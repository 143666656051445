import React from "react";
import { Grid, Button, Badge } from "@mui/material";
import useQuery from "lib/useQuery";
import EnhancedCustomTable from "components/EnhancedCustomTable";
import { RowProps } from "components/EnhancedCustomTable";
import DialogComponent from "components/ui/Dialog";
import { TableRow, TableCell } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { deleteUsers } from "services/user";
import CreateNewUserForm from "components/forms/CreateNewUserForm";
import { useAppContext } from "lib/contextLib";
import { rowsPerFetch } from "../../constants";
import { useNavigate } from "react-router-dom";
import useSetting from "hooks/useSetting";

const headCells = [
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "Username",
    align: "left",
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Date Added",
  },
  {
    id: "isAdmin",
    numeric: false,
    disablePadding: false,
    label: "Admin",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "right",
  },
];

const Users = () => {
  const { setSnackbarState, user } = useAppContext();
  const { users: allUsers, getAllUsers } = useSetting()
  const query = useQuery();

  const search = query.get("search") || undefined;
  const field = query.get("field") || undefined;

  const { mutate: deleteUsersMutation, isLoading: isDeletingUsers } =
    useMutation(deleteUsers, {
      onSuccess: () => {
        setSnackbarState({
          open: true,
          message: "User(s) deleted successfully",
          color: "success",
        });
        handleSearch();
      },
      onError: () => {
        setSnackbarState({
          open: true,
          message: "Error deleting user(s)",
          color: "error",
        });
      },
    });
  const [activePage, setActivePage] = React.useState(0);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [handleEdit, setHandleEdit] = React.useState({
    isEdit: false,
    rowData: {},
  });

  React.useEffect(() => {
    if (search || field) {
      setActivePage(0)
      handleSearch({ page: 0 });
    }
  }, [search, field])

  React.useEffect(() => {
    if ((activePage !== 0 && activePage > (allUsers?.lodedPageData as any) && allUsers?.users?.length !== allUsers?.total) ||
      (!allUsers?.users?.length || allUsers?.isFiltered)) {
      handleSearch()
    }
  }, [activePage]);

  const incrementPage = (value: number) => {
    setActivePage(value);
  };

  const handleSearch = ({ ...query } = {}) => {
    getAllUsers({ page: activePage, limit: rowsPerFetch, search, field, ...query })
  }


  const handleDeleteClick = (ids: string[]) => {
    if (user && user.username) {
      if (!user.admin) {
        setSnackbarState({
          open: true,
          message: `Please login as a admin to delete users`,
          color: "error",
        });
      } else {
        deleteUsersMutation(ids);
      }
    }
  };
  const handleCreateUserSuccess = () => {
    setIsDialogOpen(false);
    setSnackbarState({
      open: true,
      message: `User ${handleEdit?.isEdit ? "edited" : "added"} successfully`,
      color: "success",
    });
    handleSearch();
  };

  function UserRow({ handleClick, row, isItemSelected, labelId }: RowProps) {
    const navigate = useNavigate();
    const { id, username, created, isAdmin } = row;

    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setHandleEdit({ ...handleEdit, isEdit: true, rowData: row });
      setIsDialogOpen(true);
    };

    const handleDeleteUser = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      deleteUsersMutation([id]);
    };

    return (
      <TableRow
        hover
        // onClick={(event) => handleClick(event, id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={id}
        selected={isItemSelected}
      >
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          className="username-cell"
        >
          {username.toLowerCase() === user?.username ? (
            <Badge color="success" badgeContent={"Your account"}>
              {username}
            </Badge>
          ) : (
            username
          )}
        </TableCell>
        <TableCell align="left">
          {new Date(created).toLocaleDateString()}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row">
          {isAdmin ? "Yes" : "No"}
        </TableCell>
        <TableCell align="right">
          <Button onClick={handleButtonClick} variant="contained">
            {" "}
            Edit{" "}
          </Button>
          <Button
            onClick={handleDeleteUser}
            variant="contained"
            color="error"
            disabled={username.toLowerCase() === user?.username}
            sx={{ ml: 2, "&:hover": { backgroundColor: "#FF4E3E" } }}
          >
            {" "}
            Delete{" "}
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Grid container>
      <EnhancedCustomTable
        headCells={headCells}
        rows={allUsers?.users}
        isLoading={allUsers?.isLoading || isDeletingUsers}
        incrementFetch={incrementPage}
        totalCountParent={allUsers?.total}
        pageParent={activePage}
        fieldsToSearch={["Username"]}
        title="Users"
        selectionKey="id"
        onDeleteClick={handleDeleteClick}
        Row={UserRow}
        onAddClick={() => {
          setHandleEdit({ ...handleEdit, isEdit: false, rowData: {} });
          setIsDialogOpen(true);
        }}
      />
      <DialogComponent
        isDialogOpen={isDialogOpen}
        closeDialog={() => setIsDialogOpen(false)}
        title={handleEdit?.isEdit ? "Update User" : "Create New User"}
      >
        <CreateNewUserForm
          onSuccess={handleCreateUserSuccess}
          data={handleEdit.rowData}
        />
      </DialogComponent>
    </Grid>
  );
};

export default Users;
