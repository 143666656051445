import React from "react";
import {
  Grid,
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  CircularProgress,
  Box,
  FormHelperText,
  Select,
  TextField,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { createBreed } from "services/breeds";
interface Props {
  onSuccess: () => void;
}

const CreateNewBreed = ({ onSuccess }: Props) => {
  const { isError, isLoading, mutate } = useMutation(
    createBreed,
    {
      onError: (error) => {
        console.log(error);
      },
      onSuccess: onSuccess,
    }
  );

  return (
    <Formik
      initialValues={{
        name: "",
        type: "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
        type: Yup.string().required("Required"),
      })}
      onSubmit={(values, { setErrors, setStatus }) => {
        mutate(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <InputLabel htmlFor="outlined-adornment-email-login">
              Name
            </InputLabel>
            <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
              <TextField
                id="name"
                type="text"
                value={values.name}
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            {touched.name && errors.name && (
              <FormHelperText error>{errors.name}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="outlined-adornment-email-login">
              Type
            </InputLabel>
            <FormControl fullWidth error={Boolean(touched.type && errors.type)}>
              <Select
                id="type"
                name="type"
                value={values.type}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                {["Cat", "Dog"].map((item) => (
                  <MenuItem key={item} value={item.toUpperCase()}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {isError && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {"Failed to Create New Breed"}
              </FormHelperText>
            </Box>
          )}
          <Grid item container>
            <Button
              sx={{ mt: 3 }}
              disableElevation
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress /> : "Create New Breed"}
            </Button>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CreateNewBreed;
