import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "lib/axios";

export const fetchRawsAction = createAsyncThunk<undefined, { page: number, limit: number, search?: string, field?: string }>(
    "table/raws",
    async (arg, thinkAPI) => {
        const { data, status } = await axiosService.get("raw", { params: arg });

        if (data.count) {
            window.localStorage.setItem("totalCountRaw", data.count);
        }
        if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));

        return thinkAPI.fulfillWithValue({ ...data, arg });
    },
);

export const fetchAuditAction = createAsyncThunk<undefined, { page: number, limit: number, search?: string, field?: string }>(
    "table/audit-log",
    async (arg, thinkAPI) => {
        const { data, status } = await axiosService.get("settings/audit-logs", { params: arg });

        if (data.count) {
            window.localStorage.setItem("totalAuditCount", data.count);
        }
        if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));

        return thinkAPI.fulfillWithValue({ ...data, arg });
    },
);

export const fetchBreedAction = createAsyncThunk<undefined, { page: number, limit: number, search?: string, field?: string }>(
    "table/feed",
    async (arg, thinkAPI) => {
        const { data, status } = await axiosService.get("breeds", { params: arg });

        if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));

        return thinkAPI.fulfillWithValue({ ...data, arg });
    },
);