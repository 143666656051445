import React from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { Email, VisibilityOff, Visibility, Lock } from "@mui/icons-material";
// third party
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material'
import { createUser } from "services/user";
import { useAppContext } from "lib/contextLib";
const SignUpForm = () => {
  const { setSnackbarState } = useAppContext();
  const { isLoading, isError,error, mutateAsync } = useMutation(createUser, {
    onSuccess:()=>{
      setSnackbarState({
        open: true,
        message: "Registered successfully",
        color: "success",
      });
    }
  });
  console.log(error);
  const theme = useTheme();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };
  
  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: null,
          confirmPassword: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string()
            .max(255)
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
              "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            )
            .required("Password is required"),
          confirmPassword: Yup.string().when("password", {
            is: (val: string) => !!(val && val.length > 0),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              "Both Password must match!"
            ),
          }),
        })}
        onSubmit={(values, { setSubmitting, setStatus, setErrors }) => {
          try{
            mutateAsync({username:values.email, password:values.password});
          }catch(e){
            if(e instanceof Error)
           setErrors({submit:e.message})
          }
        
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
           <Grid item container justifyContent={'center'} alignItems="center" direction='column'>
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
     </Grid>
            <InputLabel
              htmlFor="outlined-adornment-email-login"
              sx={{ color: "grey.600" }}
            >
              Email
            </InputLabel>
            <FormControl
              fullWidth
              error={Boolean(touched.email && errors.email)}
              sx={{ ...theme.customInput }}
            >
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <Email sx={{ color: "grey.500" }} />
                  </InputAdornment>
                }
                id="outlined-adornment-email-login"
                type="email"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
        
                inputProps={{}}
              />
            </FormControl>
            {touched.email && errors.email && (
              <FormHelperText
                error
                id="standard-weight-helper-text-email-login"
              >
                {errors.email}
              </FormHelperText>
            )}
            <InputLabel
              htmlFor="outlined-adornment-email-login"
              sx={{ color: "grey.600", display: "inline", my: 10 }}
            >
              Password
            </InputLabel>
            <FormControl
              fullWidth
              error={Boolean(touched.password && errors.password)}
              sx={{ ...theme.customInput }}
            >
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <Lock sx={{ color: "grey.500" }} />
                  </InputAdornment>
                }
                id="outlined-adornment-password-login"
                type={showPassword ? "text" : "password"}
                value={values.password}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? (
                        <Visibility sx={{ color: "grey.500" }} />
                      ) : (
                        <VisibilityOff sx={{ color: "grey.500" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{}}
              />
            </FormControl>
            {touched.password && errors.password && (
              <FormHelperText
                error
                id="standard-weight-helper-text-email-login"
              >
                {errors.password}
              </FormHelperText>
            )}
            <InputLabel
              htmlFor="outlined-adornment-email-login"
              sx={{ color: "grey.600", display: "inline", my: 10 }}
            >
              Confirm Password
            </InputLabel>
            <FormControl
              fullWidth
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              sx={{ ...theme.customInput }}
            >
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <Lock sx={{ color: "grey.500" }} />
                  </InputAdornment>
                }
                id="outlined-adornment-password-login"
                type={showPassword ? "text" : "password"}
                value={values.confirmPassword}
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? (
                        <Visibility sx={{ color: "grey.500" }} />
                      ) : (
                        <VisibilityOff sx={{ color: "grey.500" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{}}
              />
            </FormControl>
            {touched.confirmPassword && errors.confirmPassword && (
              <FormHelperText
                error
                id="standard-weight-helper-text-email-login"
              >
                {errors.confirmPassword}
              </FormHelperText>
            )}
            {isError && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{(error as any).error || 'Something went wrong'}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <Button
                disableElevation
                disabled={isLoading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                {isLoading ? <CircularProgress /> : "Register"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          my: 4,
        }}
      >
        <Divider
          sx={{
            flexGrow: 1,
            opacity: "0.15",
            borderColor: theme.palette.common.white,
          }}
          orientation="horizontal"
        />
        <Typography color={"grey.600"} marginLeft={2} marginRight={2}>
          Already have an account?
        </Typography>
        <Divider
          sx={{
            flexGrow: 1,
            opacity: "0.15",
            borderColor: theme.palette.common.white,
          }}
          orientation="horizontal"
        />
      </Box>
      <Box>
        <Button
          disableElevation
          fullWidth
          size="large"
          component={Link}
          to="/login"
          variant="outlined"
          sx={{ color: theme.palette.common.black, borderColor: "grey.300" }}
        >
          Login
        </Button>
      </Box>
    </>
  );
};

export default SignUpForm;
