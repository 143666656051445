import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosService, { APISuccessResponse } from "lib/axios";

export const fetchScrubAction = createAsyncThunk<undefined, {
    page: number,
    limit: number,
    search?: string,
    field?: string
}>(
    "scrub/fetch",
    async (arg, thinkAPI) => {
        const { data, status } = await axiosService.get("/scrubs", { params: arg });
        if (data.count) {
            window.localStorage.setItem("totalCountScrubs", data.count);
        }
        if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));
        return thinkAPI.fulfillWithValue({ ...data, arg });
    },
);


export const fetchScrubVendors = createAsyncThunk<undefined, {
    page: number,
    limit: number,
    search?: string,
    field?: string
}>(
    "scrubvendors/fetch",
    async (arg, thinkAPI) => {
        const { data, status } = await axiosService.get("/scrubs/vendors", { params: arg });
        if (data.count) {
            window.localStorage.setItem("totalCountVendors", data.count);
        }
        if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));
        return thinkAPI.fulfillWithValue({ ...data, arg });
    },
);