import axios, { AxiosResponse } from "axios";


const axiosService = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:4000/',
})
axiosService.interceptors.response.use(
    (response) => {
        // check for auth token in response header
        const token = response.headers['auth-token'];
        if (token) {
            // store the token in local storage
            window.localStorage.setItem('token', token);
        }
        return response;
    },
    (error) => {
        // if the error is 401, the token is invalid
        console.log(error.response)
        if (error.response.status === 401 || error.response.data.logout) {
            // remove the token from local storage
            window.localStorage.removeItem('token');
            // redirect to the login page
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

// take access token from local storage and add it to the request header
axiosService.interceptors.request.use(
    (config) => {
        const token = window.localStorage.getItem('token');
        if (token && config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export type APISuccessResponse<T = undefined> = {
    error: boolean;
    message: string;
    data: T;
    response: AxiosResponse<T>;
};

export default axiosService;