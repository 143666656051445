// import React from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
//   BarChart,
//   Bar,
// } from "recharts";
// import { useGetStats } from "services";
// import { Grid, Typography, Select, MenuItem, InputLabel } from "@mui/material";
// import moment from "moment";
// import Loading from "components/ui/Loading";

// // transform data to line chart format
// const transformDataToLineChart = (data: any[] | undefined, viewBy="Day") => {
//   if (!data) return [];

//   if(viewBy === "Day"){
//   const res = [];
//   let prevCount = 0;
//   for (let i = 0; i < data.length; i++) {
//     prevCount += parseInt(data[i].count);
//     res[i] = {
//       date: moment(data[i].date).format("DD/MM/YYYY"),
//       count: prevCount,
//     };
//   }
//   return res;
// }
// else if(viewBy === "Month"){
//     const res = [];
//     let prevCount = 0;
//     // add all the counts for each month
//     for (let i = 0; i < data.length; i++){
//         const month = moment(data[i].date).format("MMMM");
//         const year = moment(data[i].date).format("YYYY");
//         const index = res.findIndex((item) => item.month === month && item.year === year);
//         if (index === -1) {
//             res.push({
//             month,
//             year,
//             count: parseInt(data[i].count),
//             date:""
//             });
//         } else {
//             res[index].count += parseInt(data[i].count);
//         }
//     }
//     // add the previous month count to the current month count
//     for (let i = 0; i < res.length; i++) {
//         prevCount += res[i].count;
//         res[i].count = prevCount;
//         res[i].date = res[i].month + ", " + res[i].year;
//     }
//     return res;
// }
// else if(viewBy === "Week"){
//     const res = [];
//     let prevCount = 0;
//     // add all the counts for each week
//     for (let i = 0; i < data.length; i++){
//         const week = moment(data[i].date).format("W");
//         const year = moment(data[i].date).format("YYYY");
//         const index = res.findIndex((item) => item.week === week && item.year === year);
//         if (index === -1) {
//             res.push({
//             week,
//             year,
//             count: parseInt(data[i].count),
//             date:""
//             });
//         } else {
//             res[index].count += parseInt(data[i].count);
//         }
//     }
//     // add the previous week count to the current week count
//     for (let i = 0; i < res.length; i++) {
//         prevCount += res[i].count;
//         res[i].count = prevCount;
//         res[i].date = "Week " + res[i].week + ", " + res[i].year;
//     }
//     return res;
// }
// };
// const transformDataForBarChart = (data: any[] | undefined, viewBy="Day") => {
//   if (!data) return [];
//   if(viewBy === "Day") {
//   const res = [];
//   for (let i = 0; i < data.length; i++) {
//     res[i] = {
//       date: moment(data[i].date).format("DD/MM/YYYY"),
//       count: parseInt(data[i].count),
//     };
//   }
//   return res;
// }
//  if(viewBy === "Month"){
//     const res = [];
//     // add all the records of the same month
//     for (let i = 0; i < data.length; i++) {
//         const month = moment(data[i].date).format("MMMM");
//         const year = moment(data[i].date).format("YYYY");
//         const index = res.findIndex((item) => item.month === month && item.year === year);
//         if (index === -1) {
//             res.push({
//             month,
//             year,
//             count: parseInt(data[i].count),
//             date:""
//             });
//         } else {
//             res[index].count += parseInt(data[i].count);
//         }
//         }
//     for(let i=0;i<res.length;i++){
//         res[i].date = res[i].month + "," + res[i].year;
//     }
//     return res;
//  }
//  else if(viewBy === "Week"){
//     const res = [];
//     // add all the records of the same week
//     for (let i = 0; i < data.length; i++) {
//         const week = moment(data[i].date).format("W");
//         const year = moment(data[i].date).format("YYYY");
//         const index = res.findIndex((item) => item.week === week && item.year === year);
//         if (index === -1) {
//             res.push({
//             week,
//             year,
//             count: parseInt(data[i].count),
//             date:""
//             });
//         } else {
//             res[index].count += parseInt(data[i].count);
//         }
//         }
//     for(let i=0;i<res.length;i++){
//         res[i].date = "Week " + res[i].week + "," + res[i].year;
//     }
//     return res;
//  }
 
// };

// export default function Dashboard() {
//   const { data: stats, isLoading, isError } = useGetStats();
//   const [valueProfiles, setValueProfiles] = React.useState<string>("Day");
//   const [valueRaw, setValueRaw] = React.useState<string>("Day");

//   const transformedDataProfiles = transformDataToLineChart(stats?.profiles, valueProfiles);
//   const transformedDataRaw = transformDataForBarChart(stats?.raw, valueRaw);
 
//   return (
//     <Grid container>
//       {!isLoading ? (
//         <Grid container>
//           <Grid item xs={12} md={6} container direction="column" spacing={1} p={2}>
//             <Grid item container justifyContent={"space-between"}>
//               <Typography variant="h4"> Profiles </Typography>
//               {/* add select option */}
//               <Grid item>
//           <InputLabel htmlFor="outlined-adornment-email-login">
//             View By
//           </InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={valueProfiles}
//             onChange={(e) => setValueProfiles(e.target.value as string)}
//           >
//             <MenuItem value={"Day"}>Day</MenuItem>
//             <MenuItem value={"Week"}>Week</MenuItem>
//             <MenuItem value={"Month"}>Month</MenuItem>
//           </Select>
//         </Grid>

//             </Grid>
//             <Grid item>
//               <ResponsiveContainer width="100%" height={400}>
//                 <LineChart
//                   width={500}
//                   height={300}
//                   data={transformedDataProfiles}
//                   margin={{
//                     top: 5,
//                     right: 30,
//                     left: 20,
//                     bottom: 5,
//                   }}
//                 >
//                   <CartesianGrid strokeDasharray="3 3" />
//                   <XAxis dataKey="date" />
//                   <YAxis />
//                   <Tooltip />
//                   <Legend />
//                   <Line
//                     type="monotone"
//                     dataKey="count"
//                     stroke="#8884d8"
//                     // activeDot={{ r: 8 }}
//                   />
//                 </LineChart>
//               </ResponsiveContainer>
//             </Grid>
//           </Grid>
//           <Grid item xs={12} md={6} container direction="column" spacing={1} p={2}>
//           <Grid item container justifyContent={"space-between"}>
//               <Typography variant="h4"> Raw Records </Typography>
//               {/* add select option */}
//               <Grid item>
//           <InputLabel htmlFor="outlined-adornment-email-login">
//             View By
//           </InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={valueRaw}
//             onChange={(e) => setValueRaw(e.target.value as string)}
//           >
//             <MenuItem value={"Day"}>Day</MenuItem>
//             <MenuItem value={"Week"}>Week</MenuItem>
//             <MenuItem value={"Month"}>Month</MenuItem>
//           </Select>
//         </Grid>

//             </Grid>

//             <Grid item>
//               <ResponsiveContainer width="100%" height={400}>
//                 <BarChart
//                   width={500}
//                   height={300}
//                   data={transformedDataRaw}
//                   margin={{
//                     top: 5,
//                     right: 30,
//                     left: 20,
//                     bottom: 5,
//                   }}
//                 >
//                   <CartesianGrid strokeDasharray="3 3" />
//                   <XAxis dataKey="date" />
//                   <YAxis />
//                   <Tooltip />
//                   <Legend />
//                   <Bar dataKey="count" fill="#8884d8" />
//                 </BarChart>
//               </ResponsiveContainer>
//             </Grid>
//           </Grid>
//         </Grid>
//       ) : (
//         <Loading />
//       )}
//     </Grid>
//   );
// }


import React, { useEffect, useState } from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports
import EarningCard from './EarningCard';
import TotalOrderLineChartCard from './TotalOrderLineChartCard';
import TotalIncomeDarkCard from './TotalIncomeDarkCard';
import TotalIncomeLightCard from './TotalIncomeLightCard';

const gridSpacing = 3;

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = () => {
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
        const token = localStorage.getItem('token');
        if (!token) {
            window.location.href = '/login';
        }
    }, []);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <EarningCard isLoading={isLoading} />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <TotalOrderLineChartCard isLoading={isLoading} />
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                <TotalIncomeDarkCard  isLoading={false} />
                            </Grid>
                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                <TotalIncomeLightCard isLoading={false} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={8}>
                        <TotalGrowthBarChart isLoading={isLoading} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PopularCard isLoading={isLoading} />
                    </Grid>
                </Grid>
            </Grid> */}
        </Grid>
    );
};

export default Dashboard;