import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import {
    fetchAllowedTldsAction,
    fetchAllUsersAction,
    fetchBlackListsAction, fetchBlockedDomainsAction, fetchBlockedRolesAction
} from "../actions/setting.action";

interface BlockedDomains {
    created: string,
    domain: string,
    id: number
    user: string,
    user_id: number
}

interface BlockList {
    created: string,
    email: string,
    id: number,
    reason: string,
    user: string,
    user_i: number,
}

interface BlockedRoles {
    created: string,
    id: number,
    role: string
}

interface AllowedTLDs {
    created: string,
    id: number,
    tld: string
}

interface user {
    created: string,
    id: number,
    isAdmin: boolean,
    password: string,
    username: string
}

export type SettingState = {
    blacklists?: {
        total?: number;
        isFiltered?: boolean;
        blacklists?: BlockList[];
        isLoading?: boolean;
        error?: boolean;
        lodedPageData?: number;
    };
    blockedDomains?: {
        total?: number;
        isFiltered: boolean;
        blockedDomains?: BlockedDomains[];
        isLoading?: boolean;
        error?: boolean;
        lodedPageData?: number;
    };
    blockedRoles: {
        total?: number;
        isFiltered: boolean;
        blockedDRoles?: BlockedRoles[];
        isLoading?: boolean;
        error?: boolean;
        lodedPageData?: number;
    };
    allowedTLDs: {
        total?: number;
        isFiltered: boolean;
        allowedTLDs?: AllowedTLDs[];
        isLoading?: boolean;
        error?: boolean;
        lodedPageData?: number;
    };
    users: {
        total?: number;
        isFiltered: boolean;
        users?: user[];
        isLoading?: boolean;
        error?: boolean;
        lodedPageData?: number;
    };
    isLoading: boolean;
    error: boolean;
    message: string;
    isToast: boolean;
};

export const settingSlice = createSlice<SettingState, SliceCaseReducers<SettingState>, string>({
    name: "setting",
    initialState: {
        blacklists: {
            total: parseInt(localStorage.getItem('totalCountBlacklist') ?? '0') || 0,
            isFiltered: false,
            blacklists: [],
            isLoading: true,
            error: false,
            lodedPageData: 0
        },
        blockedDomains: {
            total: parseInt(localStorage.getItem('totalCountBlockedDomains') ?? '0') || 0,
            isFiltered: false,
            blockedDomains: [],
            isLoading: true,
            error: false,
            lodedPageData: 0
        },
        blockedRoles: {
            total: parseInt(localStorage.getItem('totalCountBlockedRoles') ?? '0') || 0,
            isFiltered: false,
            blockedDRoles: [],
            isLoading: true,
            error: false,
            lodedPageData: 0
        },
        allowedTLDs: {
            total: parseInt(localStorage.getItem('totalCountAllowedTLDs') ?? '0') || 0,
            isFiltered: false,
            allowedTLDs: [],
            isLoading: true,
            error: false,
            lodedPageData: 0
        },
        users: {
            total: parseInt(localStorage.getItem('totalCountUsers') ?? '0') || 0,
            isFiltered: false,
            users: [],
            isLoading: true,
            error: false,
            lodedPageData: 0
        },
        isLoading: true,
        error: false,
        message: '',
        isToast: false,
    },
    reducers: {
        setIsToast(state, { payload }: { payload: { isToast: boolean; message?: string } }) {
            state.isToast = payload.isToast;
        },
    },
    extraReducers: (builder) => {
        // fetchBlackListsAction
        builder.addCase(fetchBlackListsAction.pending, (state) => {
            state.blacklists = {
                ...state.blacklists,
                isLoading: true
            }
        });
        builder.addCase(fetchBlackListsAction.fulfilled, (state, { payload }: any) => {
            const { arg } = payload;

            state.isLoading = false;
            state.blacklists = {
                isLoading: false,
                isFiltered: arg.search && arg.field ? true : false,
                total: parseInt(payload.count ?? 0),
                blacklists: (arg.search && arg.field && arg.page !== 0) ? [...state.blacklists?.blacklists as any, ...payload.blacklists] : payload.blacklists,
                lodedPageData: arg.page
            }
        });
        builder.addCase(fetchBlackListsAction.rejected, (state) => {
            state.blacklists = {
                ...state.blacklists,
                isLoading: false,
                error: true
            }
        });

        // fetchBlockedDomainsAction
        builder.addCase(fetchBlockedDomainsAction.pending, (state) => {
            state.blockedDomains = {
                ...state.blockedDomains,
                isFiltered: false,
                isLoading: true
            };
        });
        builder.addCase(fetchBlockedDomainsAction.fulfilled, (state, { payload }: any) => {
            const { arg } = payload;

            state.isLoading = false;
            state.blockedDomains = {
                isLoading: false,
                isFiltered: arg.search && arg.field ? true : false,
                total: parseInt(payload.count ?? state.blockedDomains?.total ?? 0),
                blockedDomains: arg.page !== 0 ? [...state.blockedDomains?.blockedDomains as any, ...payload.blockedDomains] : payload.blockedDomains,
                lodedPageData: arg.page
            }
        });
        builder.addCase(fetchBlockedDomainsAction.rejected, (state) => {
            state.blockedDomains = {
                ...state.blockedDomains,
                isFiltered: false,
                isLoading: false
            };
        });

        // fetchBlockedRolesAction
        builder.addCase(fetchBlockedRolesAction.pending, (state) => {
            state.blockedRoles = {
                ...state.blockedRoles,
                isFiltered: false,
                isLoading: true
            };
        });
        builder.addCase(fetchBlockedRolesAction.fulfilled, (state, { payload }: any) => {
            const { arg } = payload;

            state.blockedRoles = {
                isLoading: false,
                isFiltered: arg.search && arg.field ? true : false,
                total: parseInt(payload.count ?? state.blockedRoles?.total ?? 0),
                blockedDRoles: arg.page !== 0 ? [...state.blockedRoles?.blockedDRoles as any, ...payload.blockedRoles] : payload.blockedRoles,
                lodedPageData: arg.page
            }
        });
        builder.addCase(fetchBlockedRolesAction.rejected, (state) => {
            state.blockedRoles = {
                ...state.blockedRoles,
                isFiltered: false,
                isLoading: false
            };
        });

        // fetchAllowedTLDsAction
        builder.addCase(fetchAllowedTldsAction.pending, (state) => {
            state.allowedTLDs = {
                ...state.allowedTLDs,
                isFiltered: false,
                isLoading: true
            };
        });
        builder.addCase(fetchAllowedTldsAction.fulfilled, (state, { payload }: any) => {
            const { arg } = payload;

            state.allowedTLDs = {
                isLoading: false,
                isFiltered: arg.search && arg.field ? true : false,
                total: parseInt(payload.count ?? state.allowedTLDs?.total ?? 0),
                allowedTLDs: arg.page !== 0 ? [...state.allowedTLDs?.allowedTLDs as any, ...payload.allowedTlds] : payload.allowedTlds,
                lodedPageData: arg.page
            }
        });
        builder.addCase(fetchAllowedTldsAction.rejected, (state) => {
            state.blockedRoles = {
                ...state.blockedRoles,
                isFiltered: false,
                isLoading: false
            };
        });

        // users
        builder.addCase(fetchAllUsersAction.pending, (state) => {
            state.users = {
                ...state.users,
                isFiltered: false,
                isLoading: true
            };
        });
        builder.addCase(fetchAllUsersAction.fulfilled, (state, { payload }: any) => {
            const { arg } = payload;

            state.users = {
                isLoading: false,
                isFiltered: arg.search && arg.field ? true : false,
                total: parseInt(payload.count ?? state.users?.total ?? 0),
                users: arg.page !== 0 ? [...state.users?.users as any, ...payload.users] : payload.users,
                lodedPageData: arg.page
            }
        });
        builder.addCase(fetchAllUsersAction.rejected, (state) => {
            state.users = {
                ...state.users,
                isFiltered: false,
                isLoading: false
            };
        });
    },
});

export const { setIsToast } = settingSlice.actions;

export default settingSlice.reducer;
