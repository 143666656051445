import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetBlockedDomain, editBlockedDomain } from 'services/settings';
import * as Yup from 'yup';
import EditSettings from 'components/forms/EditSettingsForm'
import Loading from 'components/ui/Loading';
import useSetting from 'hooks/useSetting';
import { rowsPerFetch } from '../../constants';
const BlockedDomain = () => {
  const { id } = useParams();
  const [blockedList, setBlockedList] = useState<any>({});
  const { blockedDomains, getBlockedDomains } = useSetting();
  const [isEditing, setIsEditing] = React.useState(false);

  useEffect(() => {
    if (!blockedDomains?.blockedDomains?.length) {
      getBlockedDomains({ page: 0, limit: rowsPerFetch });
    }
    setBlockedList(blockedDomains?.blockedDomains?.find((blockDomain) => blockDomain.id == parseInt(id as any)))
  }, [blockedDomains?.blockedDomains?.length])

  const handleSuccess = () => {
    getBlockedDomains({ page: 0, limit: rowsPerFetch });
  }

  return (
    <>
      {(blockedList && Object.keys(blockedList).length) ? <EditSettings
        label='Domain'
        name='domain'
        refetch={handleSuccess}
        mutation={editBlockedDomain}
        initialValues={{ domain: blockedList.domain }}
        validationSchema={{ domain: Yup.string().required('Domain is required') }}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      /> : <Loading />}
    </>
  )
}

export default BlockedDomain