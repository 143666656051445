import React from "react";
import { useTheme } from "@mui/material";
import logo from 'assets/images/paws-media-final-color.png'
const SidebarLogo = () => {
  return (
   <img alt="PawsCRM" src={logo} width="150" />
  );
};


export default SidebarLogo;
