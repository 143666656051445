import React, { memo } from "react";
// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { Box, Drawer, Divider, Grid, Collapse } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
// project imports
import SidebarLogo from "./SidebarLogo";
import { drawerWidth, dashboardRoutes } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "lib/contextLib";

// ==============================|| SIDEBAR DRAWER ||============================== //

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "center",
}));

export interface SidebarProps {
  window?: Window;
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
}
const Sidebar = ({ window, mobileOpen, handleDrawerToggle }: SidebarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAppContext();
  const theme = useTheme();
  const [selected, setSelected] = React.useState("/");
  const [subSelected, setSubSelected] = React.useState("/");
  const [isSubMenuOpen0, setSubMenuOpen0] = React.useState(false);
  const [isSubMenuOpen1, setSubMenuOpen1] = React.useState(false);
  const [isSubMenuOpen2, setSubMenuOpen2] = React.useState(false);
  React.useEffect(() => {
    setSelected("/" + location.pathname.split("/")[1]);
    setSubSelected("/" + location.pathname.split("/")[2]);
  }, [location.pathname]);
  const drawer = (
    <>
      <DrawerHeader>
        <SidebarLogo />
      </DrawerHeader>
      <Divider />
      <Grid
        direction={"column"}
        height="100%"
        container
        justifyContent="space-between"
      >
        <Grid item>
          <List sx={{ paddingTop: "0px" }}>
            {dashboardRoutes.map(({ name, Icon, url, parent, childRoutes }) => {
              const isSubMenuOpen =
                name === "Scrubs"
                  ? isSubMenuOpen0
                  : name === "Tables"
                    ? isSubMenuOpen1
                    : isSubMenuOpen2;
              const setSubMenuOpen =
                name === "Scrubs"
                  ? setSubMenuOpen0
                  : name === "Tables"
                    ? setSubMenuOpen1
                    : setSubMenuOpen2;
              return (
                <React.Fragment key={name}>
                  <ListItem
                    disablePadding
                    sx={
                      selected === url
                        ? {
                          backgroundColor: `${theme.palette.primary.light}`,
                          color: `${theme.palette.common.white}`,
                        }
                        : undefined
                    }
                  >
                    <ListItemButton
                      onClick={
                        !parent
                          ? () => navigate(url)
                          : () => setSubMenuOpen(!isSubMenuOpen)
                      }
                    >
                      <ListItemIcon>
                        {
                          <Icon
                            style={{
                              transform: "scale(0.8)",
                              color:
                                selected === url
                                  ? theme.palette.common.white
                                  : undefined,
                            }}
                          />
                        }
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          "& span": { fontSize: "14px", fontStyle: "saira" },
                        }}
                        primary={name}
                      />
                      {parent && (
                        <>{isSubMenuOpen ? <ExpandLess /> : <ExpandMore />}</>
                      )}
                    </ListItemButton>
                  </ListItem>
                  {parent && (
                    <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {childRoutes
                          .filter((item) => {
                            if (
                              item.allowOnlyPartners &&
                              user &&
                              user.username
                            ) {
                              if (user.isAdmin)
                                return true;
                            } else {
                              return true;
                            }
                          })
                          .map(({ name, url: childUrl, Icon }) => (
                            <ListItem key={name} disablePadding>
                              <ListItemButton
                                selected={subSelected === childUrl}
                                onClick={() => navigate(url + childUrl)}
                                sx={{ p: "6px", paddingLeft: "15%" }}
                              >
                                <ListItemIcon sx={{ justifyContent: "center" }}>
                                  {
                                    <Icon
                                      style={{ transform: "scale(0.75)" }}
                                    />
                                  }
                                </ListItemIcon>
                                <ListItemText
                                  sx={{
                                    "& span": {
                                      fontSize: "14px",
                                      fontStyle: "saira",
                                    },
                                  }}
                                  primary={name}
                                />
                              </ListItemButton>
                            </ListItem>
                          ))}
                      </List>
                    </Collapse>
                  )}
                </React.Fragment>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        color="secondary"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default memo(Sidebar);
