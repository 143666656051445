import React, { useEffect } from "react";
import CustomTable, {
  CustomTableCell as TableCell,
} from "components/ui/CustomTable";
import {
  Grid,
  TableRow,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Chip,
  Checkbox
} from "@mui/material";
import EnhancedCustomTable, { RowProps } from "components/EnhancedCustomTable";
import { useGetLists } from "services/lists";
import { Link } from "react-router-dom";
import Loading from "components/ui/Loading";
import ErrorAlert from "components/ErrorAlert";
import DialogComponent from "components/ui/Dialog";
import CreateNewList from "components/forms/CreateNewList";
import { useAppContext } from "lib/contextLib";
import { List } from "types";
import useList from "hooks/useList";


const Lists = () => {
  // const { data, isError, isLoading, refetch } = useGetLists();
  const { list, getAllList, error, isLoading } = useList();
  const [isListCreateDialogOpen, setIsListCreateDialogOpen] = React.useState(false);
  const { setSnackbarState } = useAppContext();

  useEffect(() => {
    if (!list?.length)
      getAllList();
  }, []);

  const handleSuccess = async () => {
    await getAllList();
    setIsListCreateDialogOpen(false);
    setSnackbarState({
      color: "success",
      message: "List created successfully",
      open: true,
    })
  }

  return (
    <Grid container direction="column" spacing={1}>
      {/* <Grid item container justifyContent={"space-between"}>
        <Typography variant="h3">Lists</Typography>
        <Button variant="contained" sx={{mr:3.5}} onClick={() => setIsListCreateDialogOpen(true)}>
          {" "}
          Create New List
        </Button>
      </Grid> */}
      <Grid item container>
        {error && <ErrorAlert />}

        <EnhancedCustomTable
          rows={list}
          isLoading={isLoading}
          headCells={[
            { id: "name", label: "Name" },
            { id: "status", label: "Status" },
            { id: 'type', label: "Type" },
            { id: 'raw_count', label: "Raw Count" },
            { id: 'profiles_count', label: "Profiles Count" },
            { id: 'vendor', label: "Vendor" },
            { id: 'created', label: "Created" },
            { id: 'actions', label: "Actions" }
          ]}
          Row={ListRow}
          title="Lists"
          selectionKey="id"
          onDeleteClick={() => console.log('on')}
          onAddClick={() => setIsListCreateDialogOpen(true)}
        />
      </Grid>
      <DialogComponent
        dialogProps={{
          open: isListCreateDialogOpen,
          maxWidth: "md",
        }}
        title="Create New List"
        isDialogOpen={isListCreateDialogOpen}
        closeDialog={() => setIsListCreateDialogOpen(false)}
      >
        <CreateNewList onSuccess={handleSuccess} />
      </DialogComponent>
    </Grid>
  );
};

export default Lists;

function ListRow({ handleClick,
  row: list,
  selectionKey,
  isItemSelected,
  labelId }: RowProps<List>
) {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, (list as any)[selectionKey])}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={list.id}
      selected={isItemSelected}
    >
      <TableCell align="left" component="th" scope="row">
        <Typography variant="h6">
          {list.list_name}
        </Typography>
      </TableCell>
      <TableCell align="left" >
        <Chip
          sx={{ color: 'white', fontWeight: 'bold' }}
          label={list.status}
          size="small"
          color={list.status === "Active" ? "success" : "error"}
        />
      </TableCell>
      <TableCell align="left">{list.type}</TableCell>
      <TableCell align="left">
        {new Intl.NumberFormat().format(parseInt(list.raw_count || "0"))}
      </TableCell>
      <TableCell align="left">
        {new Intl.NumberFormat().format(parseInt(list.profiles_count || "0"))}
      </TableCell>
      <TableCell align="left">{list.vendor}</TableCell>
      <TableCell style={{ color: "black" }} align="center">{`${new Date(
        list.created
      ).toLocaleString()}`}</TableCell>
      <TableCell align="center">
        <Grid container justifyContent={"center"}>
          <Button
            component={Link}
            to={`/lists/${list.id}`}
            variant="contained"
            sx={{ mb: matchesMD ? 1 : undefined }}
          >
            {" "}
            View
          </Button>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

{/* <CustomTable
            tableHead={
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Raw Count</TableCell>
                <TableCell align="center">Profile Count</TableCell>
                <TableCell align="center">Vendor</TableCell>
                <TableCell align="center">Created</TableCell>
                <TableCell align="center">ACTIONS</TableCell>
              </TableRow>
            }
          >
            {data &&
              data.map((list) => (
               
              ))}
          </CustomTable> */}