import { configureStore } from "@reduxjs/toolkit";
import listSlice from "./reducers/list.reducer";
import scrubSlice from "./reducers/scrubs.reducer";
import settingSlice from "./reducers/setting.reducer";
import tableSlice from "./reducers/table.reducer";
import profileSlice from "./reducers/profile.reducer";
import jobsSlice from "./reducers/jobs.reducer";

const store = configureStore({
    reducer: {
        list: listSlice,
        scrub: scrubSlice,
        setting: settingSlice,
        table: tableSlice,
        profile: profileSlice,
        job: jobsSlice
    },
    devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;