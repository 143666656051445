import React from "react";
import {
  Grid,
  Button,
  InputLabel,
  FormControl,
  OutlinedInput,
  CircularProgress,
  Box,
  FormHelperText,
  Typography,
  useTheme,
  Divider,
} from "@mui/material";
import { splitTextIntoArray } from "lib/splitTextIntoArray";
import { Formik } from "formik";
import { useAppContext } from "lib/contextLib";
import * as Yup from "yup";
import { FileUpload as FileUploadIcon } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import { addToBlockedDomains } from "services/settings";
interface Props {
  onSuccess: () => void;
}
const CreateNewBlockedDomain = ({ onSuccess }: Props) => {
  const theme = useTheme();
  const { setSnackbarState } = useAppContext();
  const { isError, isLoading, mutate } = useMutation(addToBlockedDomains, {
    onError: (error) => {
      console.log(error);
    },
    onSuccess: onSuccess,
  });
  const [file, setFile] = React.useState<File>();

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      if (file.size / 1000000 > 5) {
        setSnackbarState({
          open: true,
          message: "File size should be less than 5MB",
          color: "error",
        });
      } else {
        setFile(file);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        domain: "",
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        domain: Yup.string(),
      })}
      onSubmit={(values, { setErrors, setStatus }) => {
        try {
          if (file) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = async () => {
              const text = reader.result as string;
              const domains = splitTextIntoArray(text);
              // check if domains are valid
              const validDomains = domains.filter((domain) => {
                return !domain.includes("@") && domain.includes(".");
              })
              if (validDomains.length === 0) {
                setSnackbarState({
                  open: true,
                  message: "No valid domains found",
                  color: "error",
                });
                return;
              }
              mutate({ domains:validDomains });
            };
          } else {
            mutate({ domain: values.domain });
          }
        } catch (e) {
          console.log(e);
          if (e instanceof Error) {
            console.log(e.message);
            setStatus({ success: false });
            setErrors({ submit: e.message });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={1}>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={12}>
                <InputLabel htmlFor="outlined-adornment-domain-login">
                  Domain
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(touched.domain && errors.domain)}
                >
                  <OutlinedInput
                    id="domain"
                    type="text"
                    name="domain"
                    disabled={!!file}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                my: 2,
              }}
            >
              <Divider
                sx={{
                  flexGrow: 1,
                  opacity: "0.15",
                  borderColor: theme.palette.common.black,
                }}
                orientation="horizontal"
              />
              <Typography color={"grey.600"} marginLeft={2} marginRight={2}>
                OR
              </Typography>
              <Divider
                sx={{
                  flexGrow: 1,
                  opacity: "0.15",
                  borderColor: theme.palette.common.black,
                }}
                orientation="horizontal"
              />
            </Box>
            <Button
              component="label"
              color="info"
              variant="contained"
              startIcon={<FileUploadIcon />}
            >
              {file ? `(${file.name})` : "Import from File"}
              <input
                type="file"
                accept={".csv,.txt"}
                hidden
                onChange={handleFileInput}
              />
            </Button>

            {isError && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{"Failed to Add"}</FormHelperText>
              </Box>
            )}
            <Grid item container>
              <Button
                sx={{ mt: 2 }}
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress />
                ) : file ? (
                  "Upload"
                ) : (
                  "Add To Blocked Domains"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CreateNewBlockedDomain;
