import React from "react";
import { Grid, TableRow, TableCell, Button } from "@mui/material";
import EnhancedCustomTable, { RowProps } from "components/EnhancedCustomTable";
import { deleteBlockedRoles } from "services/settings";
import DialogComponent from "components/ui/Dialog";
import useQuery from "lib/useQuery";
import CreateNewBlockedRole from "components/forms/CreateNewBlockedRole";
import { useAppContext } from "lib/contextLib";
import { useMutation } from "@tanstack/react-query";
import { rowsPerFetch } from "../../constants";
import ErrorAlert from "components/ErrorAlert";
import { useNavigate } from "react-router-dom";
import useSetting from "hooks/useSetting";

const blockedRolesHeadCells = [
  { id: "role", numeric: false, label: "Role" },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Date Added",
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "right",
  }
];

const BlockedRoles = () => {
  const { setSnackbarState } = useAppContext();
  const { blockedRoles, getBlockedRoles } = useSetting();
  const query = useQuery();
  const [activePage, setActivePage] = React.useState(0);

  const search = query.get("search") || undefined;
  const field = query.get("field") || undefined;

  React.useEffect(() => {
    if (search || field) {
      setActivePage(0)
      handleSearch({ page: 0 });
    }
  }, [search, field])

  React.useEffect(() => {
    if ((activePage !== 0 && activePage > (blockedRoles?.lodedPageData as any) && blockedRoles?.blockedDRoles?.length !== blockedRoles?.total) ||
      (!blockedRoles?.blockedDRoles?.length || blockedRoles?.isFiltered)) {
      handleSearch()
    }
  }, [activePage]);

  const incrementPage = (value: number) => {
    setActivePage(value);
  }
  const handleSearch = ({ ...query } = {}) => {
    getBlockedRoles({ page: activePage, limit: rowsPerFetch, search, field, ...query })
  }

  const { mutate: deleteBlockedRolesMutation, isLoading: isDeletingBlockedRoles } = useMutation(
    deleteBlockedRoles,
    {
      onSuccess: () => {
        setSnackbarState({
          open: true,
          message: "Role deleted successfully",
          color: "success",
        });
        handleSearch();
      },
      onError: (error) => {
        setSnackbarState({
          open: true,
          message: "Error deleting Role",
          color: "error",
        });
      },
    }
  );

  const [isBlockedRolesOpen, setIsBlockedRolesOpen] = React.useState(false);

  const handleCreateBlockedRoleSuccess = () => {
    setIsBlockedRolesOpen(false);
    setSnackbarState({
      open: true,
      message: "Blocked Role added successfully",
      color: "success",
    });
    handleSearch();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {blockedRoles?.error ? (
          <ErrorAlert />
        ) : (
          <EnhancedCustomTable
            selectionKey="id"
            Row={BlockedRolesRow}
            incrementFetch={incrementPage}
            totalCountParent={blockedRoles?.total}
            pageParent={activePage}
            fieldsToSearch={["Role", {
              label: "Date Added",
              value: "date",
            }]}
            onDeleteClick={(ids) => deleteBlockedRolesMutation(ids)}
            title="Blocked Roles"
            headCells={blockedRolesHeadCells}
            rows={blockedRoles?.blockedDRoles}
            isLoading={blockedRoles?.isLoading || isDeletingBlockedRoles}
            onAddClick={() => {
              setIsBlockedRolesOpen(true);
            }}
          />
        )}
      </Grid>

      <DialogComponent
        isDialogOpen={isBlockedRolesOpen}
        closeDialog={() => {
          setIsBlockedRolesOpen(false);
        }}
        title="Add Blocked Role"
      >
        <CreateNewBlockedRole onSuccess={handleCreateBlockedRoleSuccess} />
      </DialogComponent>
    </Grid>
  );
};

function BlockedRolesRow({
  handleClick,
  row,
  isItemSelected,
  labelId,
}: RowProps) {
  const { id, role, created } = row;
  const navigate = useNavigate();
  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/settings/blocked-roles/${id}`);
  }
  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={id}
      selected={isItemSelected}
    >
      <TableCell component="th" id={labelId} scope="row" >
        {role}
      </TableCell>

      <TableCell align="left">
        {new Date(created).toISOString().split("T")[0]}
      </TableCell>
      <TableCell align="right">
        <Button

          onClick={handleButtonClick}
          variant="contained"
        >View</Button>
      </TableCell>
    </TableRow>
  );
}

export default BlockedRoles;
