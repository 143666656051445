import React, { useEffect, useState } from 'react'
import EditSettings from 'components/forms/EditSettingsForm'
import * as Yup from 'yup';
import { useParams } from 'react-router-dom'
import { useGetBlacklistItem, editBlacklistItem } from 'services/settings'
import Loading from 'components/ui/Loading';
import useSetting from 'hooks/useSetting';
import { rowsPerFetch } from '../../constants';
const BlackListItem = () => {
  const { id } = useParams();
  const { blacklists, getBlackLists, error, isLoading: isFetchLoading } = useSetting();
  const [blockList, setBlackList] = useState<any>({})
  const [isEditing, setIsEditing] = React.useState(false);

  useEffect(() => {
    if (!blacklists?.blacklists?.length) {
      getBlackLists({ page: 0, limit: rowsPerFetch });
    }
    setBlackList(blacklists?.blacklists?.find((blockList) => blockList.id == parseInt(id as any)))
  }, [blacklists?.blacklists?.length])

  const handleSuccess = () => {
    getBlackLists({ page: 0, limit: rowsPerFetch });
  }

  return (
    <>
      {(blockList && Object.keys(blockList).length) ? <EditSettings
        label="Email"
        name="email"
        isEditing={isEditing}
        refetch={handleSuccess}
        setIsEditing={setIsEditing}
        mutation={editBlacklistItem}
        initialValues={{ email: blockList.email }}
        validationSchema={{ email: Yup.string().required('Email is required') }}
      /> : <Loading />}


    </>

  )
}
export default BlackListItem