import React from 'react'
import {
    Grid,
    Typography,
    useTheme,
    useMediaQuery,
  } from "@mui/material";
import { useNavigate } from 'react-router-dom'
  interface Props {
    value: number;
    title: string;
    icon: React.ReactNode;
    rawLength?:number;
    url:string;
  }
  function BlockCard({ value, title, icon, rawLength,url }: Props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));
    const blockSX = {
      p: 1.6,
      borderLeft: "1px solid ",
      borderBottom: "1px solid ",
      borderLeftColor: theme.palette.grey[200],
      borderBottomColor: theme.palette.grey[200],
      backgroundColor: "rgb(33, 41, 70)",
      color: "rgb(189, 200, 240)",
      height: "80px",
      cursor: "pointer",
    };
    const handleNavigate = () => {
      navigate(url)
    }
    return (
      <Grid item xs={12} sm={6} sx={blockSX} onClick={handleNavigate}>
        <Grid
          container
          alignItems="center"
          spacing={1}
          justifyContent={matchDownXs ? "space-between" : "center"}
        >
          <Grid item>{icon}</Grid>
          <Grid item sm zeroMinWidth>
            <Typography variant="h5" align="center" width={'100%'}>
              {new Intl.NumberFormat().format(value || 0)}
            </Typography>
          {rawLength ?  <Typography variant="subtitle2" align="center">
            {(value/rawLength *100 || 0).toFixed(2)}%
            </Typography>:null}
            <Typography variant="subtitle2" align="center">
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

export default BlockCard