import React, { useEffect, useState } from "react";
import { editBlockedRole } from "services/settings";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import Loading from "components/ui/Loading";
import EditSettingsForm from "components/forms/EditSettingsForm";
import useSetting from "hooks/useSetting";
import { rowsPerFetch } from "../../constants";

const BlockedRole = () => {
  const { id } = useParams();
  const { blockedRoles, getBlockedRoles } = useSetting();
  const [isEditing, setIsEditing] = React.useState(false);
  const [blockedRole, setBlockedRole] = useState<any>({});

  useEffect(() => {
    if (!blockedRoles?.blockedDRoles?.length) {
      getBlockedRoles({ page: 0, limit: rowsPerFetch });
    }
    setBlockedRole(blockedRoles?.blockedDRoles?.find((blockedRole) => blockedRole.id == parseInt(id as any)))
  }, [blockedRoles?.blockedDRoles?.length])

  const handleSuccess = () => {
    getBlockedRoles({ page: 0, limit: rowsPerFetch });
  }

  return (
    <>
      {(blockedRole && Object.keys(blockedRole).length) ? (
        <EditSettingsForm
          label="Role"
          name="role"
          refetch={handleSuccess}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          mutation={editBlockedRole}
          initialValues={{ role: blockedRole.role }}
          validationSchema={{ role: Yup.string().required("Role is required") }}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default BlockedRole;
