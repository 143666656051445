import React from "react";
import { getAuditLogs } from "services/settings";
import useQuery from "lib/useQuery";
import { rowsPerFetch } from "../../constants";
import EnhancedCustomTable from "components/EnhancedCustomTable";
import { TableRow, TableCell } from "@mui/material";
import { Grid } from "@mui/material";
import moment from "moment";
import ErrorAlert from "components/ErrorAlert";
import { RowProps } from "components/EnhancedCustomTable";
import useTable from "hooks/useTable";

const auditLogsHeadCells = [
  { id: "user", numeric: false, label: "User", align: 'left' },
  { id: 'field', numeric: false, label: 'Field', align: 'left' },
  { id: "was", numeric: false, disablePadding: false, label: "Was", align: 'left' },
  { id: "now", numeric: false, disablePadding: false, label: "Now", align: 'left' },
  { id: "created", numeric: false, disablePadding: false, label: "Date", align: 'left' },
];
const AuditLog = () => {
  const { audit, getAllAuditList } = useTable();
  const [activePage, setActivePage] = React.useState(0);

  const query = useQuery();
  const search = query.get("search") || undefined;
  const field = query.get("field") || undefined;

  React.useEffect(() => {
    if (search || field) {
      setActivePage(0)
      handleSearch({ page: 0 });
    }
  }, [search, field])

  React.useEffect(() => {
    if ((activePage !== 0 && activePage > (audit?.lodedPageData as any) && audit?.audit?.length !== audit?.total) ||
      (!audit?.audit?.length || audit?.isFiltered)) {
      handleSearch()
    }
  }, [activePage]);

  const incrementFetch = (val: number) => {
    setActivePage(val);
  };
  const handleSearch = ({ ...query } = {}) => {
    getAllAuditList({ page: activePage, limit: rowsPerFetch, search, field, ...query })
  };

  return (
    <Grid container>
      {audit?.error && <ErrorAlert />}
      {
        <EnhancedCustomTable
          rows={audit?.audit}
          isLoading={audit?.isLoading ?? false}
          totalCountParent={audit?.total}
          pageParent={activePage}
          incrementFetch={incrementFetch}
          headCells={auditLogsHeadCells}
          selectionKey="id"
          title="Audit Logs"
          fieldsToSearch={["Username", 'Was', 'Now', 'Field']}
          onDeleteClick={() => {
            console.log("no delete");
          }}
          Row={AuditLogRow}
        />
      }
    </Grid>
  );
};


function AuditLogRow({
  handleClick,
  row,
  selectionKey,
  isItemSelected,
  labelId,
}: RowProps) {

  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, (row as any)[selectionKey])}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell component="th" id={labelId} scope="row">
        {row.username}
      </TableCell>
      <TableCell align="left">{row.field}</TableCell>
      <TableCell align="left">
        {row.was}
        {/* <JSONPretty id="json-pretty" data={JSON.stringify(was)}></JSONPretty> */}
      </TableCell>
      <TableCell align="left">
        {row.now}
        {/* <JSONPretty id="json-pretty" data={JSON.stringify(now)}></JSONPretty> */}
      </TableCell>
      <TableCell align="left">
        {moment(row.created).format("MMMM Do YYYY, h:mm:ss a")}
      </TableCell>
    </TableRow>
  );
}

export default AuditLog;
