import EditSettings from 'components/forms/EditSettingsForm';
import Loading from 'components/ui/Loading';
import { rowsPerFetch } from '../../constants';
import useSetting from 'hooks/useSetting';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetAllowedTLD, editAllowedTLD } from 'services/settings';
import * as Yup from 'yup';

const AllowedTLD = () => {
  const { id } = useParams();
  const [allowedTld, setAllowedTld] = useState<any>({});
  const { allowedTLDs, getAllowedTlds } = useSetting();
  const [isEditing, setIsEditing] = React.useState(false);

  useEffect(() => {
    if (!allowedTLDs?.allowedTLDs?.length) {
      getAllowedTlds({ page: 0, limit: rowsPerFetch });
    }
    setAllowedTld(allowedTLDs?.allowedTLDs?.find((allowedTld) => allowedTld.id == parseInt(id as any)))
  }, [allowedTLDs?.allowedTLDs?.length])

  const handleSuccess = () => {
    getAllowedTlds({ page: 0, limit: rowsPerFetch });
  }

  return (
    <>
      {(allowedTld && Object.keys(allowedTld).length) ? <EditSettings
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        mutation={editAllowedTLD}
        initialValues={{ tld: allowedTld.tld }}
        refetch={handleSuccess}
        label="TLD"
        name='tld'
        validationSchema={{
          tld: Yup.string().required('Required'),
        }}
      /> : <Loading />}
    </>
  )
}

export default AllowedTLD