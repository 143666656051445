import React from "react";
import {
  Grid,
  Button,
  InputLabel,
  FormControl,
  TextField,
  MenuItem,
  OutlinedInput,
  CircularProgress,
  TextareaAutosize,
  Box,
  FormHelperText,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { createNewList } from "services/lists";
import { List } from "types";

interface Props {
  onSuccess: () => void;
}
const CreateNewList = ({onSuccess}:Props) => {
  const { isError, isLoading, mutate } = useMutation((list: Partial<List>) => createNewList(list),{
    onError: (error) => {
      console.log(error);
    },
    onSuccess:onSuccess
  });

  return (
    <Formik
      initialValues={{
        name: "",
        vendor: "",
        type: "All",
        notes: "",
        status: "Active",
        vertical:"General",
        salesRep:"",
        submit: "",
        costPerLead:0
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Name is required"),
        vendor: Yup.string(),
        type: Yup.string(),
        notes: Yup.string(),
        vertical: Yup.string(),
        salesRep: Yup.string(),
        costPerLead: Yup.number().typeError('Must be a number'),
        status: Yup.string().required("Status is required"),
      })}
      onSubmit={(values, { setErrors, setStatus }) => {
        try {
          mutate(values);
        } catch (e) {
            console.log(e);
          if (e instanceof Error) {
            console.log(e.message)
            setStatus({ success: false });
            setErrors({ submit: e.message });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={1}>
            <Grid item container>
              <InputLabel htmlFor="outlined-adornment-email-login">
                Name
              </InputLabel>
              <FormControl
                fullWidth
                error={Boolean(touched.name && errors.name)}
              >
                <OutlinedInput
                  id="name"
                  type="text"
                  value={values.name}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{}}
                />
              </FormControl>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={6}>
                <InputLabel>Type</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    value={values.type}
                    select // tell TextField to render select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="type"
                  >
                    <MenuItem key={1} value="All">
                      All
                    </MenuItem>
                    <MenuItem key={2} value="Instagram">
                      Instagram
                    </MenuItem>
                    <MenuItem key={2} value="Facebook">
                      Facebook
                    </MenuItem>
                    <MenuItem key={2} value="Ecomm">
                      Ecomm
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel >Status</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    value={values.status}
                    select // tell TextField to render select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="status"
                  >
                    <MenuItem key={1} value="Active">
                      Active
                    </MenuItem>
                    <MenuItem key={2} value="Inactive">
                      Inactive
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
              <Grid item container spacing={1}>
              <Grid item xs={12} md={6}>
              <InputLabel htmlFor="outlined-adornment-email-login">
                Vendor
              </InputLabel>
              <FormControl
                fullWidth
                error={Boolean(touched.vendor && errors.vendor)}
              >
                <OutlinedInput
                  id="name"
                  type="text"
                  name="vendor"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{}}
                />
              </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
              <InputLabel>Vertical</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    value={values.vertical}
                    select // tell TextField to render select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="vertical"
                  >
                    <MenuItem key={1} value="Dog">
                    Dog
                    </MenuItem>
                    <MenuItem key={2} value="Cat">
                    Cat
                    </MenuItem>
                    <MenuItem key={2} value="Both">
                    Both
                    </MenuItem>
                    <MenuItem key={2} value="General">
                    General
                    </MenuItem>
                  </TextField>
                </FormControl>
        </Grid>
        </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={6}>
                <InputLabel>Sales Rep</InputLabel>
                <FormControl fullWidth>
                <OutlinedInput
                  id="salesRep"
                  type="text"
                  value={values.salesRep}
                  name="salesRep"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{}}
                />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel >Cost Per Lead</InputLabel>
                <FormControl fullWidth error={
                  Boolean(touched.costPerLead && errors.costPerLead)
                }>
                <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                  id="costPerLead"
                  type="text"
                  value={values.costPerLead}
                  name="costPerLead"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.costPerLead && errors.costPerLead &&(
                  <FormHelperText error>{errors.costPerLead}</FormHelperText>
                )}
                </FormControl>

              </Grid>
            </Grid>
            <Grid item>
              <InputLabel htmlFor="outlined-adornment-email-login">
                Notes
              </InputLabel>
              <FormControl
                fullWidth
                error={Boolean(touched.notes && errors.notes)}
              >
                <TextareaAutosize
                  name="notes"
                  minRows={3}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
            </Grid>
          </Grid>

          {isError && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{'Failed to Create New List'}</FormHelperText>
            </Box>
          )}
          <Grid item container>
            <Button
              sx={{ mt: 2 }}
              disableElevation
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress /> : "Create List"}
            </Button>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CreateNewList;
