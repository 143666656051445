import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import { fetchScrubAction, fetchScrubVendors } from "store/actions/scrubs.action";

export type Scrub = {
    code?: number,
    created?: string,
    email?: string,
    id: number,
    reason?: string,
    vendor?: string,
};

export type ScrubVendor = {
    cost?: number | string | null;
    created: string;
    id: number;
    name: string;
    valid_reason: string | number | null;
}

export type ScrubState = {
    scrubs?: {
        total: number,
        scrub: Scrub[]
    };
    scrubVendors: {
        total: number,
        scrubVendors: ScrubVendor[]
    };
    isLoading: boolean;
    error: boolean;
    message: string;
    isToast: boolean;
};

export const scrubSlice = createSlice<ScrubState, SliceCaseReducers<ScrubState>, string>({
    name: "scrub",
    initialState: {
        scrubs: {
            total: 0,
            scrub: []
        },
        scrubVendors: {
            total: 0,
            scrubVendors: []
        },
        isLoading: false,
        error: false,
        message: '',
        isToast: false,
    },
    reducers: {
        setIsToast(state, { payload }: { payload: { isToast: boolean; message?: string } }) {
            state.isToast = payload.isToast;
        },
    },
    extraReducers: (builder) => {
        // Scrub 
        builder.addCase(fetchScrubAction.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchScrubAction.fulfilled, (state, { payload }: any) => {
            const { arg } = payload;

            state.isLoading = false;
            state.scrubs = {
                total: parseInt(payload.count ?? 0),
                scrub: !(arg.search && arg.field) ? [...state.scrubs?.scrub as any, ...payload.scrubs] : payload.scrubs,
            };
        });
        builder.addCase(fetchScrubAction.rejected, (state) => {
            state.isLoading = false;
            state.isToast = true;
            state.error = true;
        });
        // Scrub vendors
        builder.addCase(fetchScrubVendors.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchScrubVendors.fulfilled, (state, { payload }: any) => {
            const { arg } = payload;

            state.isLoading = false;
            state.scrubVendors = {
                total: parseInt(payload.count ?? 0),
                scrubVendors: (arg.search && arg.field && arg.page !== 0) ? [...state.scrubVendors?.scrubVendors as any, ...payload.vendors] : payload.vendors,
            };
        });
        builder.addCase(fetchScrubVendors.rejected, (state) => {
            state.isLoading = false;
            state.isToast = true;
            state.error = true;
        });
    },
});

export const { setIsToast } = scrubSlice.actions;

export default scrubSlice.reducer;
