import React from "react";
import {
  Button,
  InputLabel,
  FormControl,
  TextField,
  MenuItem,
  Grid,
  CircularProgress,
  Box,
  FormHelperText,
  Typography,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { updateProfile } from "services/profiles";
import { Profile } from "types";
import { useGetBreeds } from "services/breeds";


interface Props {
  isEditing: boolean;
  profile: Profile;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccessfulUpdate: () => void;
}
const EditProfileForm = ({ isEditing, profile, setIsEditing,onSuccessfulUpdate }: Props) => {
  const {data:breedsData, isLoading:isBreedsLoading, isError:isBreedsError} = useGetBreeds(0,1000);
  console.log(breedsData);
  const { mutate, isLoading, isError } = useMutation(updateProfile, {
    onSuccess:()=>{
      onSuccessfulUpdate();
    }
  });

  const handleCancel = (setValues: any, initialValues: any) => {
    setIsEditing(false);
    setValues(initialValues);
  };
  function getPetBreeds(petType:string):any[]{
    if(breedsData){
      if(petType.toLowerCase() === 'dog'){
        return breedsData.breeds.filter((item:any)=>item.type === 'DOG');
      }
      else if(petType.toLowerCase() === 'cat'){
        return breedsData.breeds.filter((item:any)=>item.type === 'CAT');
      }
     
      else {
        return breedsData.breeds;
      }
    
    }
    return [];
  }
  return (
    <Formik
      initialValues={{
        email: profile.email || "",
        first: profile.first || "",
        last: profile.last || "",
        address_1: profile.address_1 || "",
        address_2: profile.address_2 || "",
        city: profile.city || "",
        state: profile.state || "",
        country: profile.country || "",
        zip: profile.zip || "",
        phone: profile.phone || "",
        gender: profile.gender || "",
        dob: profile.dob || "",
        petType: profile.pet_type || "",
        petName: profile.pet_name || "",
        petBreed: profile.pet_breed || "",
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Must be a valid email").required("Required"),
        first: Yup.string(),
        last: Yup.string(),
        address_1: Yup.string(),
        address_2: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        country: Yup.string(),
        zip: Yup.string(),
        phone: Yup.string(),
      })}
      onSubmit={(values, { setErrors, setStatus }) => {
        try {
          const { submit, ...data } = values;
          mutate({ id: profile.id,profile:data });
          //   mutate(values);
        } catch (e) {
          console.log(e);
          if (e instanceof Error) {
            console.log(e.message);
            setStatus({ success: false });
            setErrors({ submit: e.message });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setValues,
        initialValues,
      }) => (
        <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h4">Basic Information </Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="outlined-adornment-email-login">
                    First Name
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.first && errors.first)}
                  >
                    <TextField
                      id="first"
                      variant={isEditing ? "outlined" : "standard"}
                      disabled={!isEditing}
                      type="text"
                      value={values.first}
                      name="first"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormControl>
                  {touched.first && errors.first && (
                      <FormHelperText error>{errors.first}</FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="outlined-adornment-email-login">
                    Last Name
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.last && errors.last)}
                  >
                    <TextField
                      id="last"
                      variant={isEditing ? "outlined" : "standard"}
                      disabled={!isEditing}
                      type="text"
                      value={values.last}
                      name="last"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Email
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                >
                  <TextField
                    id="email"
                    variant={isEditing ? "outlined" : "standard"}
                    disabled={!isEditing}
                    type="text"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
               
                </FormControl>
                {touched.email && errors.email && (
                    <FormHelperText error>{errors.email}</FormHelperText>
                  )}
              </Grid>
              <Grid item>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Phone
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(touched.phone && errors.phone)}
                >
                  <TextField
                    id="phone"
                    variant={isEditing ? "outlined" : "standard"}
                    disabled={!isEditing}
                    type="text"
                    value={values.phone}
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h4">Address Information</Typography>
              </Grid>
              <Grid item>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Address Line 1
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(touched.address_1 && errors.address_1)}
                >
                  <TextField
                    id="address_1"
                    variant={isEditing ? "outlined" : "standard"}
                    disabled={!isEditing}
                    type="text"
                    value={values.address_1}
                    name="address_1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Address Line 2
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(touched.address_2 && errors.address_2)}
                >
                  <TextField
                    id="address_2"
                    variant={isEditing ? "outlined" : "standard"}
                    disabled={!isEditing}
                    type="text"
                    value={values.address_2}
                    name="address_2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="outlined-adornment-email-login">
                    City
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.city && errors.city)}
                  >
                    <TextField
                      id="city"
                      variant={isEditing ? "outlined" : "standard"}
                      disabled={!isEditing}
                      type="text"
                      value={values.city}
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="outlined-adornment-email-login">
                    State
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.state && errors.state)}
                  >
                    <TextField
                      id="state"
                      variant={isEditing ? "outlined" : "standard"}
                      disabled={!isEditing}
                      type="text"
                      value={values.state}
                      name="state"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="outlined-adornment-email-login">
                    Zip Code
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.zip && errors.zip)}
                  >
                    <TextField
                      id="zip"
                      variant={isEditing ? "outlined" : "standard"}
                      disabled={!isEditing}
                      type="text"
                      value={values.zip}
                      name="zip"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="outlined-adornment-email-login">
                    Country
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.country && errors.country)}
                  >
                    <TextField
                      id="country"
                      variant={isEditing ? "outlined" : "standard"}
                      disabled={!isEditing}
                      type="text"
                      value={values.country}
                      name="country"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent='space-between'>
          <Grid item xs={12} sm={6} container direction="column" spacing={2}>
            <Grid item marginTop={1}>
                <Typography variant="h4">Custom Profile Data</Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Pet Type
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(touched.petType && errors.petType)}
                >
                  
                  <TextField
                    select
                    id="petType"
                    variant={isEditing ? "outlined" : "standard"}
                    disabled={!isEditing}
                    type="text"
                    value={values.petType}
                    name="petType"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <MenuItem value="Dog">Dog</MenuItem>
                    <MenuItem value="Cat">Cat</MenuItem>
                    <MenuItem value="Both">Both</MenuItem>
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="Unknown">Unknown</MenuItem>
                  </TextField>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Pet Name
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(touched.petName && errors.petName)}
                >
                  <TextField
                    id="petName"
                    variant={isEditing ? "outlined" : "standard"}
                    disabled={!isEditing}
                    type="text"
                    value={values.petName}
                    name="petName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
                </Grid>
              </Grid>
             { breedsData?.breeds && <Grid item>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Pet Breed
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(touched.petBreed && errors.petBreed)}
                >
                  <TextField
                    id="petBreed"
                    variant={isEditing ? "outlined" : "standard"}
                    disabled={!isEditing}
                    select
                    value={values.petBreed}
                    name="petBreed"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {getPetBreeds(values.petType).map(breed=>(
                      <MenuItem key={breed.id} value={breed.id}>{breed.name}</MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>}
            </Grid>
            <Grid item xs={12} sm={6} container direction="column" spacing={2} justifyContent="flex-end">
            {isError && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{"Failed to Update Profile"}</FormHelperText>
            </Box>
          )}
        <Grid container justifyContent={'flex-end'}>
          <Grid item container spacing={2} xs={12} md={4} justifyContent="flex-end">
            {isEditing ? (
              <>
                <Grid item xs={12} md={6}>
                  <Button
                    sx={{ mt: 2 }}
                    disableElevation
                    fullWidth
                    size="large"
                    onClick={() => handleCancel(setValues, initialValues)}
                    variant="contained"
                    color="warning"
                    disabled={isLoading}
                  >
                    {"Cancel"}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    sx={{ mt: 2 }}
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    color="info"
                    variant="contained"
                    disabled={isLoading}
                  >
                    {isLoading ? <CircularProgress /> : "Update"}
                  </Button>
                </Grid>
              </>
            ) : (
              <Button
                sx={{ mt: 6 }}
                disableElevation
                size="large"
                variant="contained"
                onClick={() => setIsEditing(true)}
                color="primary"
              >
                {"Edit Profile"}
              </Button>
            )}
            </Grid>
          </Grid>
            </Grid>
          </Grid>
         
        </form>
      )}
    </Formik>
  );
};

export default EditProfileForm;
