import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'lib/contextLib';
// project imports


// user will be redirected to the login page when signed out

const AuthGuard = ({ children }: {children:React.ReactElement}) => {

    const navigate = useNavigate();
    const { isAuthenticated } = useAppContext();

   // check if user is authenticated or token is valid
    useEffect(() => {
        if (!isAuthenticated && !window.localStorage.getItem('token') || !window.localStorage.getItem('authenticated')) {
            navigate('/login', { replace: true });
        }
    }, [isAuthenticated, navigate, ]);
    return children;
};

export default AuthGuard;

