import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "lib/axios";

export const fetchProfileAction = createAsyncThunk<undefined, { page?: number, limit?: number, search?: string, field?: string, blockReason?: string, listId?: string }>(
    "profiles",
    async (arg, thinkAPI) => {
        const { data, status } = await axiosService.get("profiles", { params: arg });

        if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));
        return thinkAPI.fulfillWithValue({ ...data, arg });
    },
);