import React, { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
// third party
import * as Yup from "yup";
import { Avatar, Typography } from "@mui/material";
import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import { login } from "services/user";
import { Formik } from "formik";
import { useAppContext } from "lib/contextLib";
import { Email, VisibilityOff, Visibility, Lock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  
  const { setIsAuthenticated, setUser, isAuthenticated, setSnackbarState } = useAppContext();
  const navigate = useNavigate();
  const { isError, error, isLoading, mutateAsync } = useMutation(login, {
    onSuccess: ({ data }) => {
      // setIsAuthenticated(true);
      setUser(data);
      try {
        localStorage.setItem("user", JSON.stringify(data));
      } catch (e) {
        console.log(e);
      }
      navigate("/2fa");
    },
  });

  useEffect(() => {
    if (window.localStorage.getItem('token') && window.localStorage.getItem('authenticated')) {
        setSnackbarState({
          open: true,
          message: "Already Logged In",
          color: "success",
        });
        navigate('/', { replace: true });
    }
}, []);

  const theme = useTheme();
  const [checked, setChecked] = useState(true);

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={(
          { email, password },
          { setSubmitting, setErrors, setStatus }
        ) => {
          mutateAsync({ username: email.toLocaleLowerCase(), password });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container direction="column">
              <Grid
                item
                container
                justifyContent={"center"}
                alignItems="center"
                direction="column"
              >
                <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
              </Grid>
              <Grid item container direction="column">
                <Grid item>
                  <InputLabel
                    htmlFor="outlined-adornment-email-login"
                    sx={{ color: "grey.600" }}
                  >
                    Email
                  </InputLabel>
                </Grid>
                <Grid item>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                    sx={{ ...theme.customInput }}
                  >
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">
                          <Email sx={{ color: "grey.500" }} />
                        </InputAdornment>
                      }
                      id="outlined-adornment-email-login"
                      type="email"
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      autoComplete="email"
                      onChange={handleChange}
                      inputProps={{}}
                    />
                  </FormControl>
                  {touched.email && errors.email && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {errors.email}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid item container direction="column">
                <Grid item>
                  <InputLabel
                    htmlFor="outlined-adornment-email-login"
                    sx={{ color: "grey.600", display: "inline", my: 10 }}
                  >
                    Password
                  </InputLabel>
                </Grid>
                <Grid item>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    sx={{ ...theme.customInput }}
                  >
                    {/* <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel> */}
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock sx={{ color: "grey.500" }} />
                        </InputAdornment>
                      }
                      id="outlined-adornment-password-login"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? (
                              <Visibility sx={{ color: "grey.500" }} />
                            ) : (
                              <VisibilityOff sx={{ color: "grey.500" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{}}
                    />
                  </FormControl>
                  {touched.password && errors.password && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-password-login"
                    >
                      {errors.password}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <FormControlLabel
                  sx={{
                    color: "grey.600",
                  }}
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={(event) => setChecked(event.target.checked)}
                      name="checked"
                      color="primary"
                    />
                  }
                  label="Remember me"
                />
                <Typography
                  variant="subtitle1"
                  component={Link}
                  to="/reset-password"
                  color="grey.600"
                  sx={{ textDecoration: "none" }}
                >
                  Forgot Password?
                </Typography>
              </Grid>
              {isError && (
                <FormHelperText error>
                  {(error as any)?.response?.data?.error ||
                    "Something went wrong"}
                </FormHelperText>
              )}
              <Grid item mt={2} container>
                <Button
                  disableElevation
                  disabled={isLoading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {isLoading ? <CircularProgress /> : "Sign in"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>

      {/* <Box
        sx={{
          alignItems: "center",
          display: "flex",
          my: 2,
        }}
      >
        <Divider
          sx={{
            flexGrow: 1,
            opacity: "0.15",
            borderColor: theme.palette.common.white,
          }}
          orientation="horizontal"
        />
        <Typography color={"grey.600"} marginLeft={2} marginRight={2}>
          Don&apos;t have an account?
        </Typography>
        <Divider
          sx={{
            flexGrow: 1,
            opacity: "0.15",
            borderColor: theme.palette.common.white,
          }}
          orientation="horizontal"
        />
      </Box>
      <Box>
        <Button
          disableElevation
          fullWidth
          size="large"
          component={Link}
          to="/register"
          type="submit"
          variant="outlined"
          sx={{ color: theme.palette.common.black, borderColor: "grey.300" }}
        >
          Create Account
        </Button>
      </Box> */}
    </>
  );
};

export default LoginForm;
