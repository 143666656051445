import React from "react";
import { useMutation } from "@tanstack/react-query";
import { createUser, updateUser } from "services/user";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Email, VisibilityOff, Visibility, Lock } from "@mui/icons-material";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

interface Props {
  onSuccess: () => void;
  data?: any;
}
const CreateNewUserForm = ({ onSuccess, data = {} }: Props) => {
  const { mutate, isLoading, isError, error } = useMutation(
    Object.keys(data)?.length ? updateUser : createUser,
    {
      onError: (error) => {
        console.log(error);
      },
      onSuccess: onSuccess,
    }
  );
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  return (
    <Formik
      initialValues={{
        password: "",
        userName: data?.username ?? "",
        confirmPassword: "",
        isAdmin: data?.isAdmin ?? false,
        submit: null,
      }}
      validationSchema={
        !Object.keys(data)?.length
          ? Yup.object().shape({
              password: Yup.string()
                .max(255)
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                  "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                )
                .required("Password is required"),
              userName: Yup.string().required("Last Name is required"),
              confirmPassword: Yup.string().test(
                "passwords-match",
                "Passwords must match",
                function (value) {
                  return this.parent.password === value;
                }
              ),
            })
          : Yup.object().shape({
              userName: Yup.string().required("Last Name is required"),
              password: Yup.string()
                .max(255)
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                  "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                ),
              confirmPassword: Yup.string().test(
                "passwords-match",
                "Passwords must match",
                function (value) {
                  return this.parent.password === value;
                }
              ),
            })
      }
      onSubmit={async (values, { setSubmitting, setStatus, setErrors }) => {
        const user = {
          username: values.userName?.toLowerCase(),
          password: values.password,
          isAdmin: values.isAdmin,
          ...(Object.keys(data)?.length ? { id: data?.id } : {}),
        };
        try {
          await mutate(user);
          setSubmitting(false);
        } catch (e) {
          console.log(e);
          if (e instanceof Error) {
            console.log(e.message);
            setStatus({ success: false });
            setErrors({ submit: e.message });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <InputLabel
                htmlFor="outlined-adornment-email-login"
                sx={{ color: "grey.600" }}
              >
                Username
              </InputLabel>
              <FormControl
                fullWidth
                error={Boolean(touched.userName && errors.userName)}
              >
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <Email sx={{ color: "grey.500" }} />
                    </InputAdornment>
                  }
                  id="outlined-adornment-email-login"
                  type="text"
                  value={values.userName}
                  name="userName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{}}
                />
              </FormControl>
            </Grid>
          </Grid>

          <InputLabel
            htmlFor="outlined-adornment-email-login"
            sx={{ color: "grey.600", display: "inline", my: 10 }}
          >
            Password
          </InputLabel>
          <FormControl
            fullWidth
            error={Boolean(touched.password && errors.password)}
          >
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <Lock sx={{ color: "grey.500" }} />
                </InputAdornment>
              }
              id="outlined-adornment-password-login"
              type={showPassword ? "text" : "password"}
              value={values.password}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? (
                      <Visibility sx={{ color: "grey.500" }} />
                    ) : (
                      <VisibilityOff sx={{ color: "grey.500" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{}}
            />
          </FormControl>
          {touched.password && errors.password && (
            <FormHelperText error id="standard-weight-helper-text-email-login">
              {errors.password}
            </FormHelperText>
          )}
          <InputLabel
            htmlFor="outlined-adornment-email-login"
            sx={{ color: "grey.600", display: "inline", my: 10 }}
          >
            Confirm Password
          </InputLabel>
          <FormControl
            fullWidth
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
          >
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <Lock sx={{ color: "grey.500" }} />
                </InputAdornment>
              }
              id="outlined-adornment-password-login"
              type={showPassword ? "text" : "password"}
              value={values.confirmPassword}
              name="confirmPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? (
                      <Visibility sx={{ color: "grey.500" }} />
                    ) : (
                      <VisibilityOff sx={{ color: "grey.500" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{}}
            />
          </FormControl>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isAdmin"
                    id="isAdmin"
                    checked={values.isAdmin}
                    onChange={handleChange}
                  />
                }
                label="IsAdmin"
              />
            </Grid>
          </Grid>
          {touched.confirmPassword && errors.confirmPassword && (
            <FormHelperText error id="standard-weight-helper-text-email-login">
              {errors.confirmPassword}
            </FormHelperText>
          )}
          {isError && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {(error as any)?.response?.data?.error ||
                  "Failed to Create User"}
              </FormHelperText>
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
            <Button
              disableElevation
              disabled={isLoading}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
            >
              {isSubmitting ? (
                <CircularProgress />
              ) : Object.keys(data)?.length ? (
                "Update user"
              ) : (
                "Register"
              )}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateNewUserForm;
