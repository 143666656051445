import React from "react";
import {
  Grid,
  Button,
  InputLabel,
  FormControl,
  OutlinedInput,
  CircularProgress,
  Box,
  FormHelperText,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { createNewVendor } from "services/scrubs";


interface Props {
  onSuccess: () => void;
}
const CreateNewVendor = ({onSuccess}:Props) => {
  const { isError, isLoading, mutate } = useMutation((vendor: {name:string; cost?:number;valid_reason?:string}) => createNewVendor(vendor.name, vendor.valid_reason, vendor.cost),{
    onError: (error) => {
      console.log(error);
    },
    onSuccess:onSuccess
  });

  return (
    <Formik
      initialValues={{
        name: "",
        cost:8,
        valid_reason:"",
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Name is required"),
        cost: Yup.number(),
        valid_reason: Yup.string()

      })}
      onSubmit={(values, { setErrors, setStatus }) => {
        try {
          mutate(values);
        } catch (e) {
            console.log(e);
          if (e instanceof Error) {
            console.log(e.message)
            setStatus({ success: false });
            setErrors({ submit: e.message });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={1}>
            <Grid item container>
              <InputLabel htmlFor="outlined-adornment-email-login">
                Name
              </InputLabel>
              <FormControl
                fullWidth
                error={Boolean(touched.name && errors.name)}
              >
                <OutlinedInput
                  id="name"
                  type="text"
                  value={values.name}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{}}
                />
              </FormControl>
            </Grid>
            <Grid item container>
              <InputLabel htmlFor="outlined-adornment-email-login">
                Cost
              </InputLabel>
              <FormControl
                fullWidth
                error={Boolean(touched.cost && errors.cost)}
              >
                <OutlinedInput
                  id="cost"
                  type="number"
                  value={values.cost}
                  name="cost"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{}}
                />
              </FormControl>
            </Grid>
            <Grid item container>
              <InputLabel htmlFor="outlined-adornment-email-login">
                Valid Reason
              </InputLabel>
              <FormControl
                fullWidth
                error={Boolean(touched.valid_reason && errors.valid_reason)}
              >
                <OutlinedInput
                  id="reason"
                  type="text"
                  value={values.valid_reason}
                  name="valid_reason"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{}}
                />
              </FormControl>
            </Grid>
    
          </Grid>

          {isError && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{'Failed to Create New Vendor'}</FormHelperText>
            </Box>
          )}
          <Grid item container>
            <Button
              sx={{ mt: 2 }}
              disableElevation
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress /> : "Create Vendor"}
            </Button>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CreateNewVendor;
