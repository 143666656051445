import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from 'components/ui/Loading';
import { useGetRawById } from 'services/raw';
import ErrorAlert from 'components/ErrorAlert';
import { useAppContext } from 'lib/contextLib';
import { Grid } from '@mui/material';
import EditRawForm from 'components/forms/EditRawForm';
const RawItem = () => {
    const { id } = useParams<{ id: string }>();
    const { data, isLoading, isError,refetch } = useGetRawById(id!);
    const [isEditing, setIsEditing] = React.useState(false);
    const { setSnackbarState } = useAppContext();
    if (isLoading) {
        return <Loading />;
    }
    if (isError) {
        return <ErrorAlert />;
    }
    const handleSuccess = () => {
        setSnackbarState({
            open: true,
            message: 'Raw updated successfully',
            color: 'success',
        });
        setIsEditing(false);
        refetch();
    }
  return (
    <Grid container>
        {data && <EditRawForm profile={data} 
         isEditing={isEditing} setIsEditing={setIsEditing}
         onSuccessfulUpdate={handleSuccess} 
        />}
    </Grid>
  )
}

export default RawItem