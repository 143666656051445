import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { TableState } from "store/reducers/table.reducer";
import { fetchAuditAction, fetchBreedAction, fetchRawsAction } from "store/actions/table.action";

interface TableSearchParams {
    page: number,
    limit: number,
    search?: string,
    field?: string
}

const useTable = () => {
    const { raws, audit, breed, isToast, message, error, isLoading } = useSelector<RootState, TableState>((state: any) => state.table);
    const dispatch = useDispatch();

    const getAllRawsList = (params: TableSearchParams) => {
        return dispatch<any>(fetchRawsAction(params));
    };

    const getAllAuditList = (params: TableSearchParams) => {
        return dispatch<any>(fetchAuditAction(params));
    };

    const fetchAllBreedAction = (params: TableSearchParams) => {
        return dispatch<any>(fetchBreedAction(params));
    };

    return {
        raws,
        audit,
        breed,
        getAllRawsList,
        getAllAuditList,
        fetchAllBreedAction,
        isToast, message, error, isLoading
    };
};

export default useTable;
