import React from "react";
import { Grid, TableRow, TableCell, Button } from "@mui/material";
import EnhancedCustomTable, { RowProps } from "components/EnhancedCustomTable";
import { deleteAllowedTLDs, getAllowedTLDs } from "services/settings";
import DialogComponent from "components/ui/Dialog";
import CreateNewTLD from "components/forms/CreateNewTLD";
import { useAppContext } from "lib/contextLib";
import { useMutation } from "@tanstack/react-query";
import { rowsPerFetch } from "../../constants";
import ErrorAlert from "components/ErrorAlert";
import useQuery from "lib/useQuery";
import { useNavigate } from "react-router-dom";
import useSetting from "hooks/useSetting";

const tldHeadCells = [
  { id: "tld", numeric: false, label: "TLD" },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Date Added",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "right",
  }
];


const AllowedTLDs = () => {
  const [activePage, setActivePage] = React.useState(0);
  const { allowedTLDs, getAllowedTlds } = useSetting();
  const query = useQuery();
  const { setSnackbarState } = useAppContext();

  const search = query.get("search") || undefined;
  const field = query.get("field") || undefined;

  React.useEffect(() => {
    if (search || field) {
      setActivePage(0)
      handleSearch({ page: 0 });
    }
  }, [search, field])

  React.useEffect(() => {
    if ((activePage !== 0 && activePage > (allowedTLDs?.lodedPageData as any) && allowedTLDs?.allowedTLDs?.length !== allowedTLDs?.total) ||
      (!allowedTLDs?.allowedTLDs?.length || allowedTLDs?.isFiltered)) {
      handleSearch()
    }
  }, [activePage]);

  const handleSearch = ({ ...query } = {}) => {
    getAllowedTlds({ page: activePage, limit: rowsPerFetch, search, field, ...query })
  }

  const incrementPage = (value: number) => {
    setActivePage(value);
  }

  const { mutate: deleteAllowedTLDsMutation, isLoading: isDeletingTLD } = useMutation(deleteAllowedTLDs, {
    onSuccess: () => {
      setSnackbarState({
        open: true,
        message: "TLD deleted successfully",
        color: "success",
      });
      handleSearch();
    },
    onError: (error) => {
      setSnackbarState({
        open: true,
        message: "Error deleting TLD",
        color: "error",
      });
    }
  })


  const [isCreateNewTldOpen, setIsCreateNewTldOpen] = React.useState(false);

  const handleCreateTLDSuccess = () => {
    setIsCreateNewTldOpen(false);
    setSnackbarState({
      open: true,
      message: "TLD added successfully",
      color: "success",
    });
    handleSearch();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {allowedTLDs?.error ? <ErrorAlert /> : <EnhancedCustomTable
          selectionKey="id"
          Row={TLDRow}
          onDeleteClick={(ids) => deleteAllowedTLDsMutation(ids)}
          title="Allowed TLDs"
          headCells={tldHeadCells}
          rows={allowedTLDs?.allowedTLDs || []}
          pageParent={activePage}
          incrementFetch={incrementPage}
          fieldsToSearch={["TLD"]}
          totalCountParent={allowedTLDs?.total}
          isLoading={allowedTLDs?.isLoading || isDeletingTLD}
          onAddClick={() => {
            setIsCreateNewTldOpen(true);
          }}
        />}
      </Grid>
      <DialogComponent
        isDialogOpen={isCreateNewTldOpen}
        closeDialog={() => {
          setIsCreateNewTldOpen(false);
        }}
        title="Add Allowed TLD"
      >
        <CreateNewTLD onSuccess={handleCreateTLDSuccess} />
      </DialogComponent>
    </Grid>
  );
};

function TLDRow({ handleClick, row, isItemSelected, labelId }: RowProps) {
  const { id, tld, created } = row;
  const navigate = useNavigate();
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigate(`/settings/allowed-tlds/${id}`);
  }
  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={id}
      selected={isItemSelected}
    >
      <TableCell component="th" id={labelId} scope="row" align={'left'}>
        {tld}
      </TableCell>
      <TableCell align="left">
        {new Date(created).toLocaleDateString()}
      </TableCell>
      <TableCell align="right">
        <Button
          onClick={handleButtonClick}
          variant="contained"
        >View</Button>
      </TableCell>
    </TableRow>
  );
}



export default AllowedTLDs;
