import React from 'react'
import { getRaw } from 'services/raw';
import { Grid, TableCell, TableRow, Button } from '@mui/material';
import useQuery from 'lib/useQuery';
import ErrorAlert from 'components/ErrorAlert';
import { Raw as RawType } from 'types';
import { RowProps } from 'components/EnhancedCustomTable';
import EnhancedCustomTable from 'components/EnhancedCustomTable';
import { rowsPerFetch } from '../../constants';
import { useNavigate } from 'react-router-dom';
import useTable from 'hooks/useTable';

const rawHeadCells = [
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "first",
    numeric: false,
    disablePadding: false,
    label: "First",
  },
  {
    id: "last",
    numeric: false,
    disablePadding: false,
    label: "Last",
  },
  { id: "phone", numeric: false, disablePadding: false, label: "Phone" },
  { id: "country", numeric: false, disablePadding: false, label: "Country" },
  { id: 'address_1', numeric: false, disablePadding: false, label: "Address 1" },
  { id: 'address_2', disablePadding: false, label: "Address 2" },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions', align: 'right' }
];


const Raw = () => {
  const query = useQuery();
  const { raws, getAllRawsList } = useTable();
  const [activePage, setActivePage] = React.useState(0);

  const search = query.get('search') || undefined;
  const field = query.get('field') || undefined;

  React.useEffect(() => {
    if (search || field) {
      setActivePage(0)
      handleSearch({ page: 0 });
    }
  }, [search, field])

  React.useEffect(() => {
    if ((activePage !== 0 && activePage > (raws?.lodedPageData as any) && raws?.raws?.length !== raws?.total) ||
      (!raws?.raws?.length || raws?.isFiltered)) {
      handleSearch()
    }
  }, [activePage]);

  const incrementFetch = (val: number) => {
    setActivePage(val);
  };

  const handleSearch = ({ ...query } = {}) => {
    getAllRawsList({ page: activePage, limit: rowsPerFetch, search, field, ...query })
  }
  return (
    <Grid container>
      {raws?.error && <ErrorAlert />}
      {
        <EnhancedCustomTable
          rows={raws?.raws || []}
          isLoading={raws?.isLoading || false}
          totalCountParent={raws?.total}
          pageParent={activePage}
          incrementFetch={incrementFetch}
          headCells={rawHeadCells}
          selectionKey="id"
          title='Raw'
          fieldsToSearch={['email', 'first', 'last', 'phone', 'country', 'address_1', 'address_2', 'list_id']}
          onDeleteClick={(ids: string[]) => { console.log('no delete', ids) }}
          Row={RawRow}
        />
      }
    </Grid>
  )
}

function RawRow({
  handleClick,
  row,
  selectionKey,
  isItemSelected,
  labelId, }: RowProps<RawType>) {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate(`/tables/raw/${row.id}`);
  }
  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, (row as any)[selectionKey])}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell component="th" id={labelId} scope="row" align='left'>
        {row.email}
      </TableCell>
      <TableCell align="left">{row.first}</TableCell>
      <TableCell align="left">{row.last}</TableCell>
      <TableCell align="left">{row.phone}</TableCell>
      <TableCell align="left">{row.country}</TableCell>
      <TableCell align="left">
        {row.address_1}
      </TableCell>
      <TableCell align="left">
        {row.address_2}
      </TableCell>
      <TableCell align="right">
        <Button variant='contained' color='primary' onClick={handleButtonClick}>View</Button>
      </TableCell>
    </TableRow>
  )
}

export default Raw