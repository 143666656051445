import React from "react";
import { Snackbar as MuiSnackbar } from "@mui/material";
import { useAppContext } from "lib/contextLib";
import Alert from "./Alert";

const Snackbar = () => {
const { handleSnackbarClose,snackbarState: {color, message,open} } = useAppContext();
  return (
    <MuiSnackbar
      open={open}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={color}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
