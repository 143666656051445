import React from "react";
import {
  Grid,
  Button,
  InputLabel,
  FormControl,
  OutlinedInput,
  CircularProgress,
  Box,
  Divider,
  FormHelperText,
  Typography,
  useTheme
} from "@mui/material";
import { FileUpload as FileUploadIcon } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "lib/contextLib";
import { splitTextIntoArray } from "lib/splitTextIntoArray";
import {  addToBlockedRoles } from "services/settings";
interface Props {
  onSuccess: () => void;
}
const CreateNewBlockedRole = ({ onSuccess }: Props) => {
  const theme = useTheme();
  const [file, setFile] = React.useState<File>();
  const {setSnackbarState} = useAppContext();
  const { isError, isLoading, mutate } = useMutation(
    addToBlockedRoles,
    {
      onError: (error) => {
        console.log(error);
      },
      onSuccess: onSuccess,
    }
  );

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size / 1000000 > 5) {
        setSnackbarState({
          open: true,
          message: "File size should be less than 5MB",
          color: "error",
        });
      } else {
        setFile(file);
      }
    }
  }
  return (
    <Formik
      initialValues={{
       role:"",
       submit:""
      }}
      validationSchema={Yup.object().shape({
       role: Yup.string()
      })}
      onSubmit={(values, { setErrors, setStatus }) => {
        try {
          if (file) {
           
            const fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onload = () => {
              const fileContent = fileReader.result;
              console.log(fileContent);
              if (fileContent) {
                const roles = splitTextIntoArray(fileContent as string);
                console.log(roles);
                mutate({ roles });
              }
            };
          }
          else{
            mutate({role:values.role});
          }
        
        } catch (e) {
          console.log(e);
          if (e instanceof Error) {
            console.log(e.message);
            setStatus({ success: false });
            setErrors({ submit: e.message });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={1}>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={12}>
                <InputLabel htmlFor="outlined-adornment-role-login">
                  Role
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.role && errors.role
                  )}
                >
                  <OutlinedInput
                    id="role"
                    type="text"
                    name="role"
                    disabled={!!file}
                    value={values.role}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                </FormControl>
              </Grid>
            </Grid>
            
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                my: 2,
              }}
            >
              <Divider
                sx={{
                  flexGrow: 1,
                  opacity: "0.15",
                  borderColor: theme.palette.common.black,
                }}
                orientation="horizontal"
              />
              <Typography color={"grey.600"} marginLeft={2} marginRight={2}>
                OR
              </Typography>
              <Divider
                sx={{
                  flexGrow: 1,
                  opacity: "0.15",
                  borderColor: theme.palette.common.black,
                }}
                orientation="horizontal"
              />
            </Box>
            <Button
              component="label"
              color="info"
              variant="contained"
              startIcon={<FileUploadIcon />}
            >
              {file ? `(${file.name})` : "Import from File"}
              <input
                type="file"
                accept={".csv,.txt"}
                hidden
                onChange={handleFileInput}
              />
            </Button>           

          {isError && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {"Failed to Add"}
              </FormHelperText>
            </Box>
          )}
          <Grid item container>
                <Button
                sx={{ mt: 2 }}
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress />
                ) : file ? (
                  "Upload"
                ) : (
                  "Add to Blocked Roles"
                )}
              </Button>
          </Grid>
        </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CreateNewBlockedRole;
