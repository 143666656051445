import React from 'react'
import { useGetUser, updateUser } from 'services/user';
import { useParams } from 'react-router-dom';
import EditSettings from 'components/forms/EditSettingsForm';
import Loading from 'components/ui/Loading';
import * as Yup from 'yup';
const User = () => {
  const { id } = useParams();
  const { data, isLoading, isError: isErrorLoading, refetch } = useGetUser(id!);
  const [isEditing, setIsEditing] = React.useState(false);
  console.log(data)
  return (
    <>
      {data ? <EditSettings

        isEditing={isEditing}
        setIsEditing={setIsEditing}
        mutation={updateUser}
        initialValues={{ username: data.user.username }}
        refetch={refetch}
        label="Username"
        name='username'
        validationSchema={{
          username: Yup.string().required('Required'),
        }}

      /> : <Loading />}
    </>
  )
}

export default User