import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { getScrubVendors, deleteVendor } from 'services/scrubs';
import EnhancedCustomTable from 'components/EnhancedCustomTable';
import { Grid } from '@mui/material';
import { RowProps } from 'components/EnhancedCustomTable';
import { rowsPerFetch } from '../constants';
import DialogComponent from 'components/ui/Dialog';
import { TableCell, TableRow, Button } from '@mui/material';
import useQuery from 'lib/useQuery';
import CreateNewVendor from 'components/forms/CreateVendorForm';
import { useAppContext } from 'lib/contextLib';
import { useNavigate } from 'react-router-dom';
import useScrub from 'hooks/useScrub';
const headCellsVendors = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'name',
  },
  {
    id: 'cost',
    numeric: false,
    disablePadding: false,
    label: 'cost',
  },
  {
    id: 'valid_reason',
    numeric: false,
    disablePadding: false,
    label: 'valid reason',
  },
  {
    id: 'created',
    numeric: false,
    disablePadding: false,
    label: 'Created At',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
    align: 'right',
  }
];
export default function ScrubVendors() {
  const { getScrubVendors, scrubVendors: allScrubVendors, isLoading: isFetchLoading } = useScrub();
  const { setSnackbarState } = useAppContext();
  const query = useQuery();
  const [activePage, setActivePage] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [scrubVendors, setScrubVendors] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const search = query.get("search") || undefined;
  const field = query.get("field") || undefined;

  const handleSearch = () => {
    getScrubVendors({ page: activePage, limit: rowsPerFetch, search, field })
  }

  React.useEffect(() => {
    if (activePage !== 0)
      getScrubVendors({ page: activePage, limit: rowsPerFetch, search, field })
  }, [activePage]);

  React.useEffect(() => {
    if (search || field || !allScrubVendors?.scrubVendors.length)
      handleSearch();
  }, [search, field]);

  React.useEffect(() => {
    setIsLoading(isFetchLoading)
  }, [isFetchLoading])

  React.useEffect(() => {
    setTotalCount(allScrubVendors?.total ?? 0);
    setScrubVendors(allScrubVendors?.scrubVendors ?? [])
  }, [allScrubVendors?.scrubVendors.length || allScrubVendors?.total])

  const { mutate: deleteVendorMutation } = useMutation(deleteVendor, {
    onSuccess: () => {
      setSnackbarState({ open: true, message: 'Vendor deleted successfully', color: 'success' });
      handleSearch();
    },
    onError: () => {
      setSnackbarState({ open: true, message: 'Error deleting vendor', color: 'error' });
    }
  });

  const [isVendorDialogOpen, setIsVendorDialogOpen] = React.useState(false);
  const onCreateVendorSuccess = () => {
    setIsVendorDialogOpen(false);
    setSnackbarState({
      open: true,
      message: "Vendor created successfully",
      color: "success",
    });
    handleSearch();
  }

  const onVendorDeleteClick = (ids: any[]) => {
    deleteVendorMutation(ids)
  }
  return (
    <Grid container direction="column">
      <EnhancedCustomTable onDeleteClick={onVendorDeleteClick}
        onAddClick={() => setIsVendorDialogOpen(true)}
        Row={VendorRow}
        selectionKey='id'
        rows={scrubVendors}
        isLoading={isLoading}
        title="Scrub Vendors"
        totalCountParent={totalCount}
        incrementFetch={(val) => setActivePage(val)}
        fieldsToSearch={["name"]}
        pageParent={activePage}
        headCells={headCellsVendors} />
      <DialogComponent isDialogOpen={isVendorDialogOpen} closeDialog={() => setIsVendorDialogOpen(false)} title="Add Vendor" >
        <CreateNewVendor onSuccess={onCreateVendorSuccess} />
      </DialogComponent>
    </Grid>
  );
}

function VendorRow({ handleClick, row, selectionKey, isItemSelected, labelId }: RowProps) {
  const navigate = useNavigate();
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigate(`/scrubs/vendors/${row.id}`);
  }
  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, (row as any)[selectionKey])}
      // role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left">{row.cost ? row.cost : "-"}</TableCell>
      <TableCell align="left">{row.valid_reason ? row.valid_reason : "-"}</TableCell>
      <TableCell align="left">{new Date(row.created).toLocaleString()}</TableCell>
      <TableCell align="right">
        <Button variant="contained" onClick={handleButtonClick}>View</Button>
      </TableCell>
    </TableRow>
  )
}
