import { useQuery } from '@tanstack/react-query';
import axiosService from 'lib/axios';

const route = 'breeds';

export async function getBreeds(page: number, limit: number, search?: string, field?: string) {

    const { data } = await axiosService.get(`${route}`, {
        params: {
            page,
            limit,
            search,
            field,
        }
    });
    return data;
}

export function useGetBreeds(page: number, limit: number) {
    return useQuery([route, page, limit], () => getBreeds(page, limit), {
        keepPreviousData: true,
    });
}

export function deleteBreeds(ids: number[] | string[]) {
    return axiosService.delete(route, { data: { ids } });
}


async function getBreed(id: string) {
    const { data } = await axiosService.get(`${route}/${id}`);
    return data;
}

export function useGetBreed(id: string) {
    return useQuery([route, id], () => getBreed(id), {
        enabled: !!id,
    });
}

export async function updateBreed({ id, ...data }: any) {
    return axiosService.put(`${route}/${id}`, data);
}

export async function createBreed(data: any) {
    return axiosService.post(`${route}`, data);
}