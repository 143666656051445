import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  OutlinedInput,
  CircularProgress,
  TextareaAutosize,
  Box,
  FormHelperText,
  Checkbox,
  Select,
  Typography,
  TextFieldProps
} from "@mui/material";
import { DateRange } from "@mui/icons-material";

import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { useGetLists } from "services/lists";
import { createJob, Job } from "services/jobs";
import { daysOfWeek, ispList } from "../../constants";
import { useGetMailwizzLists } from "services/lists";
import moment from "moment";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

interface Props {
  onSuccess: () => void;
}

export const statuses = ['Off', 'Finished', 'Pending', 'Failed', 'Running' ]
const CreateNewJob = ({ onSuccess }: Props) => {
  const { data: lists } = useGetLists();
  const { data: mailwizzLists, error: errorList, isLoading: isLoadingList  } = useGetMailwizzLists();
  const { isError, isLoading, mutate } = useMutation(
    (job: Partial<Job>) => createJob(job),
    {
      onError: (error) => {
        console.log(error);
      },
      onSuccess: onSuccess,
    }
  );
  
  const getCurDate = () => {
    const currentDate = moment().add(1, 'days').format("YYYY-MM-DD");
    const [ year, month, day ]: any = currentDate.split("-");
    console.log('new Date(year, month-1, day): ', new Date(year, month-1, day));
    return new Date(year, month-1, day);
  }

  return (
    <Formik
      initialValues={{
        list_ids: [],
        name:"",
        dailyLeadCap: 100000,
        notes: "",
        recurring: true,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        dayLastMailed:0,
        status:'PENDING',
        includeISP: [],
        mailwizzList:"",
        launchDate: '',
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        list_ids: Yup.array().required("List is required").min(1,'At least one list is required'),
        dailyLeadCap: Yup.number().required("Daily Lead Cap Required").lessThan(10000000),
        notes: Yup.string(),
        name: Yup.string().required("Name is Required"),
        recurring: Yup.boolean(),
        monday: Yup.boolean(),
        tuesday: Yup.boolean(),
        wednesday: Yup.boolean(),
        thursday: Yup.boolean(),
        friday: Yup.boolean(),
        saturday: Yup.boolean(),
        sunday: Yup.boolean(),
        dayLastMailed: Yup.number(),
        status: Yup.string(),
        includeISP: Yup.array().required("Include ISP required").min(1,'At least one ISP required'),
        launchDate: Yup.string().required("Launch Date required"),
        mailwizzList: Yup.string().required("Mailwizz List is Required"),
      })}
      onSubmit={(values, { setErrors, setStatus }) => {
        try {
           if(values.list_ids.length > 0 && values.includeISP.length > 0){
            if(values.sunday !== false || values.monday !== false || values.tuesday !== false || values.wednesday !== false || values.thursday !== false || values.friday !== false || values.saturday !== false ){
              mutate({
                ...values,
                dailyLeadCap: parseInt( ("" + values.dailyLeadCap))
              });
            }
           }
        } catch (e) {
          console.log(e);
          if (e instanceof Error) {
            console.log(e.message);
            setStatus({ success: false });
            setErrors({ submit: e.message });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue
      }) => (
        
        <form noValidate onSubmit={handleSubmit}>
          {
          isLoadingList ? 
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography variant="h3">Loading...</Typography>
          </Box>
          :
          // errorList ? 'Error Fetching Data' :
          // && mailwizzLists && mailwizzLists?.count && parseInt(mailwizzLists.count.toString()) > 0
          lists ? ( 
          <Grid container direction="column" spacing={2} p={2} pb={0} pt={1}>
            <Grid item >
            <InputLabel htmlFor="outlined-adornment-email-login">
                 Name
            </InputLabel>
            <FormControl
              fullWidth
              error={Boolean(touched.name && errors.name)}
            >
              <OutlinedInput
                id="outlined-adornment-email-login"
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
           
            </FormControl>
            {touched.name && errors.name && (
                <FormHelperText error>{errors.name}</FormHelperText>
              )}
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                 Select List
                </InputLabel>
                <FormControl fullWidth error={Boolean(touched.list_ids && errors.list_ids)}>
                  <Select
                  multiple
                    id="list_ids"
                    name="list_ids"
                    value={values.list_ids}
                    onBlur={handleBlur}
                    onChange={(e)=>{
                      const {value} = e.target;
                      setFieldValue("list_ids", typeof value === 'string' ? value.split(',') : value);
                    }}
                  >
                  {lists.length > 0
                    ? lists.map((list) => (
                        <MenuItem key={list.id} value={list.id}>
                          {list.list_name}
                        </MenuItem>
                      ))
                    : "No Lists"}
                  </Select>
               
                </FormControl> 
                {touched.list_ids && errors.list_ids && (
                <FormHelperText error>{errors.list_ids}</FormHelperText>
              )}
              </Grid>
            
            <Grid item xs={12} md={6}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Daily Lead Cap
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.dailyLeadCap && errors.dailyLeadCap
                  )}
                >
                  <OutlinedInput
                    id="dailyLeadCap"
                    type="number"
                    name="dailyLeadCap"
                    value={values.dailyLeadCap}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container spacing={2} >
              <Grid item xs={12} md={6}>
              <InputLabel htmlFor="outlined-adornment-email-login">
                Recurring?
              </InputLabel>
              <FormControl
                error={Boolean(touched.recurring && errors.recurring)}
              >
                <Checkbox
                  checked={values.recurring}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="recurring"
                  inputProps={{ "aria-label": "controlled" }}
                />
              </FormControl>
              </Grid>
              <Grid item xs={12} md={6} container spacing={2}>
                {daysOfWeek.map((day) => (
                    <Grid item key={day}>
                  <InputLabel htmlFor="outlined-adornment-email-login">
                      {capitalizeFirstLetter(day)}
                  </InputLabel>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          (touched as any)[day] && (errors as any)[day]
                        )}
                      >
                        <Checkbox
                          checked={(values as any)[day]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={day}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </FormControl>
                    </Grid>
                  ))
                  }
                  {
                    (touched.sunday && values.sunday !== true &&
                     touched.monday && values.monday !== true &&
                     touched.tuesday && values.tuesday !== true &&
                     touched.wednesday && values.wednesday !== true &&
                     touched.thursday && values.thursday !== true &&
                     touched.friday && values.friday !== true &&
                     touched.saturday && values.saturday !== true ) ? 
                      <Typography className="error-message">Days are Required</Typography> 
                     : ""
                  }
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={6}>
              <InputLabel htmlFor="outlined-adornment-email-login">
                  Days Last Mailed
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.dayLastMailed && errors.dayLastMailed
                  )}
                >
                  <OutlinedInput
                    id="dayLastMailed"
                    type="number"
                    name="dayLastMailed"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
              <InputLabel htmlFor="outlined-adornment-email-login">
                 Status
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    id="status"
                    name="status"
                    value={values.status}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {
                      statuses.map((status)=>(
                        <MenuItem key={status} value={status.toUpperCase()}>
                          {status}
                        </MenuItem>
                      ))
                    }
                  </Select>     
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
            <Grid item xs={12} md={6}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Include ISP
                </InputLabel>
                <FormControl fullWidth error={Boolean(touched.includeISP && errors.includeISP)}>
                  <Select
                    multiple
                    id="includeISP"
                    name="includeISP"
                    value={values.includeISP}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {
                      ispList.map((list)=>(
                        <MenuItem key={list} value={list} disabled={values.includeISP.length > 0 && !(values.includeISP[0] === list)}>
                          {list === "None" ? "All Not G&M" : list}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {touched.includeISP && errors.includeISP && (
                  <FormHelperText error>{errors.includeISP}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Mailwizz List
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.mailwizzList && errors.mailwizzList)}
                  >
                    <Select
                      id="mailwizzList"
                      name="mailwizzList"
                      value={values.mailwizzList}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                    { mailwizzLists && mailwizzLists?.count && parseInt(mailwizzLists.count.toString()) > 0 ? mailwizzLists.records.map(list=>(
                      <MenuItem key={list.general.list_uid} value={list.general.list_uid}>
                        {list.general.name}
                      </MenuItem>
                    )) : 
                    <MenuItem disabled={true} key={"nodata"} value={""}>
                      {"No Mailwizz List Found"}
                    </MenuItem>
                    }
                    </Select>     
                  </FormControl>
                  {touched.mailwizzList && errors.mailwizzList && (
                  <FormHelperText error>{errors.mailwizzList}</FormHelperText>
                )}
              </Grid>
            </Grid>


            <Grid item container spacing={2}>
            <Grid item xs={12} md={6}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Launch Date
                </InputLabel>
                <FormControl fullWidth error={Boolean(touched.launchDate && errors.launchDate)}>

                <DatePicker
                  className={`date-picker ${touched.launchDate && errors.launchDate ? "date-border" : ""} date-outline`}
                  minDate={new Date()}
                  // getCurDate()
                  onChange={(e: any)=>{
                    const value = moment(e).format("YYYY-MM-DD");
                    setFieldValue("launchDate", value);
                  }}
                  value={values.launchDate}
                  selected={values?.launchDate ?  new Date(moment(values.launchDate).format("YYYY-MM-DD")) : new Date()}
                  showIcon
                  icon={<DateRange />}
                  />
                  {/* getCurDate() */}

                {touched.launchDate && errors.launchDate && (
                  <FormHelperText sx={{marginLeft: 0}} error>{errors.launchDate}</FormHelperText>
                )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid item>
              <InputLabel htmlFor="outlined-adornment-email-login">
                Notes
              </InputLabel>
              <FormControl
                fullWidth
                error={Boolean(touched.notes && errors.notes)}
              >
                <TextareaAutosize
                  name="notes"
                  minRows={3}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
            </Grid>
          </Grid>
          )
          : 'No Data Found'}

          {isError && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {"Failed to Create New Job"}
              </FormHelperText>
            </Box>
          )}
          <Grid item container>
            <Button
              sx={{ mt: 3 }}
              disableElevation
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress /> : "Create New Job"}
            </Button>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

function capitalizeFirstLetter(string:string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default CreateNewJob;
