import { useQuery } from "@tanstack/react-query";
import axiosService from "lib/axios";
import { Profile } from "types";
const route = "profiles";



export async function getProfiles (activePage:number, limit:number,search?:string,field?:string, blockReason?:string, listId?:string):Promise<{
    profiles:Profile[];
    page:number;
    limit:number;
    totalCount:string;
}> {
   

    const { data } = await axiosService.get(`${route}`, {
        params:{
            page:activePage,
            limit,
            search,
            field,
            blockReason,
            listId
        }
    });
    if(data.count){
        window.localStorage.setItem("countProfiles", data.count);
    }
    return {
        profiles: data.profiles,
        page: data.page,
        limit: data.limit,
        totalCount: data.count || window.localStorage.getItem("countProfiles")
    };
}




async function getProfile(id:string |number):Promise<{
    profile:Profile;
    scrubInformation:any[]
}> {
    const { data } = await axiosService.get(`${route}/${id}`);
    return data;
}

export function useGetProfile(id:string | number) {
    return useQuery([route, id], () => getProfile(id), {
        keepPreviousData: true,
    });
}

interface updateSchema {
    id:string | number;
    profile:Partial<Profile>;
}
export function updateProfile({id,profile}:updateSchema){
    return axiosService.put(`${route}/${id}`, profile);
}

