import React, { Ref } from "react";

// material-ui
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  CardProps,
  CardHeaderProps,
  CardContentProps,
} from "@mui/material";

// project imports
import { KeyedObject } from "types";

// constant
const headerSX = {
  "& .MuiCardHeader-action": { mr: 0 },
  padding:'8px'
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

export interface MainCardProps extends KeyedObject {
  border?: boolean;
  boxShadow?: boolean;
  children?: React.ReactNode | string;
  style?: React.CSSProperties;
  className?: string;
  contentClass?: string;
  contentSX?: CardContentProps["sx"];
  sx?: CardProps["sx"];
  secondary?: CardHeaderProps["action"];
  elevation?: number;
  title?: React.ReactNode | string;
  cardHeader?:boolean;
  noContent?:boolean;
}

const MainCard = React.forwardRef(
  (
    {
      border = false,
      children,
      contentClass = "",
      contentSX = {},
      secondary,
      sx = {},
      title,
      cardHeader,
      noContent,
      ...others
    }: MainCardProps,
    ref: Ref<HTMLDivElement>
  ) => {

    return (
      <Card
        ref={ref}
       
        sx={{
          border: border ? "1px solid" : "none",
          color: "white", 
          width:'100%',
          marginTop:title?'8px':undefined,
          minHeight:'160px',
          ...sx,
        }}
        {...others}
      >
        {/* card header and action */}
     {(cardHeader || title) &&  <CardHeader
          sx={headerSX}
          title={<Typography align="center" variant="h3">{title}</Typography>}
          action={secondary}
        /> }


        {/* content & header divider */}
        {title && <Divider />}

        {/* card content */}
          {noContent?  <>{children}</>:  <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>  }
        
  
      </Card>
    );
  }
);
MainCard.displayName="MainCard"
export default MainCard;
