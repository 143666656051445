
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import {  useTheme } from "@mui/material/styles";
import Sidebar from 'components/ui/Sidebar';
import { drawerWidth } from '../../../constants'
import ToolBar from 'components/ui/ToolBar';
import { Outlet } from 'react-router-dom';
import AuthGuard from 'lib/routeGuards/AuthGuard';

export default function ResponsiveDrawer() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  return (
    <AuthGuard>
    <Box sx={{ display: 'flex'}}>
      <CssBaseline />
      <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      {/* <AppBar
        position="fixed"
        
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          border: `1px solid ${theme.palette.grey[300]}`
        }}
      >
        <ToolBar handleDrawerToggle={handleDrawerToggle}/>
      </AppBar>
    */}
      <Box
        component="main"
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)`,...theme.mainContent } }}
      > 
         <Outlet />
      </Box>
    </Box>
    </AuthGuard>
  );
}
