import React from "react";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  useTheme
} from "@mui/material";

import EnhancedTableHead from "./Head";
import EnhancedTableToolbar from "./ToolBar";
import Paper from "@mui/material/Paper";
import { rowsPerFetch, drawerWidth } from "../../constants";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  try {

    if ((a?.[orderBy] as unknown as string)?.toString()?.includes('-')) throw new Error('Date')

    const first = parseInt(a[orderBy] as unknown as string);
    const second = parseInt(b[orderBy] as unknown as string);

    if (isNaN(first) || isNaN(second)) {
      throw new Error("Not a number");
    }
    if (second < first) {
      return -1;
    }
    if (second > first) {
      return 1;
    }
  }
  catch (err) {
    console.log(err);
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  return 0;
}
export type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: string,
  orderBy: Key
): (
  a: { [key in Key]: number | string | any },
  b: { [key in Key]: number | string | any }
) => number {

  return order === "desc"
    ? (a, b) => {
      return descendingComparator(a, b, orderBy)
    }
    : (a, b) => -descendingComparator(a, b, orderBy);
}


interface Props<t = any> {
  rows: t[] | undefined;
  headCells: any[];
  title: string;
  isLoading: boolean;
  selectionKey: string;
  Row: React.FC<RowProps>;
  onAddClick?: () => void;
  onDeleteClick: (ids: string[]) => void;
  pageParent?: number;
  totalCountParent?: number;
  incrementFetch?: (a: number) => void
  fieldsToSearch?: any[]
  orderP?: string;
  orderByP?: string;
  onSingleJobDelete?: any;
  onRestartJob?: any;
}
const EnhancedCustomTable = ({
  rows,
  headCells,
  title,
  isLoading,
  selectionKey,
  Row,
  onAddClick,
  onDeleteClick,
  pageParent,
  incrementFetch,
  totalCountParent,
  fieldsToSearch,
  orderP,
  orderByP,
  onSingleJobDelete,
  onRestartJob
}: Props) => {
  const [order, setOrder] = React.useState<string>(orderP || "desc");
  const [orderBy, setOrderBy] = React.useState<string>(orderByP || selectionKey);
  const [selected, setSelected] = React.useState<any[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
    numeric?: boolean
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  React.useEffect(() => {
    setSelected([]);
  }, [rows])
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (rows) {
        const newSelecteds = rows.map((n) => (n as any)[selectionKey]);
        setSelected(newSelecteds);
        return;
      }
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (pageParent !== undefined && newPage * rowsPerPage >= ((pageParent + 1) * rowsPerFetch - rowsPerFetch * 0.2) && incrementFetch) {
      console.log('here');
      incrementFetch(pageParent + 1);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows!.length) : 0;
  const theme = useTheme();

  return (

    <Box sx={{ width: "100%" }}>

      <EnhancedTableToolbar
        onDeleteClick={onDeleteClick}
        onAddClick={onAddClick}
        title={title}
        setInitialPage={() => setPage(0)}
        selected={selected}
        numSelected={selected.length}
        fieldsToSearch={fieldsToSearch}
      />
      <Box
        component="main"
        sx={{ flexGrow: 1, pt: { sm: 2, lg: 2 } }}
      >
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer sx={{
            minHeight: "50vh",
            position: "relative"
          }}>
            {!isLoading && rows ? (
              <Table
               className="customTable"
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"small"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order as unknown as Order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.sort(getComparator(order, orderBy))
                    .map((row, index) => {
                      const isItemSelected = isSelected(
                        (row as any)[selectionKey]
                      );
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <Row
                          handleClick={handleClick}
                          labelId={labelId}
                          row={row}
                          key={index}
                          isItemSelected={isItemSelected}
                          selectionKey={selectionKey}
                          onSingleJobDelete={onSingleJobDelete}
                          onRestartJob={onRestartJob}
                        />
                      );
                    })}

                  {!rows.length && (
                    <TableRow>
                      <TableCell colSpan={headCells?.length} style={{ textAlign: "center", fontWeight: "bold" }}>
                        No record found!!
                      </TableCell>
                    </TableRow>
                  )}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 33 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            ) : (
              // make circular progress appear in center
              <Box sx={{ position: "absolute", inset: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CircularProgress size="3rem" />
              </Box>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 250, 500]}
            component="div"
            count={totalCountParent || rows?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box >
  );
};

export interface RowProps<t = any> {
  row: t;
  labelId: string;
  isItemSelected: boolean;
  handleClick: (event: React.MouseEvent<unknown>, name: string) => void;
  selectionKey: string;
  onSingleJobDelete?: any;
  onRestartJob?: any;
}

export default EnhancedCustomTable;



