import React from "react";
import { alpha } from "@mui/material/styles";
import {
  Grid,
  Paper,
  InputBase,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  AppBar,
  useTheme,
  Button,
  Box,
} from "@mui/material";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { Add } from "@mui/icons-material";
import useQuery from "lib/useQuery";
import { useNavigate } from "react-router-dom";
import { drawerWidth } from "../../constants";
import { LogoutUsers } from "services/user";

interface EnhancedTableToolbarProps {
  numSelected: number;
  selected: any[];
  title: string;
  onAddClick?: () => void;
  onDeleteClick: (ids: string[]) => void;
  fieldsToSearch?: any[];
  setInitialPage?: () => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, fieldsToSearch, setInitialPage } = props;
  const theme = useTheme();
  const query = useQuery();
  const searchQuery = query.get("search") || "";
  const fieldQuery = query.get("field") || "";
  const navigate = useNavigate();

  const [field, setField] = React.useState(
    fieldQuery || (fieldsToSearch?.[0] as string)?.toLowerCase() || ""
  );
  const [search, setSearch] = React.useState(searchQuery || "");
  const handleChange = (event: SelectChangeEvent) => {
    setField(event.target.value);
  };

  const handleSearchClick = () => {
    setInitialPage && setInitialPage();
    query.set("search", search);
    query.set("field", field);
    navigate({ search: query.toString() });
    // if (onSearch && setInitialPage) {
    //   setInitialPage();
    //   onSearch();
    // }
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSearchClick();
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        border: `1px solid ${theme.palette.grey[300]}`,
      }}
    >
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        <Grid
          container
          justifyContent={"space-between"}
          p={2}
          alignItems="center"
        >
          <Grid item md={9} display={"flex"} alignItems={"center"}>
            {numSelected > 0 ? (
              <Typography
                // sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
            ) : (
              <Typography
                // sx={{ flex: "1 1 100%" }}
                variant="h3"
                id="tableTitle"
                component="div"
              >
                {props.title}
              </Typography>
            )}
            {fieldsToSearch && !numSelected && (
              <Box display={"flex"} alignItems={"center"} ml={5}>
                <Paper
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    px: 2,
                    maxHeight: 50,
                    mr: 3,
                  }}
                >
                  <InputBase
                    sx={{ flex: 1 }}
                    placeholder="Search..."
                    inputProps={{ "aria-label": "search" }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <IconButton
                    onClick={handleSearchClick}
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
                <FormControl
                  sx={{ minWidth: 150, color: "white" }}
                  className="selectField"
                >
                  <InputLabel
                    sx={{ color: "white" }}
                    id="demo-simple-select-autowidth-label"
                  >
                    Field
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={field}
                    onChange={handleChange}
                    autoWidth
                    label="Age"
                    sx={{ color: "white" }}
                  >
                    {fieldsToSearch.map((field) => {
                      if (typeof field === "string") {
                        return (
                          <MenuItem key={field} value={field.toLowerCase()}>
                            {field}
                          </MenuItem>
                        );
                      } else {
                        return (
                          <MenuItem key={field.value} value={field.value}>
                            {field.label}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Grid>
          <Grid item md={3} container justifyContent={"flex-end"}>
            {numSelected > 0 ? (
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={() => props.onDeleteClick(props.selected)}
                  className="btnLogin"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <>
                {props.onAddClick && (
                  <Tooltip title="Add">
                    <IconButton onClick={props.onAddClick} className="btnLogin">
                      <Add />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            <Button
              variant="outlined"
              onClick={() => {
                LogoutUsers();
                navigate("/login");
              }}
              className="btnLogin"
              sx={{ ml: 2 }}
            >
              Logout
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default EnhancedTableToolbar;

// SELECT
//     DATE(created) AS Date,
//     COUNT(*) AS no_orders
// FROM raw

// GROUP BY
//     DATE(created)
