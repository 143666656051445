import React from "react";
import {
  Grid,
  Button,
  InputLabel,
  FormControl,
  OutlinedInput,
  CircularProgress,
  Box,
  FormHelperText,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { useAppContext } from "lib/contextLib";
import { FileUpload as FileUploadIcon } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { addToList } from "services/settings";
import { splitTextIntoArray } from "lib/splitTextIntoArray";

interface Props {
  onSuccess: () => void;
}
const CreateNewBlacklist = ({ onSuccess }: Props) => {
  const theme = useTheme();
  const [file, setFile] = React.useState<File>();
  const { setSnackbarState } = useAppContext();
  const { isError, isLoading, mutate } = useMutation(addToList, {
    onError: (error) => {
      console.log(error);
    },
    onSuccess: onSuccess,
  });

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      if (file.size / 1000000 > 5) {
        setSnackbarState({
          open: true,
          message: "File size should be less than 5MB",
          color: "error",
        });
      } else {
        setFile(file);
      }
    }
  };
  return (
    <Formik
      initialValues={{
        email: "",
        reason:"",
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Invalid email"),
        reason: Yup.string()
      })}
      onSubmit={(values, { setErrors, setStatus }) => {
        try {
          if (file) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
              const text = reader.result;
              if (text) {
                const emails = splitTextIntoArray(text.toString());
                const invalidEmails = emails.filter(
                  (email) => !Yup.string().email().isValidSync(email)
                );
                if (invalidEmails.length > 0) {
                  setSnackbarState({
                    open: true,
                    message: "Invalid emails found in file",
                    color: "error",
                  });
                  return;
                } else {
                  mutate({
                    emails,
                    reason:values.reason
                  });
                }
              }
            };
          } else if (values.email) {
            mutate({
              email: values.email,
              reason:values.reason
            });
          }
        } catch (e) {
          console.log(e);
          if (e instanceof Error) {
            console.log(e.message);
            setStatus({ success: false });
            setErrors({ submit: e.message });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={1}>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={12}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Email
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                >
                  <OutlinedInput
                    id="email"
                    type="text"
                    disabled={!!file}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                </FormControl>
                {touched.email && errors.email && (
                  <FormHelperText error>{errors.email}</FormHelperText>
                )}
              </Grid>
            </Grid>

            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                my: 2,
              }}
            >
              <Divider
                sx={{
                  flexGrow: 1,
                  opacity: "0.15",
                  borderColor: theme.palette.common.black,
                }}
                orientation="horizontal"
              />
              <Typography color={"grey.600"} marginLeft={2} marginRight={2}>
                OR
              </Typography>
              <Divider
                sx={{
                  flexGrow: 1,
                  opacity: "0.15",
                  borderColor: theme.palette.common.black,
                }}
                orientation="horizontal"
              />
            </Box>
            <Button
              component="label"
              color="info"
              variant="contained"
              startIcon={<FileUploadIcon />}
            >
              {file ? `(${file.name})` : "Import from File"}
              <input
                type="file"
                accept={".csv,.txt"}
                hidden
                onChange={handleFileInput}
              />
            </Button>
            <Grid item xs={12} md={12}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Reason
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(touched.reason && errors.reason)}
                >
                  <OutlinedInput
                    id="reason"
                    type="text"
                    value={values.reason}
                    name="reason"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                </FormControl>
              </Grid>
            
            {isError && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{"Failed to Add"}</FormHelperText>
              </Box>
            )}
            <Grid item container>
              <Button
                sx={{ mt: 2 }}
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress />
                ) : file ? (
                  "Upload"
                ) : (
                  "Add To Blacklist"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CreateNewBlacklist;
