import * as React from "react";
import { Grid, Grid2Props, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles"
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  GridProps?:Grid2Props
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, GridProps, ...others } = props;
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <div
      role="tabpanel"
      style={{width:'100%'}}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...others}
    >
      {value === index && (
        <Grid container p={!matchesSM?2:undefined} item {...GridProps}>
          {children}
        </Grid>
      )}
    </div>
  );
}
