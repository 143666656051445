import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import {
    fetchListAction,
} from "../actions/list.action";

export type List = {
    cost_per_lead?: string,
    created?: string,
    id?: number,
    list_name?: string,
    notes?: number | null,
    profiles_count: number,
    raw_count?: number,
    sales_rep?: number | string | null,
    stats_blacklist: number,
    stats_dblock: number,
    stats_dupes?: number | null,
    stats_rblock: number
    stats_tldblock: number,
    status?: string,
    type?: string,
    vendor?: string,
    vertical?: string,
};

export type ListState = {
    list?: List[];
    isLoading: boolean;
    error: boolean;
    message: string;
    isToast: boolean;
};

export const listSlice = createSlice<ListState, SliceCaseReducers<ListState>, string>({
    name: "list",
    initialState: {
        list: [],
        isLoading: false,
        error: false,
        message: '',
        isToast: false,
    },
    reducers: {
        setIsToast(state, { payload }: { payload: { isToast: boolean; message?: string } }) {
            state.isToast = payload.isToast;
        },
    },
    extraReducers: (builder) => {
        // fetchListAction
        builder.addCase(fetchListAction.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchListAction.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.list = payload;
        });
        builder.addCase(fetchListAction.rejected, (state) => {
            state.isLoading = false;
            state.isToast = true;
            state.error = true;
        });
    },
});

export const { setIsToast } = listSlice.actions;

export default listSlice.reducer;
