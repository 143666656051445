import React from "react";
import {
  Button,
  InputLabel,
  FormControl,
  TextField,
  Grid,
  CircularProgress,
  Box,
  FormHelperText,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { editVendor } from 'services/scrubs';

interface Props {
  isEditing: boolean;
  vendor: { id: string; name: string; valid_reason: string; cost: number };
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccessfulUpdate: () => void;
}
const EditVendorForm = ({ isEditing, vendor, setIsEditing, onSuccessfulUpdate }: Props) => {
  const { mutate, isLoading, isError, error } = useMutation(
    editVendor,
    {
      onSuccess: () => {
        onSuccessfulUpdate();
      }

    }
  );

  const handleCancel = (setValues: any, initialValues: any) => {
    setIsEditing(false);
    setValues(initialValues);
  };
  return (
    <Formik
      initialValues={{
        name: vendor?.name,
        cost: vendor?.cost,
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Name is required"),
        cost: Yup.number(),
      })}
      onSubmit={(values, { setErrors, setStatus }) => {
        try {
          mutate({ id: vendor.id, ...values });
        } catch (e) {
          console.log(e);
          if (e instanceof Error) {
            console.log(e.message);
            setStatus({ success: false });
            setErrors({ submit: e.message });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setValues,
        initialValues,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={1}>
            <Grid item container>
              <InputLabel htmlFor="outlined-adornment-email-login">
                Name
              </InputLabel>
              <FormControl
                fullWidth
                error={Boolean(touched.name && errors.name)}
              >
                <TextField
                  id="name"
                  disabled={!isEditing}
                  type="text"
                  value={values.name}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={12}>
                <InputLabel sx={{ color: "grey.600" }}>Cost</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    id="cost"
                    disabled={!isEditing}
                    type="number"
                    value={values.cost}
                    name="cost"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>

          </Grid>

          {isError && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{"Failed to Update Vendor"}</FormHelperText>
            </Box>
          )}
          <Grid item container spacing={2}>
            {isEditing ? (
              <>
                <Grid item xs={12} md={6}>
                  <Button
                    sx={{ mt: 2 }}
                    disableElevation
                    fullWidth
                    size="large"
                    onClick={() => handleCancel(setValues, initialValues)}
                    variant="contained"
                    color="warning"
                    disabled={isLoading}
                  >
                    {"Cancel"}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    sx={{ mt: 2 }}
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    color="info"
                    variant="contained"
                    disabled={isLoading}
                  >
                    {isLoading ? <CircularProgress /> : "Update"}
                  </Button>
                </Grid>
              </>
            ) : (
              <Button
                sx={{ mt: 3 }}
                disableElevation
                fullWidth
                size="large"
                variant="contained"
                onClick={() => setIsEditing(true)}
                color="primary"
              >
                {"Edit Vendor"}
              </Button>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default EditVendorForm;
