import React from 'react'
import SignUpForm from 'components/auth/SignUpForm'

import { Grid } from '@mui/material'
const Register = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" style={{height:'100vh'}}
    >
        <Grid item xs={12} sm={6} md={4} >
            <SignUpForm />
        </Grid>
    </Grid>
  )
}

export default Register