import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { ScrubState } from "store/reducers/scrubs.reducer";
import { fetchScrubAction, fetchScrubVendors } from "store/actions/scrubs.action";

interface ScrubParams {
    page: number,
    limit: number,
    search?: string,
    field?: string
}

const useScrub = () => {
    const { scrubs, scrubVendors, isToast, message, error, isLoading } = useSelector<RootState, ScrubState>((state: any) => state.scrub);
    const dispatch = useDispatch();

    const getScrubs = (params: ScrubParams) => {
        return dispatch<any>(fetchScrubAction(params));
    };

    const getScrubVendors = (params: ScrubParams) => {
        return dispatch<any>(fetchScrubVendors(params));
    };

    return {
        scrubs,
        scrubVendors,
        getScrubs,
        getScrubVendors,
        isToast, message, error, isLoading
    };
};

export default useScrub;