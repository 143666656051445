import React from "react";
import Form2Fa from "components/auth/Form2Fa";
import { Grid } from "@mui/material";

const Fa2Auth = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Grid item xs={12} sm={6} md={4}>
        <Form2Fa />
      </Grid>
    </Grid>
  );
};

export default Fa2Auth;
