import React from "react";
import { useGetJob } from "services/jobs";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import ErrorAlert from "components/ErrorAlert";
import Loading from "components/ui/Loading";
import { useAppContext } from "lib/contextLib";
import EditJob from "components/forms/EditJobForm";
import useJobs from "hooks/useJobs";
import { rowsPerFetch } from "../constants";

const Job = () => {
  const { id } = useParams();
  const { getAllJobs } = useJobs();
  const [isEditing, setIsEditing] = React.useState(false);
  const { data, isLoading, isError, refetch } = useGetJob(id!);
  const { setSnackbarState } = useAppContext();
  const onSuccessfulUpdate = () => {
    setIsEditing(false);
    setSnackbarState({
      open: true,
      message: "Job updated successfully",
      color: "success",
    });
    getAllJobs({ page: 0, limit: rowsPerFetch });
    refetch();
  };

  return (
    <Grid container direction="column">
      {isError && <ErrorAlert />}
      {!data && isLoading && !isError ? (
        <Loading />
      ) : (

        <Grid item container direction="column">
          <Grid item>
            <Typography variant="h3" align="center"> {data && data.job && data.job.name} </Typography>
          </Grid>
          <Grid item container justifyContent={'space-between'} spacing={2}>
            <Grid item xs={12} md={12}>
              {data && (
                // <MainCard title="Job Information" sx={{color:'grey'}}>
                <EditJob
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  job={data.job}
                  onSuccess={onSuccessfulUpdate}
                />
                // </MainCard>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Job;
