import React from "react";
import {
  Grid,
  Button,
  InputLabel,
  FormControl,
  OutlinedInput,
  CircularProgress,
  Box,
  FormHelperText,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import {  addToTLDs } from "services/settings";
interface Props {
  onSuccess: () => void;
}
const CreateNewTLD = ({ onSuccess }: Props) => {
  const { isError, isLoading, mutate } = useMutation(
    addToTLDs,
    {
      onError: (error) => {
        console.log(error);
      },
      onSuccess: onSuccess,
    }
  );

  return (
    <Formik
      initialValues={{
       tld:"",
       submit:""
      }}
      validationSchema={Yup.object().shape({
       tld: Yup.string().required("Required"),
      })}
      onSubmit={(values, { setErrors, setStatus }) => {
        try {
           mutate(values.tld)
        } catch (e) {
          console.log(e);
          if (e instanceof Error) {
            console.log(e.message);
            setStatus({ success: false });
            setErrors({ submit: e.message });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={1}>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={12}>
                <InputLabel htmlFor="outlined-adornment-tld-login">
                  TLD
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.tld && errors.tld
                  )}
                >
                  <OutlinedInput
                    id="tld"
                    type="text"
                    name="tld"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                </FormControl>
              </Grid>
            </Grid>
            
           

          {isError && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {"Failed to Add"}
              </FormHelperText>
            </Box>
          )}
          <Grid item container>
            <Button
              sx={{ mt: 2 }}
              disableElevation
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress /> : "Add Allowed TLD"}
            </Button>
          </Grid>
        </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CreateNewTLD;
