import axiosService from "lib/axios";
import { useQuery } from "@tanstack/react-query";

export interface Scrub {
  id: string;
  email: string;
  vendor_id: string | number;
  code: string;
  reason: string;
}
// export async function getScrubs(
//   page: number,
//   limit: number,
//   search?: string,
//   field?: string
// ): Promise<{
//   scrubs: Scrub[];
//   page: number;
//   limit: number;
//   totalCount: string;
// }> {
//   const { data } = await axiosService.get("/scrubs", {
//     params: { page, limit, search, field },
//   });
//   if (data.count) {
//     window.localStorage.setItem("totalCountScrubs", data.count);
//   }
//   return {
//     scrubs: data.scrubs,
//     page: page,
//     limit: limit,
//     totalCount: data.count || window.localStorage.getItem("totalCountScrubs"),
//   };
// }

export async function getScrubVendors(
  page: number,
  limit: number,
  search?: string,
  field?: string
) {
  const { data } = await axiosService.get("scrubs/vendors", {
    params: { page, limit, search, field },
  });
  if (data.count) {
    window.localStorage.setItem("totalCountVendors", data.count);
  }
  return {
    vendors: data.vendors,
    page: data.page,
    limit: data.limit,
    totalCount: data.count || window.localStorage.getItem("totalCountVendors"),
  };
}

export async function deleteScrub(ids: string[]) {
  await axiosService.delete(`scrubs`, {
    data: {
      ids,
    },
  });
}

export async function deleteVendor(ids: string[]) {
  await axiosService.delete(`scrubs/vendors`, {
    data: {
      ids,
    },
  });
}



export async function createNewVendor(
  name: string,
  valid_reason?: string,
  cost?: number
) {
  const { data } = await axiosService.post("scrubs/vendors", {
    name,
    valid_reason,
    cost,
  });
  return data;
}


async function getVendor(id: string) {
  const { data } = await axiosService.get(`scrubs/vendors/${id}`);
  return data;
}

export function useGetVendor(id: string) {
  return useQuery(["vendor", id], () => getVendor(id));
}

export async function editVendor({
  id,
  name,
  valid_reason,
  cost,
}: {
  id: string;
  name: string;
  valid_reason?: string;
  cost?: number;
}) {
  return axiosService.put(`scrubs/vendors/${id}`, {
    name,
    valid_reason,
    cost,
  });
}