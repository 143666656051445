import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { SettingState } from "store/reducers/setting.reducer";
import { fetchAllUsersAction, fetchAllowedTldsAction, fetchBlackListsAction, fetchBlockedDomainsAction, fetchBlockedRolesAction } from "store/actions/setting.action";

interface SettingParams {
    page: number,
    limit: number,
    search?: string,
    field?: string
}

const useSetting = () => {
    const dispatch = useDispatch();
    const { blacklists, blockedDomains, blockedRoles, allowedTLDs, users, isToast, message, error, isLoading } = useSelector<RootState, SettingState>((state: any) => state.setting);

    const getBlackLists = (params: SettingParams) => {
        return dispatch<any>(fetchBlackListsAction(params));
    };

    const getBlockedDomains = (params: SettingParams) => {
        return dispatch<any>(fetchBlockedDomainsAction(params));
    };

    const getBlockedRoles = (params: SettingParams) => {
        return dispatch<any>(fetchBlockedRolesAction(params));
    };


    const getAllowedTlds = (params: SettingParams) => {
        return dispatch<any>(fetchAllowedTldsAction(params));
    };

    const getAllUsers = (params: SettingParams) => {
        return dispatch<any>(fetchAllUsersAction(params));
    }

    return {
        blacklists,
        getBlackLists,
        blockedDomains,
        blockedRoles,
        allowedTLDs,
        users,
        getBlockedDomains,
        getBlockedRoles,
        getAllowedTlds,
        getAllUsers,
        isToast, message, error, isLoading
    };
};

export default useSetting;
