import React from "react";
import { BrowserRouter } from 'react-router-dom'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { AppContextProvider } from "lib/contextLib";
import ThemeRoutes from 'routes';
import "./index.css"
import Snackbar from "components/ui/Snackbar";
import { Provider as StoreProvider } from "react-redux";
import store from "store";

const queryClient = new QueryClient()
export default function App() {

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <StoreProvider store={store}>
          <AppContextProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Snackbar />
              <ThemeRoutes />
            </LocalizationProvider>
          </AppContextProvider>
        </StoreProvider>
      </QueryClientProvider>
    </BrowserRouter >
  );
}
