export function splitTextIntoArray(text:string){
    const textAsStr = text.toString();
    // find which character is used to separate the emails
    const separator = textAsStr.includes(",")
      ? ","
      : textAsStr.includes(";")
      ? ";"
      : textAsStr.includes(" ")
      ? " "
      : "\n";
    const emails = textAsStr
      .split(separator)
      .map((email) => email.trim());
    // check if all emails are valid

    return emails;
}