import React from 'react'
import { Toolbar,IconButton,Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
interface Props {
    handleDrawerToggle:()=>void
}
const ToolBar = ({handleDrawerToggle}:Props) => {
  return (
    <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon sx={{color:'grey.500'}} />
          </IconButton>
        </Toolbar>
  )
}

export default ToolBar