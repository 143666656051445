import React, { useContext, createContext } from "react";
import { AlertColor } from "@mui/material/Alert";

interface IAuthenticateContext {
  isAuthenticated: boolean;
  user:any;
  setUser: React.Dispatch<React.SetStateAction<any>>;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackbarClose: () => void;
  snackbarState: SnackbarState;
  setSnackbarState: React.Dispatch<React.SetStateAction<SnackbarState>>;

}
interface SnackbarState {
  open: boolean;
  message: string;
  color: AlertColor;
}
export const AppContext = createContext<undefined | IAuthenticateContext>(
  undefined
);
interface ProviderProps {
  children: React.ReactNode;
}
export function AppContextProvider({ children }: ProviderProps) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [user, setUser] = React.useState<any>(JSON.parse(window.localStorage.getItem("user") || "{}"));
  const [snackbarState, setSnackbarState] = React.useState<SnackbarState>({
    open: false,
    message: "",
    color: "success",
  });

  React.useEffect(() => {
    const token = window.localStorage.getItem("token");
    const authenticated = window.localStorage.getItem("authenticated");
    if (token && authenticated) {
      setIsAuthenticated(true);
    }
  },[])

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };
  
  const value = React.useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
      handleSnackbarClose,
      snackbarState,
      setSnackbarState,
      user,
      setUser
    }),
    [isAuthenticated, snackbarState,user]
  );
  return (
    <AppContext.Provider value={value}>
      {" "}
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const appContextValue = useContext(AppContext);
  if (appContextValue == undefined) {
    throw new Error("Expected context value to be set!");
  }
  return appContextValue;
}

