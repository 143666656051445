import {
  Grid,
  TableCell,
  TableRow,
  Tooltip,
  Chip,
  Button,
  useTheme

} from "@mui/material";
import React from "react";
import { getProfiles } from "services/profiles";
import EnhancedCustomTable from "components/EnhancedCustomTable";
import { useNavigate } from "react-router-dom";
import { RowProps } from "components/EnhancedCustomTable";
import useQuery from "lib/useQuery";
import { Profile } from "types";
import { rowsPerFetch } from "../constants";
import useProfile from "hooks/useProfile";
const profileHeadCells = [
  { id: "email", numeric: false, disablePadding: false, label: "Email", align: 'left' },
  {
    id: "first",
    numeric: false,
    disablePadding: false,
    label: "First Name",
    align: 'left'
  },
  {
    id: "last",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
    align: 'left'
  },
  { id: "phone", numeric: false, disablePadding: false, label: "Phone", align: 'left' },
  { id: "country", numeric: false, disablePadding: false, label: "Country", align: 'left' },
  { id: "status", numeric: false, disablePadding: false, label: "Status", align: 'left' },
  { id: "actions", numeric: true, disablePadding: false, label: "Actions", align: "right" },
];

const Profiles = () => {
  const { profile: allProfiles, getAllProfiles } = useProfile();
  const [activePage, setActivePage] = React.useState(0);

  const query = useQuery();
  const incrementPage = (value: number) => {
    setActivePage(value);
  };

  const search = query.get("search") || undefined;
  const field = query.get("field") || undefined;
  const blockReason = query.get("block_reason") || undefined;
  const listId = query.get("list_id") || undefined;

  React.useEffect(() => {
    if (search || field) {
      setActivePage(0)
      handleSearch({ page: 0 });
    }
  }, [search, field])

  React.useEffect(() => {
    if ((activePage !== 0 && activePage > (allProfiles?.lodedPageData as any) && allProfiles?.profile?.length !== allProfiles?.total) ||
      (!allProfiles?.profile?.length || allProfiles?.isFiltered)) {
      handleSearch()
    }
  }, [activePage]);

  const handleSearch = ({ ...query } = {}) => {
    setActivePage(0);
    getAllProfiles({
      search, field, page: activePage, limit: rowsPerFetch, ...((!search || !field) ? { blockReason } : {}), ...((!search || !field) ? { listId } : {}), ...query
    })
  };

  return (
    // <Grid container>
    //     <AppBar
    //     position="fixed"

    //     sx={{
    //       width: { sm: `calc(100% - ${drawerWidth}px)` },
    //       ml: { sm: `${drawerWidth}px` },
    //       border: `1px solid ${theme.palette.grey[300]}`
    //     }}
    //   >
    //     <ToolBar handleDrawerToggle={handleDrawerToggle}/>
    //   </AppBar>

    //   <Box
    //     component="main"
    //     sx={{ flexGrow: 1, p: {sm:3, lg:4}, pt:{sm:2, lg:2}, width: { sm: `calc(100% - ${drawerWidth}px)`,...theme.mainContent } }}
    //   > 
    <Grid container>
      <EnhancedCustomTable
        rows={allProfiles?.profile}
        isLoading={allProfiles?.isLoading ?? false}
        title="Profiles"
        selectionKey="id"
        headCells={profileHeadCells}
        onDeleteClick={() => {
          console.log("delete");
        }}
        Row={ProfileRow}
        incrementFetch={incrementPage}
        pageParent={activePage}
        totalCountParent={allProfiles?.total}
        fieldsToSearch={[
          "Email",
          "Last",
        ]}
      />
    </Grid>
    // </Box>
    // </Grid>
  );
};

function ProfileRow({
  handleClick,
  row,
  selectionKey,
  isItemSelected,
  labelId,
}: RowProps<Profile>) {
  const navigate = useNavigate();
  const handleProfileView = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    e.stopPropagation();
    navigate(`/profiles/${id}`);
  };

  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, (row as any)[selectionKey])}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell component="th" id={labelId} scope="row" align="left">
        {row.email}
      </TableCell>
      <TableCell align="left">{row.first}</TableCell>
      <TableCell align="left">{row.last}</TableCell>
      <TableCell align="left">{row.phone}</TableCell>
      <TableCell align="left">{row.country}</TableCell>
      <TableCell align="left">
        <Chip
          sx={{ color: "white", fontWeight: "600" }}
          label={row.status}
          size="small"
          color={row.status === "ACTIVE" ? "success" : "error"}
        />
      </TableCell>
      <TableCell align="right">
        <Tooltip title="View Profile" placement="left-start">
          <Button
            onClick={(e) => handleProfileView(e, row.id)}
            variant="contained"
            aria-label="delete"
          >
            View
          </Button>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

export default Profiles;
