import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import {
    fetchListAction,
} from "../actions/list.action";
import { fetchAuditAction, fetchBreedAction, fetchRawsAction } from "store/actions/table.action";

export type Raw = {
    address_1: null | string;
    address_2: string;
    city: string;
    country: string;
    created: string;
    dob: null | string;
    email: string;
    ethnicity: null | string;
    extra_1: null | string;
    extra_2: null | string;
    extra_3: null | string;
    extra_4: null | string;
    first: null | string;
    gender: null | string;
    id: number;
    last: null | string;
    list_id: number;
    phone: null | number
    state: string,
    upload_id: number
    zip: string
};

export type audit = {
    address_1: null | string,
    address_2: null | string,
    city: string,
    country: string,
    created: string,
    dob: null | string,
    email: string,
    ethnicity: null | string,
    extra_1: null | string,
    extra_2: null | string,
    extra_3: null | string,
    extra_4: null | string,
    first: null | string,
    gender: null | string,
    id: number,
    last: null | string,
    list_id: number,
    phone: null | number,
    state: string,
    upload_id: number
    zip: string
}

export type breed = {
    created: string,
    id: number,
    name: string,
    type: string
}

export type TableState = {
    raws?: {
        total?: number;
        isFiltered?: boolean;
        raws?: Raw[];
        isLoading?: boolean;
        error?: boolean;
        lodedPageData?: number;
    };
    audit?: {
        total?: number;
        isFiltered?: boolean;
        audit?: audit[];
        isLoading?: boolean;
        error?: boolean;
        lodedPageData?: number;
    };
    breed?: {
        total?: number;
        isFiltered?: boolean;
        breed?: breed[];
        isLoading?: boolean;
        error?: boolean;
        lodedPageData?: number;
    };
    isLoading: boolean;
    error: boolean;
    message: string;
    isToast: boolean;
};

export const tableSlice = createSlice<TableState, SliceCaseReducers<TableState>, string>({
    name: "table",
    initialState: {
        raws: {
            total: parseInt(localStorage.getItem('totalCountRaw') ?? '0') || 0,
            isFiltered: false,
            raws: [],
            isLoading: true,
            error: false,
            lodedPageData: 0
        },
        audit: {
            total: parseInt(localStorage.getItem('totalAuditCount') ?? '0') || 0,
            isFiltered: false,
            audit: [],
            isLoading: true,
            error: false,
            lodedPageData: 0
        },
        breed: {
            total: parseInt(localStorage.getItem('totalBreedCount') ?? '0') || 0,
            isFiltered: false,
            breed: [],
            isLoading: true,
            error: false,
            lodedPageData: 0

        },
        isLoading: false,
        error: false,
        message: '',
        isToast: false,
    },
    reducers: {
        setIsToast(state, { payload }: { payload: { isToast: boolean; message?: string } }) {
            state.isToast = payload.isToast;
        },
    },
    extraReducers: (builder) => {
        // Table Raw
        builder.addCase(fetchRawsAction.pending, (state) => {
            state.raws = {
                ...state.raws,
                isFiltered: false,
                isLoading: true
            };
        });
        builder.addCase(fetchRawsAction.fulfilled, (state, { payload }: any) => {
            const { arg } = payload;

            state.raws = {
                isLoading: false,
                isFiltered: arg.search && arg.field ? true : false,
                total: parseInt(payload.count ?? state.raws?.total ?? 0),
                raws: arg.page !== 0 ? [...state.raws?.raws as any, ...payload.raw] : payload.raw,
                lodedPageData: arg.page
            }
        });
        builder.addCase(fetchRawsAction.rejected, (state) => {
            state.raws = {
                ...state.raws,
                isFiltered: false,
                isLoading: false
            };
        });

        // Table Audit
        builder.addCase(fetchAuditAction.pending, (state) => {
            state.audit = {
                ...state.audit,
                isFiltered: false,
                isLoading: true
            };
        });
        builder.addCase(fetchAuditAction.fulfilled, (state, { payload }: any) => {
            const { arg } = payload;

            state.audit = {
                isLoading: false,
                isFiltered: arg.search && arg.field ? true : false,
                total: parseInt(payload.count ?? state.audit?.total ?? 0),
                audit: arg.page !== 0 ? [...state.audit?.audit as any, ...payload.auditLogs] : payload.auditLogs,
                lodedPageData: arg.page
            }
        });
        builder.addCase(fetchAuditAction.rejected, (state) => {
            state.audit = {
                ...state.audit,
                isFiltered: false,
                isLoading: false
            };
        });

        // Table Breed
        builder.addCase(fetchBreedAction.pending, (state) => {
            state.breed = {
                ...state.breed,
                isFiltered: false,
                isLoading: true
            };
        });
        builder.addCase(fetchBreedAction.fulfilled, (state, { payload }: any) => {
            const { arg } = payload;

            state.breed = {
                isLoading: false,
                isFiltered: arg.search && arg.field ? true : false,
                total: parseInt(payload.count ?? state.breed?.total ?? 0),
                breed: arg.page !== 0 ? [...state.breed?.breed as any, ...payload.breeds] : payload.breeds,
                lodedPageData: arg.page
            }
        });
        builder.addCase(fetchBreedAction.rejected, (state) => {
            state.breed = {
                ...state.breed,
                isFiltered: false,
                isLoading: false
            };
        });
    },
});

export const { setIsToast } = tableSlice.actions;

export default tableSlice.reducer;
