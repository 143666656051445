import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import axiosService from "lib/axios";

const route = '/users';
export async function getUsers(page: number, limit: number, search?: string, field?: string) {
    const { data } = await axiosService.get(route, {
        params: { page, limit, search, field },
    });
    if (data.count) {
        window.localStorage.setItem('totalCountUsers', data.count);
    }
    return {
        users: data.users,
        page: data.page,
        limit: data.limit,
        totalCount: data.count || window.localStorage.getItem('totalCountUsers'),
    };
}

export async function getUser(id: string) {
    const { data } = await axiosService.get(`${route}/${id}`);
    return data;
}

export function useGetUser(id: string) {
    return useQuery(['user', id], () => getUser(id));
}

export function updateUser({ id, username, isAdmin, password }: any) {
    return axiosService.put(`${route}/${id}`, { username, isAdmin, password });
}

export function useGetUsers() {
    return useQuery(["users"], () => getUsers);
}

export function createUser(user: { username: string, password: string, isAdmin?: boolean }) {
    return axiosService.post(route, { username: user.username, password: user.password, isAdmin: user.isAdmin });
}

export function login(user: { username: string, password: string }) {
    return axiosService.post(route + '/login', { username: user.username, password: user.password });
}

export function generatefa(user_id: any) {
    return axiosService.get(`/generatefa/${user_id}`);
}

export function check2faauth(code: string) {
    const userData: any = localStorage.getItem("user");
    const user: any = JSON.parse(userData);
    return axiosService.post('/verifyfa', { code, tokenSecret: user.authsecret });
}

export function deleteUsers(ids: string[]) {
    return axiosService.delete(route, { data: { ids } });
}

export function LogoutUsers() {
    localStorage.clear();
}