import React, { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
// third party
import { QRCodeSVG } from 'qrcode.react';
import * as Yup from "yup";
import { Avatar, Typography } from "@mui/material";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useMutation } from "@tanstack/react-query";
import { generatefa, check2faauth } from "services/user";
import { Formik } from "formik";
import { useAppContext } from "lib/contextLib";
import PasswordIcon from '@mui/icons-material/Password';
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Form2Fa = () => {

  const { setIsAuthenticated, isAuthenticated, setSnackbarState } = useAppContext();
  const [ qrLink, setQrLink ] = useState("");
  // const [ isGenerateLoading, setIsGenerateLoading ] = useState(false);
  
  const navigate = useNavigate();

  const { isError, error, isLoading, mutateAsync } = useMutation(check2faauth, {
    onSuccess: ({ data }) => {
      if(data.verified){
        setSnackbarState({
          open: true,
          message: "User Verified Successfully",
          color: "success",
        });
        setIsAuthenticated(true);
        localStorage.setItem("authenticated", data.verified);
        navigate("/");
      }else{
        setSnackbarState({
          open: true,
          message: "Invalid Auth Code",
          color: "error",
        });
      }
    },
    onError: (error) => {
      setSnackbarState({
        open: true,
        message: "Invalid Auth Code",
        color: "error",
      });
    },
  });

  useEffect(() => {
    if (window.localStorage.getItem('token') && window.localStorage.getItem('authenticated')) {
      setSnackbarState({
        open: true,
        message: "Already Logged In",
        color: "success",
      });  
      navigate('/', { replace: true });
    }
}, []);

  const { isError: isGenerateError, error: generateError, isLoading: isGenerateLoading, mutateAsync: mutateAsyncGenerate } = useMutation(generatefa, {
    onSuccess: ({ data }) => {
      const userData: any = localStorage.getItem("user");
      let user: any = JSON.parse(userData);
      user = {
        ...user,
        qrurl: data.qrurl,
        authsecret: data.authsecret
      }
      console.log('user: ', user);
      localStorage.setItem("user", JSON.stringify(user));
      setQrLink(data.data?.otpauth_url);
      // setIsGenerateLoading(false);
    },
    onError: (error) => {
      setSnackbarState({
        open: true,
        message: "Erro generating code",
        color: "error",
      });
    },
  });

  // const mutateAsyncGenerate = async(user_id: any) => {
    // const userData: any = localStorage.getItem("user");
    // let user: any = JSON.parse(userData);
  //   await axios.get(`http://localhost:4000/generatefa/${user_id}`).then((data: any) => {
      // user = {
      //   ...user,
      //   qrurl: data.qrurl,
      //   authsecret: data.authsecret
      // }
      // console.log('user: ', user);
      // localStorage.setItem("user", JSON.stringify(user));
      // setQrLink(data.data?.otpauth_url);
  //   }).catch((e: any) => {
  //     console.log(e);
  //   }).finally(() => {
  //     setIsGenerateLoading(false);
  //   })
  // }

  useEffect(() => {
    const userData: any = localStorage.getItem("user");
    const user: any = JSON.parse(userData);
    if(user && user.authsecret && user.authsecret !== '' && user.qrurl && user.qrurl){
      setQrLink(user.qrurl);
    }
    else{
      // setIsGenerateLoading(true);
      mutateAsyncGenerate(user?.id);
    }
  }, [])

  
  const theme = useTheme();

  return (
    <>
      {
        isGenerateLoading ?
          <Typography 
            color={theme.palette.common.white}
            variant={"h3"}
          >
            Generating 2FA Code...
          </Typography>
        :
        <>
          <Formik
            initialValues={{
              code: ""
            }}
            validationSchema={Yup.object()
              .shape({
                code: Yup.string()
                  .length(6, 'OTP must be exactly 6 digits')
                  .required("code is required"),
              })
            }
            onSubmit={(
              { code },
              { setSubmitting, setErrors, setStatus }
            ) => {
              mutateAsync(code);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container direction="column">
                  <Grid
                    item
                    container
                    justifyContent={"center"}
                    alignItems="center"
                    direction="column"
                  >
                    <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                      <QrCodeScannerIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      2FA Authentication
                    </Typography>
                  </Grid>
                  <Grid
                    marginTop={"15px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems="center"
                    >
                    <QRCodeSVG value={`${qrLink && qrLink !== '' ? qrLink : 'https://reactjs.org/' }`} />
                  </Grid>
                  <Grid item container direction="column">
                    <Grid item>
                      <InputLabel
                        htmlFor="outlined-adornment-email-login"
                        sx={{ color: "grey.600" }}
                      >
                        Code
                      </InputLabel>
                    </Grid>
                    <Grid item>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.code && errors.code)}
                        sx={{ ...theme.customInput }}
                      >
                        <OutlinedInput
                          startAdornment={
                            <InputAdornment position="start">
                              <PasswordIcon sx={{ color: "grey.500" }} />
                            </InputAdornment>
                          }
                          id="outlined-adornment-email-login"
                          type="text"
                          value={values.code}
                          name="code"
                          onBlur={handleBlur}
                          autoComplete="code"
                          onChange={handleChange}
                          inputProps={{}}
                        />
                      </FormControl>
                      {touched.code && errors.code && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-email-login"
                        >
                          {errors.code}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  {isError && (
                    <FormHelperText error>
                      {(error as any)?.response?.data?.error ||
                        "Something went wrong"}
                    </FormHelperText>
                  )}
                  <Grid item mt={2} container>
                    <Button
                      disableElevation
                      disabled={isLoading}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {isLoading ? <CircularProgress /> : "Submit"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </>
      }
    </>
  );
};

export default Form2Fa;
