import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGetVendor } from 'services/scrubs';
import Loading from 'components/ui/Loading';
import { useAppContext } from 'lib/contextLib';
import EditVendorForm from 'components/forms/EditVendor';
// import { ScrubVendor } from 'store/reducers/scrubs.reducer';
import useScrub from 'hooks/useScrub';
import { rowsPerFetch } from '../constants';

const ScrubVendors = () => {
  const { id } = useParams();
  const { getScrubVendors, scrubVendors } = useScrub();
  const [vendor, setVendor] = React.useState<any>({});
  // const { data, isLoading, isError: isErrorLoading, refetch } = useGetVendor(id!);
  const { setSnackbarState } = useAppContext();
  const [isEditing, setIsEditing] = React.useState(false);

  useEffect(() => {
    if (!scrubVendors.scrubVendors?.length) {
      getScrubVendors({ page: 0, limit: rowsPerFetch });
    }
    setVendor(scrubVendors.scrubVendors?.find((vendor) => vendor.id == parseInt(id as any)))
  }, [scrubVendors.scrubVendors?.length])

  const handleSuccess = () => {
    setIsEditing(false);
    getScrubVendors({ page: 0, limit: rowsPerFetch });
    setSnackbarState({ open: true, message: "Vendor updated successfully", color: "success" });
    // refetch();
  };

  return (
    <>
      {(vendor && Object.keys(vendor).length) ? <EditVendorForm onSuccessfulUpdate={handleSuccess}
        isEditing={isEditing} vendor={vendor} setIsEditing={setIsEditing} />
        : <Loading />}
    </>
  )
}

export default ScrubVendors