import React from 'react'
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useAppContext } from 'lib/contextLib';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { Grid, Button, Box, FormHelperText, FormControl, OutlinedInput, InputLabel, CircularProgress } from '@mui/material';

// make this a reusable component
interface Props {
    isEditing: boolean;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
    mutation:(t:any)=>any;
    initialValues:any;
    validationSchema:any;
    label:string;
    name:string;
    refetch:()=>any;
    children?:React.ReactNode;
}
const EditSettings = ({isEditing, setIsEditing,mutation,initialValues,validationSchema,label,name,refetch,children
}:Props) => {
    const { id } = useParams();
    const { setSnackbarState } = useAppContext();
    const { mutate, isLoading:isUpdating, isError } = useMutation(mutation, {
        onSuccess:()=>{
           setSnackbarState({open:true, message:'Updated successfully', color:'success'});
           setIsEditing(false);
           refetch();
        }
      });

    const handleCancel = (setValues:any, initialValues:any) => {
        setValues(initialValues);
        setIsEditing(false);
    }

  return (
 <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape(validationSchema)}
      onSubmit={(values, { setErrors, setStatus }) => {
        try {
          
            mutate({id:id, ...values});
        } catch (e) {
          console.log(e);
          if (e instanceof Error) {
            console.log(e.message);
            setStatus({ success: false });
            setErrors({ submit: e.message });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setValues,
        initialValues,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={1}>
            {children? children : <Grid item container spacing={1}>
              <Grid item xs={12} md={12}>
                <InputLabel htmlFor="outlined-adornment-role-login">
                    {label}
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(
                    touched[name] && errors[name]
                  )}
                >
                  <OutlinedInput
                    id={name}
                    type="text"
                    name={name}
                    disabled={!isEditing}
                    value={values[name]}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                </FormControl>
              </Grid>
            </Grid> }
           
         

          {isError && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {"Failed to update..."}
              </FormHelperText>
            </Box>
          )}
               <Grid item container spacing={2} >
            {isEditing ? (
              <>
                <Grid item xs={12} md={6} >
                  <Button
                    sx={{ mt: 2 }}
                    disableElevation
                    fullWidth
                    size="large"
                    onClick={() => handleCancel(setValues, initialValues)}
                    variant="contained"
                    color="warning"
                    disabled={isUpdating}
                  >
                    {"Cancel"}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    sx={{ mt: 2 }}
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    color="info"
                    variant="contained"
                    disabled={isUpdating}
                  >
                    {isUpdating ? <CircularProgress /> : "Update"}
                  </Button>
                </Grid>
              </>
            ) : (
              <Button
                sx={{ mt: 3 }}
                disableElevation
                fullWidth
                size="large"
                variant="contained"
                onClick={() => setIsEditing(true)}
                color="primary"
              >
                {`Edit ${label}`}
              </Button>
            )}
          </Grid>
        </Grid>
        </form>
      )}
    </Formik>
    
  )
}

export default EditSettings