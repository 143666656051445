import React from "react";
import { Grid, TableRow, TableCell, Button } from "@mui/material";
import EnhancedCustomTable, { RowProps } from "components/EnhancedCustomTable";
import { deleteBlackLists } from "services/settings";
import DialogComponent from "components/ui/Dialog";
import CreateNewBlacklist from "components/forms/CreateNewBlacklist";
import { useAppContext } from "lib/contextLib";
import { useMutation } from "@tanstack/react-query";
import ErrorAlert from "components/ErrorAlert";
import useQuery from "lib/useQuery";
import { rowsPerFetch } from "../../constants";
import { useNavigate } from "react-router-dom";
import useSetting from "hooks/useSetting";

const headCells = [
  { id: "email", numeric: false, label: "Email" },
  { id: 'user', numeric: false, label: 'Added By' },
  { id: 'reason', numeric: false, label: 'Reason' },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Date Added",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "right",
  }
];

const Blacklist = () => {
  const query = useQuery();
  const { blacklists, getBlackLists } = useSetting();
  const [activePage, setActivePage] = React.useState(0);
  const incrementPage = (value: number) => {
    setActivePage(value);
  }

  const search = query.get("search") || undefined;
  const field = query.get("field") || undefined;

  React.useEffect(() => {
    if ((activePage !== 0 && activePage > (blacklists?.lodedPageData as any) && blacklists?.blacklists?.length !== blacklists?.total) ||
      (!blacklists?.blacklists?.length || blacklists?.isFiltered)) {
      handleSearch()
    }
  }, [activePage])

  React.useEffect(() => {
    if (search || field) {
      setActivePage(0)
      handleSearch({ page: 0 });

    }
  }, [search, field])

  const handleSearch = ({ ...query } = {}) => {
    getBlackLists({ page: activePage, limit: rowsPerFetch, search, field, ...query })
  }

  const { setSnackbarState } = useAppContext();

  const { mutate, isLoading: isDeletingBlacklist } = useMutation(deleteBlackLists, {
    onSuccess: () => {
      setSnackbarState({
        open: true,
        message: "Deleted successfully",
        color: "success",
      });
      handleSearch();
    },
    onError: (error) => {
      setSnackbarState({
        open: true,
        message: "Error deleting blacklist",
        color: "error",
      });
    }
  })

  const [isCreateNewBlacklistOpen, setIsCreateNewBlacklistOpen] = React.useState(false);

  const handleCreateBlacklistSuccess = () => {
    setIsCreateNewBlacklistOpen(false);
    setSnackbarState({
      open: true,
      message: "Added to Blacklist successfully",
      color: "success",
    });
    handleSearch();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {blacklists?.error ? <ErrorAlert /> : <EnhancedCustomTable
          selectionKey="id"
          Row={BlackListRow}
          onDeleteClick={(ids) => mutate(ids)}
          title="Black List"
          headCells={headCells}
          totalCountParent={blacklists?.total}
          incrementFetch={incrementPage}
          pageParent={activePage}
          rows={blacklists?.blacklists}
          isLoading={blacklists?.isLoading || isDeletingBlacklist}
          fieldsToSearch={['Email', {
            value: 'user',
            label: 'Added By'
          }, {
              value: 'date',
              label: 'Date Added'
            }]}
          onAddClick={() => {
            setIsCreateNewBlacklistOpen(true);
          }}
        />
        }
      </Grid>
      <DialogComponent
        isDialogOpen={isCreateNewBlacklistOpen}
        closeDialog={() => {
          setIsCreateNewBlacklistOpen(false);
        }}
        title="Add to Blacklist"
      >
        <CreateNewBlacklist onSuccess={handleCreateBlacklistSuccess} />
      </DialogComponent>
    </Grid>
  );
};



function BlackListRow({ handleClick, row, isItemSelected, labelId }: RowProps) {
  const { id, email, created, reason, user } = row;
  const navigate = useNavigate();
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigate(`/settings/blacklist/${id}`);
  }
  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={id}
      selected={isItemSelected}
    >

      <TableCell component="th" align="left">
        {email}
      </TableCell>
      <TableCell align="left">{user} </TableCell>
      <TableCell align="left">
        {reason}
      </TableCell>
      <TableCell align="left">
        {new Date(created).toISOString().split("T")[0]}
      </TableCell>
      <TableCell align="right">
        <Button
          onClick={handleButtonClick}
          variant="contained"
        >
          View
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default Blacklist;
