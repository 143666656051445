import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { ListState } from "store/reducers/list.reducer";
import { fetchListAction } from "store/actions/list.action";

const useList = () => {
    const { list, isToast, message, error, isLoading } = useSelector<RootState, ListState>((state: any) => state.list);
    const dispatch = useDispatch();

    const getAllList = () => {
        return dispatch<any>(fetchListAction());
    };

    return {
        list,
        getAllList,
        isToast, message, error, isLoading
    };
};

export default useList;
