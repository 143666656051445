import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { JobsState } from "store/reducers/jobs.reducer";
import { fetchJobAction } from "store/actions/jobs.action";

interface JobSearchParams {
    page?: number,
    limit?: number,
    search?: string,
    field?: string,
}

const useJobs = () => {
    const { jobs } = useSelector<RootState, JobsState>((state: any) => state.job);
    const dispatch = useDispatch();

    const getAllJobs = (params: JobSearchParams) => {
        return dispatch<any>(fetchJobAction(params));
    };

    return {
        jobs,
        getAllJobs,
    };
};

export default useJobs;
