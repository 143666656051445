import React from "react";
import { useParams, Link } from "react-router-dom";
import { useGetProfile } from "services/profiles";
import { useGetLists } from "services/lists";
import {
  Grid,
  Tabs,
  useTheme,
  Tab,
  Typography,
  CircularProgress,
  Chip,

} from "@mui/material";
import MainCard from "components/ui/Card";
import TabPanel from "components/ui/TabPanel";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditProfileForm from "components/forms/EditProfileForm";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { Email } from "@mui/icons-material";
import { useAppContext } from "lib/contextLib";
import moment from "moment";
import ErrorAlert from "components/ErrorAlert";
import EnhancedCustomTable from "components/EnhancedCustomTable";
import useProfile from "hooks/useProfile";
import { rowsPerFetch } from "../constants";

const tabsOption = [
  {
    label: "Overview",
    icon: <AccountCircleIcon sx={{ fontSize: "1.1rem" }} />,
  },
  {
    label: "Emails",
    icon: <Email sx={{ fontSize: "1.1rem" }} />,
  },
  {
    label: "Notes & Activities",
    icon: <LibraryBooksIcon sx={{ fontSize: "1.1rem" }} />,
  },
];

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Profile = () => {
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const [isEditing, setIsEditing] = React.useState(false);
  const { profile: allProfiles, getAllProfiles } = useProfile();
  const { setSnackbarState } = useAppContext();
  const {
    data: lists,
    isLoading: isListsLoading,
    isError: isListsError,
  } = useGetLists();
  const profileId = parseInt(id!);
  const { data, isLoading, isError } = useGetProfile(profileId);
  const [value, setValue] = React.useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  console.log(data?.scrubInformation);
  const handleSuccess = () => {
    setIsEditing(false);

    setSnackbarState({
      open: true,
      message: "Profile updated successfully",
      color: "success",
    });
    getAllProfiles({ page: 0, limit: rowsPerFetch });
  };
  const mvScrubInformation = data?.scrubInformation?.find(item => item.vendor_id === 1);
  const oeScrubInformation = data?.scrubInformation?.find(item => item.vendor_id === 2);

  return (
    <MainCard sx={{ color: "grey.600" }}>
      {(isError || isListsError) && <ErrorAlert />}
      {(isLoading || isListsLoading) && <CircularProgress sx={{display: "flex", justifyContent: "center", alignItems: "center"}} />}
      {!isLoading && !isError && data && lists && (
        <Grid container spacing={2} direction="column">
          <Grid container spacing={2} padding={4}>
            <Grid item xs={12} md={3} container direction="column" spacing={1}>
              <Grid item>
                {" "}
                <Typography variant="h4">
                  {data.profile.first} {data.profile.last}{" "}
                </Typography>{" "}
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">{data.profile.email}</Typography>
              </Grid>
              <Grid item>
                {" "}
                <Typography variant="caption">
                  {" "}
                  Added {moment(data.profile.created || new Date()).fromNow()}{" "}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              container
              direction={"column"}
              spacing={1}
            >
              <Grid item>
                <Typography variant="h5">Lists</Typography>
              </Grid>
              <Grid item>
                <Chip
                  sx={{ maxWidth: "80px", color: "white" }}
                  size="small"
                  label={
                    lists.find((item) => item.id === data.profile.list_id)?.list_name
                  }
                  color="info"
                />
              </Grid>
              <Grid item>
                <Chip
                  sx={{ color: "white", fontWeight: "600" }}
                  label={data.profile.status}
                  size="small"
                  color={data.profile.status === "ACTIVE" ? "success" : "error"}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              container
              direction={"column"}
              spacing={1}
            >
              <Grid item>
                {" "}
                <Typography variant="h4">Scrub Information </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {" "}
                  MV - {mvScrubInformation ? <Chip sx={{ color: 'white', fontWeight: 600 }} size="small" label={mvScrubInformation.reason}
                    color={mvScrubInformation.code === 1 ? 'success' : 'error'}
                  /> : 'No Data Available'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  OE - {oeScrubInformation ? <Chip sx={{ color: 'white', fontWeight: 600 }} size='small' label={
                    oeScrubInformation.reason
                  } color={oeScrubInformation.code === 1 ? 'success' : 'error'} /> : 'No Data Available'}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              container
              direction={"column"}
              spacing={1}
            >
              <Grid item>
                <Typography variant="h4">Tags</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="simple tabs example"
              variant="scrollable"
              sx={{
                mb: 3,
                "& a": {
                  minHeight: "auto",
                  minWidth: 10,
                  py: 1.5,
                  px: 1,
                  mr: 2.25,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                },
                "& a.Mui-selected": {
                  color: theme.palette.primary.main,
                },
                "& .MuiTabs-indicator": {
                  bottom: 2,
                },
                "& a > svg": {
                  marginBottom: "0px !important",
                  mr: 1.25,
                },
              }}
            >
              {tabsOption.map((tab, index) => (
                <Tab
                  key={index}
                  component={Link}
                  to="#"
                  icon={tab.icon}
                  label={tab.label}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
            <TabPanel value={value} index={0}>
              <EditProfileForm
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                profile={data.profile}
                onSuccessfulUpdate={handleSuccess}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <EnhancedCustomTable
                isLoading={false}
                selectionKey={"id"}
                rows={[]}
                title={"Emails"}
                onDeleteClick={() => {
                  console.log("delete");
                }}
                headCells={[
                  {
                    id: "date",
                    label: "Date",
                    numeric: false,
                    disablePadding: false,
                  },
                  {
                    id: "subject",
                    label: "Subject",
                    numeric: false,
                    disablePadding: false,
                  },
                  {
                    id: "status",
                    label: "Status",
                    numeric: false,
                    disablePadding: false,
                  },
                  {
                    id: "campaign",
                    label: "Campaign",
                    numeric: false,
                    disablePadding: false,
                  },
                  {
                    id: "actions",
                    label: "Actions",
                    numeric: false,
                    disablePadding: false,
                  },
                ]}
                Row={({ any }: any) => {
                  return <div>row</div>;
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <>Notes and Activities</>
            </TabPanel>
          </Grid>
        </Grid>
      )}
    </MainCard>
  );
};

export default Profile;
