import {  MainContent } from "types";
import { createTheme } from "@mui/material/styles";

interface P4 {
  fontWeight: number;
  fontSize: string;
  lineHeight: string;
  color:string
}

declare module "@mui/material/styles" {
  interface Theme {
    p4: P4;
    customInput:any;
    mainContent: MainContent;
  }
  interface ThemeOptions {
    customInput?: any;
    p4?: P4;
    mainContent?: MainContent;
  }
}

const theme = createTheme({
  mainContent: {
    width: "100%",
    minHeight: "calc(100vh - 88px)",
    flexGrow: 1,
    padding: "20px",
    marginTop: "70px",
    borderRadius: `10px`,

  },
   components:{
    // MuiIconButton:{
    //   styleOverrides:{
    //     root:{
    //       color:""
    //     }
    //   }
    // },
    MuiButton:{
      styleOverrides:{
        contained: {
          color: "#fff",
        },
        root:{
          fontWeight: 600,
          
        }
      }
    },
    MuiOutlinedInput:{
      styleOverrides:{
        input:{
          padding: "13px 11px",
        }
      }
    },
    MuiAlert:{
      styleOverrides:{
        message:{
          borderRadius: `10px`,
          color: `#fff`,
          fontWeight: 600,
        }
      }
    }
   },
  typography: {
    fontFamily: '"Arial", sans-serif, "Roboto", "Helvetica"',
    h1: {
      fontSize: "36px",
      // lineHeight: "46px",
      fontWeight: 600,
    },
    h2: {
      fontSize: "30px",
      
      fontWeight: 600,
    },
    h3: {
      fontSize: "24px",
      // lineHeight: "34px",
      fontWeight: 600,
    },
    h4: {
      fontSize: "18px",
      // lineHeight: "26px",
      fontWeight: 600,
    },
    h5: {
      fontSize: "16px",
      // lineHeight: "24px",
      fontWeight: 600,
    },
    h6: {
      fontSize: "14px",
      // lineHeight: "22px",
      fontWeight: 500,
    },
    // body1:{
    //   fontSize:'20px',
      // lineHeight:"30px"
    // },
    // body2:{
    //   fontSize:'18x',
    //   lineHeight:"28px"
    // }
  },
  palette: {
    primary: {
      main: "#7742e6",
      light: "#9663ff",
      dark:'#431d99'
    },
    secondary: {
      main: "#e6db44",
      dark: "#b3a92e",
      light: "#fff56d",
    },

    info: {
      main: "#219FFF",
      dark:'#1a73e8',
      light:'#6cb6ff'
    },
    success: {
      main: "#17BD8D",
      dark: "rgba(23, 189, 141, 0.1)",
      light: "#17BD8D",
    },
    warning: {
      light: "#FFC107",
      main: "#FFA114",
      dark:'#ff7c00',
    },
    error: {
      light: "#FF3D71",
      main: "#FF4E3E",
      dark: "rgba(255, 78, 62, 0.1)",
    },
  },
});

export default theme;
