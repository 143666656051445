import React from "react";
import {
  Button,
  InputLabel,
  FormControl,
  TextField,
  MenuItem,
  Grid,
  CircularProgress,
  Box,
  FormHelperText,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { updateList } from "services/lists";
import { List } from "store/reducers/list.reducer";

interface Props {
  isEditing: boolean;
  list: List;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccessfulUpdate: () => void;
}
const EditLeagueForm = ({ isEditing, list, setIsEditing, onSuccessfulUpdate }: Props) => {
  const { mutate, isLoading, isError, error } = useMutation(
    (list: Partial<List>) => updateList((list as any)),
    {
      onSuccess: () => {
        onSuccessfulUpdate();
      }

    }
  );

  const handleCancel = (setValues: any, initialValues: any) => {
    setIsEditing(false);
    setValues(initialValues);
  };
  return (
    <Formik
      initialValues={{
        name: list.list_name,
        vendor: list.vendor || "",
        type: list.type || "",
        status: list.status || "",
        submit: "",
        id: list.id,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Name is required"),
        vendor: Yup.string(),
        type: Yup.string(),
        status: Yup.string().required("Status is required"),
      })}
      onSubmit={(values, { setErrors, setStatus }) => {
        try {
          mutate(values);
        } catch (e) {
          console.log(e);
          if (e instanceof Error) {
            console.log(e.message);
            setStatus({ success: false });
            setErrors({ submit: e.message });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setValues,
        initialValues,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={1}>
            <Grid item container>
              <InputLabel htmlFor="outlined-adornment-email-login">
                Name
              </InputLabel>
              <FormControl
                fullWidth
                error={Boolean(touched.name && errors.name)}
              >
                <TextField
                  id="name"
                  variant={isEditing ? "outlined" : "standard"}
                  disabled={!isEditing}
                  type="text"
                  value={values.name}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={6}>
                <InputLabel sx={{ color: "grey.600" }}>Type</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    variant={isEditing ? "outlined" : "standard"}
                    disabled={!isEditing}
                    value={values.type}
                    select // tell TextField to render select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="type"
                  >
                    <MenuItem key={1} value="All">
                      All
                    </MenuItem>
                    <MenuItem key={2} value="Instagram">
                      Instagram
                    </MenuItem>
                    <MenuItem key={2} value="Facebook">
                      Facebook
                    </MenuItem>
                    <MenuItem key={2} value="Ecomm">
                      Ecomm
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel sx={{ color: "grey.600" }}>Status</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    variant={isEditing ? "outlined" : "standard"}
                    disabled={!isEditing}
                    value={values.status}
                    select // tell TextField to render select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="status"
                  >
                    <MenuItem key={1} value="Active">
                      Active
                    </MenuItem>
                    <MenuItem key={2} value="Inactive">
                      Inactive
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <InputLabel htmlFor="outlined-adornment-email-login">
                Vendor
              </InputLabel>
              <FormControl
                fullWidth
                error={Boolean(touched.vendor && errors.vendor)}
              >
                <TextField
                  id="name"
                  type="text"
                  value={values.vendor}
                  name="vendor"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{}}
                  variant={isEditing ? "outlined" : "standard"}
                  disabled={!isEditing}
                />
              </FormControl>
            </Grid>
          </Grid>

          {isError && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{"Failed to Update List"}</FormHelperText>
            </Box>
          )}
          <Grid item container spacing={2}>
            {isEditing ? (
              <>
                <Grid item xs={12} md={6}>
                  <Button
                    sx={{ mt: 2 }}
                    disableElevation
                    fullWidth
                    size="large"
                    onClick={() => handleCancel(setValues, initialValues)}
                    variant="contained"
                    color="warning"
                    disabled={isLoading}
                  >
                    {"Cancel"}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    sx={{ mt: 2 }}
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    color="info"
                    variant="contained"
                    disabled={isLoading}
                  >
                    {isLoading ? <CircularProgress /> : "Update"}
                  </Button>
                </Grid>
              </>
            ) : (
              <Button
                sx={{ mt: 3 }}
                disableElevation
                fullWidth
                size="large"
                variant="contained"
                onClick={() => setIsEditing(true)}
                color="primary"
              >
                {"Edit List"}
              </Button>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default EditLeagueForm;
