import React from "react";
import LoginForm from "components/auth/LoginForm";
import { Box, Grid, Typography } from "@mui/material";

const NotFound = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", top: "50% !important" }}>
        <Typography variant="h3" gutterBottom>Page Not Found</Typography>
      </Box>
    </Grid>
  );
};

export default NotFound;
