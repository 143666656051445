import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import { fetchProfileAction } from "store/actions/profile.action";

export type Profile = {
    address_1: null | string;
    address_2: string;
    city: string;
    clicks: null | string;
    country: string;
    created: string;
    date_first_send: null | string;
    date_hot: null | string;
    date_last_clicked: null | string;
    date_last_modified: null | string;
    date_last_opened: null | string;
    date_to_mailwizz: null | string;
    email: string;
    first: null | string;
    gender: null | string;
    id: number;
    last: null | string;
    last_mailed: null | string;
    list_id: number;
    opens: null | string;
    pet_breed: null | string;
    pet_breed_id: null | string;
    pet_name: null | string;
    pet_type: null | string;
    phone: null | string;
    selected_by_job: null | string;
    sends: null | string;
    state: string;
    status: string;
    unsubscribe_segment: null | string;
    upload_id: number;
    zip: null | string;
};

export type ProfiletState = {
    profile: {
        total?: number,
        isFiltered?: boolean,
        profile?: Profile[],
        isLoading?: boolean,
        error?: boolean,
        lodedPageData?: number
    };
};

export const profileSlice = createSlice<ProfiletState, SliceCaseReducers<ProfiletState>, string>({
    name: "profile",
    initialState: {
        profile: {
            total: parseInt(localStorage.getItem('countProfiles') ?? '0') || 0,
            isFiltered: false,
            profile: [],
            isLoading: true,
            error: false,
            lodedPageData: 0
        },
    },
    reducers: {},
    extraReducers: (builder) => {
        // Fetch profile 
        builder.addCase(fetchProfileAction.pending, (state) => {
            state.profile = {
                ...state.profile,
                isFiltered: false,
                isLoading: true
            };
        });
        builder.addCase(fetchProfileAction.fulfilled, (state, { payload }: any) => {
            const { arg } = payload;

            state.profile = {
                isLoading: false,
                isFiltered: arg?.search && arg?.field ? true : false,
                total: parseInt(payload.count ?? state.profile?.total ?? 0),
                profile: arg.page !== 0 ? [...state.profile?.profile as any, ...payload.profiles] : payload.profiles,
                lodedPageData: arg.page
            }
        });
        builder.addCase(fetchProfileAction.rejected, (state) => {
            state.profile = {
                ...state.profile,
                isFiltered: false,
                isLoading: false
            };
        });
    },
});

export const { setIsToast } = profileSlice.actions;

export default profileSlice.reducer;

