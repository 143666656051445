import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { useAppContext } from "lib/contextLib";
import EditBreedForm from "components/forms/EditBreedForm";
import useTable from "hooks/useTable";
import { rowsPerFetch } from "../../constants";

const Breed = () => {
  const { id } = useParams();
  const [isEditing, setIsEditing] = React.useState(false);
  const { setSnackbarState } = useAppContext();
  const [breed, setBreed] = useState<any>({})
  const { breed: allBreed, fetchAllBreedAction } = useTable();

  useEffect(() => {
    if (!allBreed?.breed?.length) {
      fetchAllBreedAction({ page: 0, limit: rowsPerFetch });
    }
    setBreed(allBreed?.breed?.find((breed) => breed.id == parseInt(id as any)))
  }, [allBreed?.breed?.length])

  const handleSuccess = () => {
    fetchAllBreedAction({ page: 0, limit: rowsPerFetch });
    setIsEditing(false);
    setSnackbarState({
      open: true,
      message: "Breed updated successfully",
      color: "success",
    })
  }

  return (
    <Grid container>
      <Grid container direction="column">
        <Grid item>
          {(breed && Object.keys(breed).length) ? <EditBreedForm
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            onSuccess={handleSuccess}
            breed={breed}
          /> : <></>}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Breed;
