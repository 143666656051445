import React, { useState } from "react";
import { Grid, TableRow, TableCell, Button } from "@mui/material";
import EnhancedCustomTable, { RowProps } from "components/EnhancedCustomTable";
import {
  deleteBlockedDomains,
} from "services/settings";
import DialogComponent from "components/ui/Dialog";
import { rowsPerFetch } from "../../constants";
import { useAppContext } from "lib/contextLib";
import { useMutation } from "@tanstack/react-query";
import CreateNewBlockedDomain from "components/forms/CreateNewBlockedDomain";
import ErrorAlert from "components/ErrorAlert";
import useQuery from "lib/useQuery";
import { useNavigate } from "react-router-dom";
import useSetting from "hooks/useSetting";

const blockedDomainsHeadCells = [
  { id: "domain", numeric: false, label: "Domain" },
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: "Added By",
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Date Added",
  },

  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "right",
  }
];
const BlockedDomains = () => {
  const query = useQuery();
  const { blockedDomains, getBlockedDomains, error } = useSetting();
  const [activePage, setActivePage] = React.useState(0);
  const { setSnackbarState } = useAppContext();

  const search = query.get("search") || undefined;
  const field = query.get("field") || undefined;

  React.useEffect(() => {
    if ((activePage !== 0 && activePage > (blockedDomains?.lodedPageData as any) && blockedDomains?.blockedDomains?.length !== blockedDomains?.total) ||
      (!blockedDomains?.blockedDomains?.length || blockedDomains?.isFiltered)) {
      handleSearch()
    }
  }, [activePage]);

  React.useEffect(() => {
    if (search || field) {
      setActivePage(0);
      handleSearch({ page: 0 });
    }
  }, [search, field])

  const incrementPage = (value: number) => {
    setActivePage(value);
  };

  const handleSearch = ({ ...query } = {}) => {
    getBlockedDomains({ page: activePage, limit: rowsPerFetch, search, field, ...query })
  };

  const { mutate: deleteBlockedDomainsMutation, isLoading: isDeletingBlockedDomains } = useMutation(
    deleteBlockedDomains,
    {
      onSuccess: () => {
        setSnackbarState({
          open: true,
          message: "Domain deleted successfully",
          color: "success",
        });
        handleSearch();
      },
      onError: (error) => {
        setSnackbarState({
          open: true,
          message: "Error deleting Domain",
          color: "error",
        });
      },
    }
  );

  const [isBlockedDomainsOpen, setIsBlockedDomainsOpen] = React.useState(false);

  const handleCreateDomainSuccess = () => {
    setIsBlockedDomainsOpen(false);
    setSnackbarState({
      open: true,
      message: "Blocked Domain added successfully",
      color: "success",
    });
    handleSearch();
  };

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12}>
          {error ? (
            <ErrorAlert />
          ) : (
            <EnhancedCustomTable
              selectionKey="id"
              Row={BlockedDomainsRow}
              title="Blocked Domains"
              headCells={blockedDomainsHeadCells}
              incrementFetch={incrementPage}
              pageParent={activePage}
              totalCountParent={blockedDomains?.total}
              fieldsToSearch={["domain", {
                label: "Added By",
                value: "user"
              }]}
              rows={blockedDomains?.blockedDomains}
              isLoading={blockedDomains?.isLoading || isDeletingBlockedDomains}
              onAddClick={() => setIsBlockedDomainsOpen(true)}
              onDeleteClick={(ids: string[]) =>
                deleteBlockedDomainsMutation(ids)
              }
            />
          )}
        </Grid>
        <DialogComponent
          isDialogOpen={isBlockedDomainsOpen}
          closeDialog={() => {
            setIsBlockedDomainsOpen(false);
          }}
          title="Add Blocked Domain"
        >
          <CreateNewBlockedDomain onSuccess={handleCreateDomainSuccess} />
        </DialogComponent>
      </Grid>
    </Grid>
  );
};

function BlockedDomainsRow({
  handleClick,
  row,
  isItemSelected,
  labelId,
}: RowProps) {
  const { id, domain, created } = row;
  const navigate = useNavigate();
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigate(`/settings/blocked-domains/${id}`);
  }
  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={id}
      selected={isItemSelected}
    >

      <TableCell component="th" align="left" id={labelId} scope="row">
        {domain}
      </TableCell>
      <TableCell align="left">{row.user}</TableCell>
      <TableCell align="left">
        {new Date(created).toLocaleDateString()}
      </TableCell>
      <TableCell align="right">
        <Button
          onClick={handleButtonClick}
          variant="contained"
        >View</Button>
      </TableCell>
    </TableRow>
  );
}

export default BlockedDomains;
