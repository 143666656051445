import React from "react";
import {   Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiTableCell, { tableCellClasses } from "@mui/material/TableCell";
import Table, { TableProps } from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";


export const CustomTableCell = styled(MuiTableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.grey[600],
    fontSize: 12,
    fontStyle: "normal",
    textTransform:"uppercase",
    fontWeight: 600,
    borderBottom: `1px solid ${theme.palette.grey[700]}1A`,
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.grey[700],
    borderBottom: `1px solid ${theme.palette.grey[700]}1A`,
    height: "35px",
  },
}));


interface Props {
    tableHead:React.ReactNode;
    children:React.ReactNode
    small?:boolean;
    tableSX?:TableProps["sx"]
}

const CustomTable = ({tableHead,children,small,tableSX}:Props) => {
  
  return (
    <TableContainer
      component={Paper}
      sx={{
        backgroundColor: "transparent",
        color: "grey.500",
        boxShadow: "none",
      }}
    >
      <Table  sx={{ minWidth: !small?650:undefined,...tableSX }} aria-label="custom table">
        <TableHead sx={{ backgroundColor: "grey.200" }}>
         {tableHead}
        </TableHead>
        <TableBody>
         {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
