import React from 'react';
import {
    TableRow,
    TextField,
    MenuItem,
  } from "@mui/material";
import { fields } from 'pages/List';
import CustomTable,  {
    CustomTableCell as TableCell,
  } from 'components/ui/CustomTable';
  interface Props {
    maxNum: number;
    first10Rows: any[];
    fieldSelections:string[];
    handleFieldSelect:(index: number, value: string)=>void;

  }
const FieldSelector = ({maxNum, first10Rows, fieldSelections, handleFieldSelect}:Props) => {
  return (
    <CustomTable
  tableSX={{ "& .MuiTableCell-root": { padding: "4px" } }}
    tableHead={
      
      <TableRow>
        {Array.from(Array(maxNum).keys()).map((item) => (
          <TableCell key={item} align="left">
            Field {item + 1}
          </TableCell>
        ))}
      </TableRow>
    }
  >
    {Array.from(Array(first10Rows.length+1).keys()).map((i) => (
      <TableRow
        key={i}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        {Array.from(Array(maxNum).keys()).map((j) => (
          <TableCell key={j} align="left">
            {first10Rows &&
              first10Rows[i] 
              && first10Rows[i][j] &&
              first10Rows[i][j].trim()}
          </TableCell>
        ))}
      </TableRow>
    ))}
    <TableRow
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      {Array.from(Array(maxNum).keys()).map((j) => (
        <TableCell key={j} align="left" sx={{ minWidth: "30px" }}>
          <TextField
            value={fieldSelections[j] || ""}
            select
            label="Field"
            fullWidth
            onChange={(e) => handleFieldSelect(j, e.target.value)}
          >
            {fields
              .filter(
                (item) =>
                  !fieldSelections.includes(item) ||
                  fieldSelections[j] === item|| item === "IGNORE"
              )
              .map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
          </TextField>
        </TableCell>
      ))}
    </TableRow>
  </CustomTable>
  )
}

function sortUploadKeys(keys:string[],item:any):string[]{
  const sortedKeys = keys.sort((a, b) => {
    if(item[b])return 1;
    return -1;
  });
  return sortedKeys;
}
export function Uploads({ uploads }:{uploads:any[]}) {
  console.log(uploads)


    return (
 
        <CustomTable
        tableSX={{ "& .MuiTableCell-root": { padding: "4px" } }}
        tableHead={
          
          <TableRow>
            { uploads[0] && sortUploadKeys(Object.keys(uploads[0]),uploads[0]).filter(key=>fields.includes(key)).map((key) => (
                <TableCell key={key} align="left">
                    {key}
             </TableCell>
            ))}
          </TableRow>
        }
      >
        {uploads.map(item=>(
         <TableRow
         key={item.id}
         sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
       >
        {item && sortUploadKeys(Object.keys(item), item).filter(key=>fields.includes(key))
        
        .map(key=>(
            <TableCell key={key} align={'left'}>
              {item[key]}
            </TableCell>
        ))}
       </TableRow>
      ))}
      <TableRow>

      </TableRow>
</CustomTable>

    )
  }

export default FieldSelector