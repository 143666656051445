import React from "react";
import {
  Grid,
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  CircularProgress,
  Box,
  FormHelperText,
  TextField,
  Select
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { updateBreed } from "services/breeds";
import { Job,editJob } from "services/jobs";
interface Props {
  onSuccess: () => void;
  breed: {
    id: string;
    name: string;
    type: string;
  };
  isEditing:boolean;
  setIsEditing:React.Dispatch<React.SetStateAction<boolean>>;
}

const EditJob = ({ onSuccess,breed, isEditing,setIsEditing }: Props) => {
  const { isError, isLoading, mutate } = useMutation(updateBreed,
    {
      onError: (error) => {
        console.log(error);
      },
      onSuccess: onSuccess,
    }
  );

  const handleCancel = (setValues: any, initialValues: any) => {
    setIsEditing(false);
    setValues(initialValues);
  };
  console.log(breed);
  return (
    <Formik
    initialValues={{
      name: breed.name || "",
      type: breed.type || "",
      submit: "",
    }}
    validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
        type: Yup.string().required("Required"),
    })}
    onSubmit={(values, { setErrors, setStatus }) => {
      try {
        mutate({
          id: breed.id,
          name: values.name,
          type: values.type,
        });
      } catch (e) {
        console.log(e);
        if (e instanceof Error) {
          console.log(e.message);
          setStatus({ success: false });
          setErrors({ submit: e.message });
        }
      }
    }}
  >
    {({
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      touched,
      values,
      setValues,
      initialValues
    }) => (
      <form noValidate onSubmit={handleSubmit}>
         <Grid container direction="column" spacing={2} p={2} pb={0} pt={1}>
         <Grid item xs={12}>
                  <InputLabel htmlFor="outlined-adornment-email-login">
                    Name
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                  >
                    <TextField
                      id="name"
                      disabled={!isEditing}
                      type="text"
                      value={values.name}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormControl>
                  {touched.name && errors.name && (
                      <FormHelperText error>{errors.name}</FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="outlined-adornment-email-login">
                        Type
                    </InputLabel>
                    <FormControl
                        fullWidth
                        error={Boolean(touched.type && errors.type)}
                    >
                  <Select
                    
                    id="type"
                    name="type"
                    value={values.type}
                    onBlur={handleBlur}
                    disabled={!isEditing}
                    onChange={handleChange}
                  >
                 
                       {["Cat", 'Dog'].map(item=>(
                        <MenuItem key={item} value={item.toUpperCase()}>
                          {item}
                        </MenuItem>
                       ))} 
                  </Select>
                </FormControl>
            </Grid>
         {isError && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {"Failed to Edit Breed"}
              </FormHelperText>
            </Box>
          )}
          <Grid item container spacing={2} pt={2}>
            {isEditing ? (
              <>
                <Grid item xs={12} md={6} >
                  <Button
                    sx={{ mt: 2 }}
                    disableElevation
                    fullWidth
                    size="large"
                    onClick={() => handleCancel(setValues, initialValues)}
                    variant="contained"
                    color="warning"
                    disabled={isLoading}
                  >
                    {"Cancel"}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    sx={{ mt: 2 }}
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    color="info"
                    variant="contained"
                    disabled={isLoading}
                  >
                    {isLoading ? <CircularProgress /> : "Update"}
                  </Button>
                </Grid>
              </>
            ) : (
              <Button
                sx={{ mt: 3 }}
                disableElevation
                fullWidth
                size="large"
                variant="contained"
                onClick={() => setIsEditing(true)}
                color="primary"
              >
                {"Edit Breed"}
              </Button>
            )}
          </Grid>
        </Grid>

            
    </form>
    )}
    </Formik>    
  );
};


export default EditJob;
