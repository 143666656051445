import React from "react";
import { useMutation } from "@tanstack/react-query";
import { getBreeds, deleteBreeds } from "../../services/breeds";
import EnhancedCustomTable from "components/EnhancedCustomTable";
import { Grid } from "@mui/material";
import { RowProps } from "components/EnhancedCustomTable";
import { rowsPerFetch } from "../../constants";
import DialogComponent from "components/ui/Dialog";
import { TableCell, TableRow, Button } from "@mui/material";
import useQuery from "lib/useQuery";
import CreateNewBreed from "components/forms/CreateNewBreed";
import { useAppContext } from "lib/contextLib";
import { useNavigate } from "react-router-dom";
import ErrorAlert from "components/ErrorAlert";
import useTable from "hooks/useTable";
const headCellsVendors = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Created At",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "right",
  },
];
export default function ScrubVendors() {
  const { setSnackbarState } = useAppContext();
  const query = useQuery();
  const [activePage, setActivePage] = React.useState(0);
  const { breed, fetchAllBreedAction } = useTable();

  const search = query.get("search") || undefined;
  const field = query.get("field") || undefined;

  const handleSearch = ({ ...query } = {}) => {
    fetchAllBreedAction({ page: activePage, limit: rowsPerFetch, search, field, ...query })
  };

  React.useEffect(() => {
    if (search || field) {
      setActivePage(0)
      handleSearch({ page: 0 });
    }
  }, [search, field])

  React.useEffect(() => {
    if ((activePage !== 0 && activePage > (breed?.lodedPageData as any) && breed?.breed?.length !== breed?.total) ||
      (!breed?.breed?.length || breed?.isFiltered)) {
      handleSearch()
    }
  }, [activePage]);

  const { mutate: deleteBreedsMutation } = useMutation(deleteBreeds, {
    onSuccess: () => {
      setSnackbarState({
        open: true,
        message: "Deleted successfully",
        color: "success",
      });
      handleSearch();
    },
    onError: () => {
      setSnackbarState({
        open: true,
        message: "Error deleting Breeds",
        color: "error",
      });
    },
  });

  const [isBreedDialogOpen, setIsBreedDialogOpen] = React.useState(false);
  const onCreateBreedSuccess = () => {
    setIsBreedDialogOpen(false);
    setSnackbarState({
      open: true,
      message: "Breed created successfully",
      color: "success",
    });
    handleSearch();
  };

  const onBreedDeleteClick = (ids: any[]) => {
    deleteBreedsMutation(ids);
  };
  return (
    <Grid container direction="column">
      {breed?.error && <ErrorAlert />}
      <EnhancedCustomTable
        onDeleteClick={onBreedDeleteClick}
        onAddClick={() => setIsBreedDialogOpen(true)}
        Row={BreedRow}
        selectionKey="id"
        rows={breed?.breed}
        isLoading={breed?.isLoading ?? false}
        title="Breeds"
        totalCountParent={breed?.total}
        incrementFetch={(val) => setActivePage(val)}
        fieldsToSearch={["name", "type"]}
        pageParent={activePage}
        headCells={headCellsVendors}
      />
      <DialogComponent
        isDialogOpen={isBreedDialogOpen}
        closeDialog={() => setIsBreedDialogOpen(false)}
        title="Add Breed"
      >
        <CreateNewBreed onSuccess={onCreateBreedSuccess} />
      </DialogComponent>
    </Grid>
  );
}

function BreedRow({
  handleClick,
  row,
  selectionKey,
  isItemSelected,
}: RowProps) {
  const navigate = useNavigate();
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigate(`/tables/breeds/${row.id}`);
  };
  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, (row as any)[selectionKey])}
      // role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left">{row.type}</TableCell>
      <TableCell align="left">
        {new Date(row.created).toLocaleString()}
      </TableCell>
      <TableCell align="right">
        <Button variant="contained" onClick={handleButtonClick}>
          View
        </Button>
      </TableCell>
    </TableRow>
  );
}
