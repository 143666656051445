

import axiosService from "lib/axios";
import { useQuery } from "@tanstack/react-query";
const route = "raw";


export async function getRaw(page: number, limit: number, search?: string, field?: string) {
    const { data } = await axiosService.get(route, {
        params: { page, limit, search, field },
    });
    if (data.count) {
        window.localStorage.setItem("totalCountRaw", data.count);
    }
    return {
        raw: data.raw,
        page: page,
        limit: limit,
        totalCount: data.count || window.localStorage.getItem("totalCountRaw"),
    };
}


async function getRawById(id: string) {
    const { data } = await axiosService.get(`${route}/${id}`);
    return data;
}

export function useGetRawById(id: string) {
    return useQuery(["raw", id], () => getRawById(id));
}


export async function updateRaw({ id, raw }: any) {
    return axiosService.put(`${route}/${id}`, raw);
}