import {
  Dashboard,
  List,
  AccountBox,
  Settings,
  CleaningServices,
  Work,
  Block,
  GppBad,
  Unpublished,
  GppGood,
  Group,
  TableRows,
  DataObject,
  CorporateFare,
  Pets,
  FileCopySharp
} from "@mui/icons-material";

export const daysOfWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const ispList = [
  "Google",
  "Microsoft",
  "None",
  "Any"
]

export const drawerWidth = 240;
export const dashboardRoutes = [
  { name: "Dashboard", Icon: Dashboard, url: "/" },
  { name: "Profiles", Icon: AccountBox, url: "/profiles" },
  { name: "Lists", Icon: List, url: "/lists" },
  {
    name: "Scrubs",
    Icon: CleaningServices,
    url: "/scrubs",
    parent: true,
    childRoutes: [
      { name: "Scrubs", Icon: TableRows, url: "/" },
      { name: "Scrub Vendors", Icon: Group, url: "/vendors" },
    ],
  },
  { name: "Jobs", Icon: Work, url: "/jobs" },
  {
    name: "Settings",
    Icon: Settings,
    url: "/settings",
    parent: true,
    childRoutes: [
      {
        name: "Blacklist",
        Icon: Block,
        url: "/blacklist",
      },
      {
        name: "Blocked Domains",
        Icon: GppBad,
        url: "/blocked-domains",
      },
      {
        name: "Blocked Roles",
        Icon: Unpublished,
        url: "/blocked-roles",
      },
      {
        name: "Allowed TLDs",
        Icon: GppGood,
        url: "/allowed-tlds",
      },
      {
        name: "Users",
        Icon: Group,
        url: "/users",
        allowOnlyPartners: true
      },
    ],
  },
  {
    name: "Tables",
    Icon: TableRows,
    url: "/tables",
    parent: true,
    childRoutes: [{
      name: 'Raw',
      url: '/raw',
      Icon: DataObject
    }
      , {
      name: 'Audit',
      url: '/audit-log',
      Icon: CorporateFare
    },
    {
      name: 'Breed',
      url: '/breeds',
      Icon: Pets
    }
    ]
  },
  {
    name: "Logs",
    Icon: FileCopySharp,
    url: "/logs",
  }
];
export const JOES_EMAIL = 'joe@pawsmedia.com'

export const rowsPerFetch = 1000;

