import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  IconButton,
  DialogProps,
  DialogActions,
} from "@mui/material";
import {  Close as CloseIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

interface Props {
    isDialogOpen:boolean;
    closeDialog:()=>void;
    title:string;
    children:React.ReactNode;
    dialogProps?:DialogProps;
    dialogActions?:React.ReactNode;
}
const DialogComponent = ({ isDialogOpen,closeDialog,title,children,dialogProps,dialogActions }:Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={isDialogOpen}
      maxWidth="xs"
      fullWidth
      fullScreen={fullScreen}
      onClose={closeDialog}
      {...dialogProps}
    >
      <DialogTitle variant="h3">
        {title}
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
       {children}
      </DialogContent>
      {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
    </Dialog>
  );
};

export default DialogComponent;
