import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { ProfiletState } from "store/reducers/profile.reducer";
import { fetchProfileAction } from "store/actions/profile.action";


interface ProfileSearchParams {
    page?: number,
    limit?: number,
    search?: string,
    field?: string,
    blockReason?: string,
    listId?: string
}

const useProfile = () => {
    const { profile } = useSelector<RootState, ProfiletState>((state: any) => state.profile);
    const dispatch = useDispatch();

    const getAllProfiles = (params: ProfileSearchParams) => {
        return dispatch<any>(fetchProfileAction(params));
    };

    return {
        profile,
        getAllProfiles,
    };
};

export default useProfile;
