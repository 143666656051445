import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "lib/axios";

export const fetchListAction = createAsyncThunk<undefined>(
    "list/fetch",
    async (arg, thinkAPI) => {
        const { data, status } = await axiosService.get("lists");

        if (status !== 200) return thinkAPI.rejectWithValue(new Error(data.message || "Something is wrong here"));
        return thinkAPI.fulfillWithValue(data);
    },
);