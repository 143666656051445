import { useAppContext } from "lib/contextLib";
import * as React from "react";
import { Navigate, Outlet } from "react-router";

const ProtectedRoutes = ({ children }: { children?: React.ReactNode }) => {
  const { user } = useAppContext();

  if (user?.isAdmin)
    return children ? children : <Outlet />;

  return <Navigate to={"/"} />;
};
export default ProtectedRoutes as any;
