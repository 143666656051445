import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { deleteScrub } from 'services/scrubs';
import EnhancedCustomTable from 'components/EnhancedCustomTable';
import moment from 'moment';
import { Grid } from '@mui/material';
import { RowProps } from 'components/EnhancedCustomTable';
import useQuery from 'lib/useQuery';
import { TableCell, TableRow } from '@mui/material';
import { useAppContext } from 'lib/contextLib';
import { rowsPerFetch } from '../constants';
import useScrub from 'hooks/useScrub';
import { Scrub } from 'store/reducers/scrubs.reducer';
const headCellsScrubs = [
  {
    id: 'email',
    numeric: false,
    label: 'Email',
  },
  {
    id: 'code',
    numeric: true,
    disablePadding: false,
    label: 'Code',
  },
  {
    id: 'reason',
    numeric: true,
    disablePadding: false,
    label: 'Reason',
  },
  {
    id: 'vendor',
    numeric: true,
    disablePadding: false,
    label: 'Vendor',
  },
  {
    id: 'created',
    numeric: true,
    disablePadding: false,
    label: 'Date Added',
  }
];


export default function Scrubs() {
  const { getScrubs, scrubs: allScrub, isLoading: isFetchLoading, error } = useScrub();
  const [activePage, setActivePage] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [scrubs, setScrubs] = React.useState<Scrub[]>([]);
  const [isLoading, setIsLoading] = React.useState(isFetchLoading);
  const query = useQuery();

  const search = query.get('search') || undefined;
  const field = query.get('field') || undefined;

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setIsLoading(true);
      getScrubs({ page: activePage, limit: rowsPerFetch, search, field })
    }, 100000);

    return () => clearInterval(intervalId);

  }, [activePage])

  React.useEffect(() => {
    setIsLoading(isFetchLoading)
  }, [isFetchLoading])

  React.useEffect(() => {
    setTotalCount(allScrub?.total ?? 0);
    setScrubs(allScrub?.scrub ?? [])
  }, [allScrub?.scrub.length || allScrub?.total])

  React.useEffect(() => {
    if (search || field || !allScrub?.scrub.length)
      handleSearch();
  }, [search, field])
  const handleSearch = () => {
    setActivePage(0);
    getScrubs({ page: activePage, limit: rowsPerFetch, search, field })
  }


  const { setSnackbarState } = useAppContext();
  const { mutate: deleteScrubMutation, isLoading: isDeletingScrub } = useMutation(deleteScrub, {
    onSuccess: () => {
      setSnackbarState({
        open: true,
        message: 'Deleted successfully',
        color: 'success',
      });
      handleSearch();
    },
    onError: () => {
      setSnackbarState({
        open: true,
        message: "Failed to delete scrub",
        color: 'error',
      });
      handleSearch();
    },
  })

  const onScrubDeleteClick = (ids: any[]) => {
    deleteScrubMutation(ids)
  }

  function ScrubRow({ handleClick, row, selectionKey, isItemSelected, labelId }: RowProps) {
    return (
      <TableRow
        hover
        onClick={(event) => handleClick(event, (row as any)[selectionKey])}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          align='left'
        >
          {row.email}
        </TableCell>
        <TableCell align="left">{row.code}</TableCell>
        <TableCell align="left">{row.reason}</TableCell>
        <TableCell align="left">{row.vendor}</TableCell>
        <TableCell align="left">{moment(row.created).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
      </TableRow>
    )
  }

  return (
    <Grid container direction="column">
      <EnhancedCustomTable onDeleteClick={onScrubDeleteClick} Row={ScrubRow}
        selectionKey='id'
        rows={scrubs}
        isLoading={isLoading || isDeletingScrub}
        title="Scrubs"
        totalCountParent={totalCount}
        orderByP="created"
        orderP="desc"
        pageParent={activePage}
        incrementFetch={(val) => setActivePage(val)}
        fieldsToSearch={['email', 'reason', 'Code']}
        headCells={headCellsScrubs} />
    </Grid>
  );
}
